
import {Options, Vue} from "vue-class-component";
import store from "@/store";
import {PartyRelation, RelationType, Third, ThirdType} from "@/types";
import {add, addExpandedItem, edit, removeExpandedItem} from "@/utils";
import ThirdSearchDialog from "@/components/demand/dialogs/ThirdSearchDialog.vue";
import Notify from 'quasar/src/plugins/Notify.js';;
import {formatDateLocale} from "@/utils/configuration/formatters-config";
import i18n from "@/i18n";

@Options({
  props: ["party"],
  components: {
    ThirdSearchDialog,
  },
  computed: {

    isPersonMorale: function () {
      return this.party?.type.id.toUpperCase().includes("ORGANIZATION");
    },
    isPersonPhysique: function () {
      return this.party?.type.id.toUpperCase().includes("PERSON");
    },
  },
})
export default class TaskPartyRelation extends Vue {
  relationSelectedIndex?: number;
  relationSelectedType?: ThirdType.ORGANIZATION | ThirdType.PERSON;
  openedRelations:boolean = false;
  dateMask = "DD/MM/YYYY";
  showDialog?: boolean = false;
  openDialog = "";
  party?:Third;
  relationBtn = false;
  reset: any;
  relations:any;

  get organizationType() {
    return ThirdType.ORGANIZATION;
  }

  get personType() {
    return ThirdType.PERSON;
  }

  /* Picklists */

  get relationsPicklist() {
    return store.getters["picklistModule/relationsItems"].map((item: any) => {
      return {
        ...item,
        label: item.value?.toString() !== "" ? i18n.global.t("party.relation.relation." + item.value) : ''
      };
    });
  }

  getRelationType(filterValue: any) {
    const items = store.getters["picklistModule/relationTypeItems"].map((item: any) => {
      return {
        ...item,
        label: item.value?.toString() !== "" ? i18n.global.t("party.relation.relationType." + item.value) : ''
      };
    });
    if (filterValue.relation.type.value=== RelationType.ASCENDING) {
      return items.filter((item: any) => item.ascendinglabel === filterValue.relation.type.value)
    } else if (filterValue.relation.type.value === RelationType.DESCENDING) {
      return items.filter((item: any) => item.descendinglabel === filterValue.relation.type.value)
    }
    return null
  }

  get clientType() {
    return store.getters["picklistModule/clientTypeItems"];
  }

  /* Methods */

  addExpandRelation(items: PartyRelation[]) {
    if (items && items.length === 0) {
      const element = store.getters["thirdModule/getRelationInit"];
      this.relations?.push(element);
    }
  }

  add<T>(items: T[]) {
    const getter = store.getters['thirdModule/getRelationInit'];
    items.push(getter);
  }

  edit = edit;

  addExpandedItem = addExpandedItem;
  removeExpandedItem = removeExpandedItem;
  startDate!: string;

  remove <T>(index: string | number, items: T[]) {
    if (items) {
      items.splice(Number(index), 1);
    }
  }
  setDeletedRelation (relation: PartyRelation) {
    store.state.thirdModule.deletedRelationUid = relation.resourceUid
  }
  formatDateUntil(value: any) {
    value.validity.until = value.validity.until.includes("/")
        ? formatDateLocale(value.validity.until, "DD/MM/YYYY", "fr", "DD/MM/YYYY")
        : formatDateLocale(value.validity.until, "DDMMYYYY", "fr", "DD/MM/YYYY");
  }
  formatDateFrom(value: any) {
    value.validity.from = value.validity.from.includes("/")
        ? formatDateLocale(value.validity.from, "DD/MM/YYYY", "fr", "DD/MM/YYYY")
        : formatDateLocale(value.validity.from, "DDMMYYYY", "fr", "DD/MM/YYYY");
  }

  searchThird(index: number, type: ThirdType.ORGANIZATION | ThirdType.PERSON) {
    this.relationSelectedIndex = index;
    this.showDialog = true;
    this.relationSelectedType = type;
    this.openDialog = type;
  }

  onSelectedItem(third: any) {
    if (this.validateSelectedItem(third)) {
      const index = this.relationSelectedIndex || 0;
      const relations = this.party?.person?.relations || this.party?.organization?.relations;
      const party = this.party?.person || this.party?.organization;
      if (relations) {
        if (third._discriminator === ThirdType.ORGANIZATION) {
          relations[index].name = !third.name ? third.commercial_name : third.name;
        } else {
          relations[index].name = third.familyname;
        }
        if (relations[index].relation?.type?.value === RelationType.ASCENDING) {
          relations[index].partyAsc = { resourceUid: third.id };
          relations[index].partyDesc = { resourceUid: party?.resourceUid };
        } else {
          relations[index].partyDesc = { resourceUid: third.id };
          relations[index].partyAsc = { resourceUid: party?.resourceUid };
        }
        this.onClose();
      }
    } else {
      console.warn(`Relation avec ${third.name} existe déjà!`);
      Notify.create({
        timeout: 10000,
        actions: [{ icon: 'close', color: 'white' }],
        message: `Relation avec ${third.name} existe déjà!`,
        color: "negative",
      });
    }
  }

  validateSelectedItem(third: Third): boolean {
    const relations = [
      ...(this.party?.person?.relations || []),
      ...(this.party?.organization?.relations || [])
    ];

    return !relations.some((item) => {
      const relationId =
          item.relation?.type?.value === RelationType.ASCENDING
              ? item.partyAsc?.resourceUid
              : item.partyDesc?.resourceUid;

      return relationId === third.id;
    });
  }


  onClose() {
    this.showDialog = false;
  }

  changeRelation(value: any) {
    const asc = value.partyAsc;
    const desc = value.partyDesc;
    if (value.relation.type.value === RelationType.ASCENDING) {
      value.partyAsc = desc;
      value.partyDesc = asc;
    } else {
      value.partyDesc = asc;
      value.partyAsc = desc;
    }
  }

  getStartDate(index: number, value: any) {
    this.startDate = formatDateLocale(value, "DD/MM/YYYY", "us", "YYYY/MM/DD");
  }

  limitEndDateOptions(date: any) {
    return date > this.startDate;
  }
  formatDate(date: string) {
    return formatDateLocale(date, this.dateMask, "fr", "YYYY/MM/DD");
  }
  created() {
    if (this.party?.type?.id == ThirdType.PERSON) {
      this.relations = this.party?.person?.relations;
    } else {
      this.relations = this.party?.organization?.relations;
    }
    if (this.relations && this.relations!.length === 0) {
      this.add(this.relations!);
    }
  }
}
