
import {Options, Vue} from "vue-class-component";
import store from "@/store";
import AvisPanel from "@/components/task/tabs/Opinion/AvisPanel.vue";
import CommentsReservesPanel from "@/components/task/tabs/Opinion/CommentsReservesPanel.vue";
import DecisionReservesPanel from "@/components/task/tabs/Opinion/DecisionReservesPanel.vue";
import DecisionPanel from "@/components/task/tabs/Opinion/DecisionPanel.vue";
import moment from "moment";
import { evaluateUiControls} from "@/utils";
@Options({
  components: { CommentsReservesPanel,DecisionReservesPanel,DecisionPanel },
  props: ["disable"],
  created(){
    evaluateUiControls('decision-task', 'insert', this.$router, true)
  },
  updated() {
    this.$emit('update')
  },
})
export default class Decision extends Vue {
  disable: any
   
  get decisions() {
    return store.state.taskModule.decisions ? store.state.taskModule.decisions
      .sort((a: any, b: any) => {
        const decisionDateA: any = a.decisionDate ? new Date(a.decisionDate) : null;
        const decisionDateB: any = b.decisionDate ? new Date(b.decisionDate) : null;
        if (decisionDateA === null && decisionDateB === null) {
          return 0;
        } else if (decisionDateA === null) {
          return 1;
        } else if (decisionDateB === null) {
          return -1;
        }
        return decisionDateA - decisionDateB;
      }) : [];
  }
  get selectedDecision() {
    return store.state.taskModule.decisionModule.selectedDecision ? store.state.taskModule.decisionModule.selectedDecision : {};
  }
  get isTaskEnterOpinion() {
    return store.getters["taskModule/isTaskEnterOpinion"];
  }
  get isTaskTypeMonoDecision() {
    return store.getters["taskModule/isTaskTypeMonoDecision"];
  }
   get isTaskModifyConditions() {
    return store.getters["taskModule/isTaskModifyConditions"];
  }
  getDateFormat(date: any) {
    return moment(date).format("DD/MM/YYYY HH:mm") || ' - '
  }
  get connectedUser() {
    return store.state.taskModule.decisionModule.connectedUser ? store.state.taskModule.decisionModule.connectedUser : "";
  }
 decisionsList(decision:any){
  return decision.opinions.filter((item: any) => Object.keys(item.opinion).length > 0 
  && item.opinionDate !== '' && item.resourceUid  )
 }
 analysisReference(decision:any) { 
      return decision && decision?.associatedAnalysisProcess && decision.associatedAnalysisProcess.length > 0 
      ?    
      decision?.associatedAnalysisProcess [decision?.associatedAnalysisProcess.length-1].analysisProcessReference
      : 
      "-"
    }
  analysisRessourceUid(decision:any) { 
      return decision && decision?.associatedAnalysisProcess && decision.associatedAnalysisProcess.length > 0 
      ?    
      decision?.associatedAnalysisProcess [decision?.associatedAnalysisProcess.length-1].analysisProcessResourceUid
      : 
      "-"
   }
}
