
import {Options, Vue} from "vue-class-component";
import store from "@/store";
import moment from "moment";
import SideBarEfficom from "@/components/general/SideBarEfficom.vue";
import i18n from "@/i18n";
import AnalysisDetails from "@/components/middle-office/tabs/decision/AnalysesReference/AnalysisDetails.vue";
import {useGeneralInfoStore} from "@/components/task/generalInfo/taskGeneralInfoStore";
import {handleUpdateEvent, Paths} from "@/utils";
import OpinionReservesCard from "@/components/task/tabs/Opinion/OpinionReservesCard.vue"
import { ReserveStatusItem } from "@/types"

@Options({
  components: {
    SideBarEfficom,
    AnalysisDetails,
    OpinionReservesCard
  },
  props: ["decisions","status","analysisReference","analysisRessourceUid"]
})
export default class CommentsReservesPanel extends Vue {
  decisions?: any[];
  status?: any
  analysisReference?: any;
  analysisRessourceUid?: any;
  componentToRenderSB = AnalysisDetails;
  componentPropsSB?: object;
  openanalysisReference = false;
  generalInfoStore = useGeneralInfoStore();
  Paths = Paths;
  ReserveStatusItem = ReserveStatusItem
  getDateFormat(date?: any) {
  return moment(date).isValid() ? moment(date).format("DD/MM/YYYY HH:mm") : ' - ';
  }
  get currentRoute() {
    return this.$router.currentRoute;
  }
  get analysis(){
    return store.state.analysisModule?.analysis
  }

  onSelect() {
     store.dispatch("middleOfficeModule/showAnalysisReferenceDetails");
  }
   onSelect1(resourceUid: string,AnalysisReference: string) {
    store.dispatch("middleOfficeModule/setanalysisReference",AnalysisReference);
    store.dispatch("taskModule/analysisModule/analysis", { data: resourceUid, isEditing: false, reCalculate: false, route: this.currentRoute }).then(()=>{
    store.dispatch("middleOfficeModule/showAnalysisReferenceDetails");
    })
  }

  get cancelButtonLabel() {
    return i18n.global.t("decision.analysisReferenceSidebar.cancelButton");
  }

  get sidebarLabel() {
    return i18n.global.t("decision.analysisReferenceSidebar.AnalysisRef") + ' ' + store.state.middleOfficeModule?.analysisReference;
  }

  get openAnalysisReferenceDetails() {
    return store.state.middleOfficeModule?.openopeAnalysisReference;
  }

  get sidebarCtaLabel() {
    return "";
  }
  get selectedDecision() {
    return store.state.taskModule.decisionModule.selectedDecision ? store.state.taskModule.decisionModule.selectedDecision : {};
  }
  get isTaskEnterOpinion() {
    return store.getters["taskModule/isTaskEnterOpinion"];
  }
  get isTaskTypeMonoDecision() {
    return store.getters["taskModule/isTaskTypeMonoDecision"];
  }
     get isTaskModifyConditions() {
    return store.getters["taskModule/isTaskModifyConditions"];
  }
  onCloseAnalysisReferenceDetails() {
    store.dispatch("showConfirmationDialog", {
      message: i18n.global.t("main.confirmationDialog.message"),
      confirmation: true
    })
  }
  get connectedUser() {
    return store.state.taskModule.decisionModule.connectedUser ? store.state.taskModule.decisionModule.connectedUser : "";
  }

}
