import {api} from "@/auth/api";
import {settings} from "@/settings";
import axios from "axios";

/**
 * useApi to execute request operations
 * @description
 * When the default path is set to false, a not default path needs to be set as the below example.
 * There isn't the necessity to pass /api/1/ to the path
 * @example
 * standard path: odm-contactmechanism
 * special path: event/odm-contactmechanism
 * @param system_uid
 * @param path
 * @param version
 * @param standardPath
 */
export const useApi = (system_uid?: string, path?: string, standardPath = true, version = 1, daaqResourceUid?: string) => {
    const URI = `/${system_uid}/api/${version}/${system_uid}`;
    const URI_NOT_DEFAULT = `/${system_uid}/api/${version}`;
    let response: any = null
    let error: any = null
    const apiInstance = () => api(daaqResourceUid);

    const get = async (resourceUid?: string) => {
        try {
            response = resourceUid ?
                (await api().get(`${standardPath ? URI : URI_NOT_DEFAULT}/${path}/${resourceUid}/`)).data :
                (await api().get(`${standardPath ? URI : URI_NOT_DEFAULT}/${path}/`)).data;

            console.info(`Entity recovered from resource ${path} and resourceUid: ${response.resourceUid ? response.resourceUid : 'Empty!'}`);
        }
        catch (e) {
            error = e
            console.error(`ERROR => ${error?.response?.data?.message?.returnMessage}`)
        }
        return { response, error }
    }

    const getWithEndPath = async (endPath: string, resourceUid?: string) => {
        try {
            response = resourceUid ?
                (await api().get(`${URI}/${path}/${resourceUid}/${endPath}/`)).data :
                (await api().get(`${URI}/${path}/`)).data;

            console.info(`Entity recovered from resource ${path} and resourceUid: ${response.resourceUid ? response.resourceUid : 'Empty!'}`);
        }
        catch (e) {
            error = e
            console.error(`ERROR => ${error?.response?.data?.message?.returnMessage}`)
        }
        return { response, error }
    }

    const upsert = async (payload: any, daaqResourceUid?: string) => {
        try {
            if (payload.resourceUid) {
                response = (await api().put(`${standardPath ? URI : URI_NOT_DEFAULT}/${path}/${payload.resourceUid}/`, payload)).data
                console.info(`PUT done with resource ${path} and resourceUid: ${payload.resourceUid}`);
            } else {
                response = (await apiInstance().post(`${standardPath ? URI : URI_NOT_DEFAULT}/${path}/`, payload)).data;
                console.info(`POST done with resource ${path}`);
            }
        }
        catch (e) {
            error = e
            console.error(`ERROR => ${error?.response?.data?.message?.returnMessage}`)
        }
        return { response, error }
    }

    const anonymous = async (payload: any) => {
        try {
            const axiosResponse = await axios.post(settings.auth_anonymous_api_url);
            response = (await api('', axiosResponse.data.token).post(`${URI}/${path}/`, payload)).data
        }
        catch (e) {
            error = e
            console.error(`ERROR => ${error?.response?.data?.message?.returnMessage}`)
        }
        return { response, error }
    }

    const remove = async (resourceUid: string, daaqResourceUid?: string) => {
        try {
            response = (await api(daaqResourceUid).delete(`${URI}/${path}/${resourceUid}/`)).data;

            console.info(`Entity deleted from resource ${path} with resourceUid: ${response.resourceUid}`);
        }
        catch (e) {
            error = e
            console.error(`ERROR => ${error?.response?.data?.message?.returnMessage}`)
        }
        return { response, error }
    }

    return { get, getWithEndPath, upsert, remove, anonymous }
}

