import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-27081300"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "card" }
const _hoisted_2 = { class: "card-body" }
const _hoisted_3 = { class: "font-semi-bold font-size-14" }
const _hoisted_4 = { class: "row" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_radio = _resolveComponent("q-radio")!
  const _component_TemplateListComponent = _resolveComponent("TemplateListComponent")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (!_ctx.modal)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: _normalizeClass(["mb-2", _ctx.offerStatusDisable ? 'disabled' : '']),
            id: 'task_taskdetails_printDocuments_SignatureMethods'
          }, [
            _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.$t('task.printDocument.signature_labels.SIGNATUREMETHOD')), 1),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.signatureMethods, (method) => {
              return (_openBlock(), _createBlock(_component_q_radio, {
                key: method.value,
                "data-testid": 'task_taskdetails_printDocuments_' + method.value,
                modelValue: _ctx.signatureMethod,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.signatureMethod) = $event)),
                val: method.value,
                label: method.label
              }, null, 8, ["data-testid", "modelValue", "val", "label"]))
            }), 128))
          ], 2))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_4, [
        (_ctx.entityIsavailable)
          ? (_openBlock(), _createBlock(_component_TemplateListComponent, {
              key: 0,
              offerId: _ctx.offer,
              modal: _ctx.modal
            }, null, 8, ["offerId", "modal"]))
          : _createCommentVNode("", true)
      ])
    ])
  ]))
}