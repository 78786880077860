
import { Vue, Options } from "vue-class-component";
import { formatWithMask,formatAmoutswitchCurrency } from "@/utils/configuration/formatters-config";
import { Proposal } from "@/types";
import LabelAndValue from "@/components/general/LabelAndValue.vue";
import store from "@/store";

@Options({
  components: { LabelAndValue },
  props: ["proposal"],
})
export default class FinancingForm extends Vue {
  formatWithMask = formatWithMask;
  proposal?: Proposal;
  formatAmoutswitchCurrency = formatAmoutswitchCurrency;
  valueStyle = {
    "font-weight": "bold",
    "font-size": "1rem",
    "margin-left": "20px",
  };
  priceWithTax(items: any): number  {
    return items.sumOfTotalPayments ? items.sumOfTotalPayments.amount : null;
  }
  priceWithoutTax(items: any): number  {
    return items.sumOfTotalPaymentsWoTax ? items.sumOfTotalPaymentsWoTax : items.businessData?.sumOfTotalPaymentsWoTax;
  }


  getInstallmentDetails (items: any) {
    return  items  && items.businessData ? {
    totalInstallmentWTax: items?.businessData.totalInstallmentWTax ?? null,  //businessData
    totalInstallmentWoTax: items?.businessData.totalInstallmentWoTax ?? null, //businessData
    sumOfFinancialPaymentsWTax: items?.businessData.sumOfFinancialPaymentsWTax ?? null,
    sumOfFinancialPaymentsWoTax: items?.businessData.sumOfFinancialPaymentsWoTax ?? null,
    residualValueAmountWTax: items?.residualValueAmountWTax ?? null,
    residualValueAmountWoTax: items?.residualValueAmountWoTax ?? null,
    sumOfTotalPaymentsWoTax: items?.businessData.sumOfTotalPaymentsWoTax ?? null,
    sumOfTotalPaymentsWTax: items?.sumOfTotalPayments.amount ?? null,
    financialPaymentWoTax: items?.financialPaymentWoTax ?? null,
    financialPaymentWTax: items?.financialPaymentWTax ?? null
    } : ''
  }

  get isExternalApi(){
    return this.offer?.businessData.API;
  }
  get offer() {
    return store.state.middleOfficeModule.offer || store.state.taskModule.offer;
  }
}
