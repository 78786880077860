import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "row mb-3" }
const _hoisted_2 = { class: "col-6 mb-2" }
const _hoisted_3 = { class: "col-6 mb-2" }
const _hoisted_4 = { class: "col-6 mb-2" }
const _hoisted_5 = { class: "col-6 mb-2" }
const _hoisted_6 = { class: "row mb-3" }
const _hoisted_7 = { class: "col-6 mb-2" }
const _hoisted_8 = { class: "col-6 mb-2" }
const _hoisted_9 = { class: "col-6 mb-2" }
const _hoisted_10 = { class: "col-6 mb-2" }
const _hoisted_11 = { class: "row mb-3" }
const _hoisted_12 = { class: "col-6 mb-2" }
const _hoisted_13 = { class: "col-6 mb-2" }
const _hoisted_14 = { key: 0 }
const _hoisted_15 = { class: "mb-3 pl-3 pr-3" }
const _hoisted_16 = { class: "same-line" }
const _hoisted_17 = { class: "text-dark font-weight-bold" }
const _hoisted_18 = { class: "shadow-0 table table-sm mb-5" }
const _hoisted_19 = { class: "text-dark font-weight-bold" }
const _hoisted_20 = { class: "text-dark font-weight-bold" }
const _hoisted_21 = { class: "text-dark font-weight-bold" }
const _hoisted_22 = { class: "text-dark font-weight-bold" }
const _hoisted_23 = { class: "same-line" }
const _hoisted_24 = { class: "text-dark font-weight-bold" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LabelAndValue = _resolveComponent("LabelAndValue")!
  const _component_OnlyValue = _resolveComponent("OnlyValue")!
  const _component_q_expansion_item = _resolveComponent("q-expansion-item")!
  const _component_q_th = _resolveComponent("q-th")!
  const _component_q_tr = _resolveComponent("q-tr")!
  const _component_q_table = _resolveComponent("q-table")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_LabelAndValue, {
          "i18n-key": 'summaryPanel.customer.firstName',
          value: _ctx.person.familyName,
          oneLine: true,
          "value-style": _ctx.valueStyle,
          "label-style": _ctx.labelStyleItem,
          "div-value-style": _ctx.divValueStyle
        }, null, 8, ["i18n-key", "value", "value-style", "label-style", "div-value-style"])
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_LabelAndValue, {
          "i18n-key": 'summaryPanel.customer.lastName',
          value: _ctx.person.firstName,
          oneLine: true,
          "value-style": _ctx.valueStyle,
          "label-style": _ctx.labelStyleItem,
          "div-value-style": _ctx.divValueStyle
        }, null, 8, ["i18n-key", "value", "value-style", "label-style", "div-value-style"])
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_LabelAndValue, {
          "i18n-key": 'summaryPanel.customer.maritalState',
          value: _ctx.person?.currentMaritalStatus?.type?.value
        ? _ctx.$t(`demand.party.statusMarital.${_ctx.person.currentMaritalStatus.type.value}`)
        : ''
        ,
          oneLine: true,
          "value-style": _ctx.valueStyle,
          "label-style": _ctx.labelStyleItem,
          "div-value-style": _ctx.divValueStyle
        }, null, 8, ["i18n-key", "value", "value-style", "label-style", "div-value-style"])
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createVNode(_component_LabelAndValue, {
          "i18n-key": 'summaryPanel.customer.birthday',
          value: `${_ctx.person.birthDate} ${_ctx.getYearsOfDate(
        _ctx.formatDateToMoment(_ctx.person.birthDate, '-')
      )} ${_ctx.$t('main.years')}`,
          oneLine: true,
          "value-style": _ctx.valueStyle,
          "label-style": _ctx.labelStyleItem,
          "div-value-style": _ctx.divValueStyle
        }, null, 8, ["i18n-key", "value", "value-style", "label-style", "div-value-style"])
      ])
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createElementVNode("div", _hoisted_7, [
        _createVNode(_component_LabelAndValue, {
          "i18n-key": 'summaryPanel.customer.citizenship',
          value: _ctx.$t(`demand.party.country.${_ctx.person.nationality.type.value}`),
          oneLine: true,
          "value-style": _ctx.valueStyle,
          "label-style": _ctx.labelStyleItem,
          "div-value-style": _ctx.divValueStyle
        }, null, 8, ["i18n-key", "value", "value-style", "label-style", "div-value-style"])
      ]),
      _createElementVNode("div", _hoisted_8, [
        _createVNode(_component_LabelAndValue, {
          "i18n-key": 'summaryPanel.customer.address',
          value: _ctx.adressType(_ctx.person.addresses),
          oneLine: true,
          "value-style": _ctx.valueStyle,
          "label-style": _ctx.labelStyleItem,
          "div-value-style": _ctx.divValueStyle
        }, null, 8, ["i18n-key", "value", "value-style", "label-style", "div-value-style"]),
        _createVNode(_component_OnlyValue, {
          class: "q-mt-xs",
          value: _ctx.adressStreetAndStreetNumber(_ctx.person.addresses) + '-',
          oneLine: true,
          "value-style": _ctx.valueStyle,
          "div-value-style": _ctx.divValueStyle
        }, null, 8, ["value", "value-style", "div-value-style"]),
        _createVNode(_component_OnlyValue, {
          class: "q-mt-xs",
          value: _ctx.adressPostalCodeAndCity(_ctx.person.addresses),
          oneLine: true,
          "value-style": _ctx.valueStyle,
          "div-value-style": _ctx.divValueStyle
        }, null, 8, ["value", "value-style", "div-value-style"])
      ]),
      _createElementVNode("div", _hoisted_9, [
        _createVNode(_component_LabelAndValue, {
          "i18n-key": 'summaryPanel.customer.phoneNumber',
          value: _ctx.contacts?.get(_ctx.ContactType?.PHONE_CONTACT),
          oneLine: true,
          "value-style": _ctx.valueStyle,
          "label-style": _ctx.labelStyleItem,
          "div-value-style": _ctx.divValueStyle
        }, null, 8, ["i18n-key", "value", "value-style", "label-style", "div-value-style"])
      ]),
      _createElementVNode("div", _hoisted_10, [
        _createVNode(_component_LabelAndValue, {
          "i18n-key": 'summaryPanel.customer.email',
          value: _ctx.contacts?.get(_ctx.ContactType?.EMAIL_CONTACT),
          oneLine: true,
          "value-style": _ctx.valueStyle,
          "label-style": _ctx.labelStyleItem,
          "div-value-style": _ctx.divValueStyle
        }, null, 8, ["i18n-key", "value", "value-style", "label-style", "div-value-style"])
      ])
    ]),
    _createElementVNode("div", _hoisted_11, [
      _createElementVNode("div", _hoisted_12, [
        _createVNode(_component_LabelAndValue, {
          "i18n-key": 'summaryPanel.customer.profession',
          value: _ctx.person?.jobs?.find((el) => !!el)?.positionType?.type?.value
        ? _ctx.$t(
          `demand.party.positionType.${_ctx.person.jobs.find((el) => !!el).positionType.type.value
          }`
        )
        : ''
        ,
          oneLine: true,
          "value-style": _ctx.valueStyle,
          "label-style": _ctx.labelStyleItem,
          "div-value-style": _ctx.divValueStyle
        }, null, 8, ["i18n-key", "value", "value-style", "label-style", "div-value-style"])
      ]),
      _createElementVNode("div", _hoisted_13, [
        _createVNode(_component_LabelAndValue, {
          "i18n-key": 'summaryPanel.customer.activity',
          value: _ctx.person.jobs?.find((el) => !!el)?.activity?.type?.value
        ? _ctx.$t(
          `demand.party.jobActivity.${_ctx.person.jobs.find((el) => !!el).activity.type.value
          }`
        )
        : ''
        ,
          oneLine: true,
          "value-style": _ctx.valueStyle,
          "label-style": _ctx.labelStyleItem,
          "div-value-style": _ctx.divValueStyle
        }, null, 8, ["i18n-key", "value", "value-style", "label-style", "div-value-style"])
      ])
    ]),
    (!_ctx.order ||(_ctx.order!=='order'))
      ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
          ((_ctx.rows && _ctx.rows.length != 0))
            ? (_openBlock(), _createBlock(_component_q_expansion_item, {
                key: 0,
                "default-opened": "",
                "expand-icon-toggle": "",
                "header-class": "align-items-center justify-content-between mb-3 mt-3",
                class: "bg-secondary"
              }, {
                header: _withCtx(() => [
                  _createElementVNode("div", null, _toDisplayString(_ctx.capitalize(
            _ctx.$t("middleOffice.party.organization.section.classification").toLowerCase()
          )), 1)
                ]),
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_15, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.rows, (item) => {
                      return (_openBlock(), _createElementBlock("div", {
                        key: item.id
                      }, [
                        _createElementVNode("div", _hoisted_16, [
                          _createElementVNode("span", null, _toDisplayString(_ctx.$t('main.type')) + ": ", 1),
                          _createElementVNode("span", _hoisted_17, _toDisplayString(_ctx.$t("picklists.partyClassificationType." + item.class_type_code)), 1)
                        ]),
                        _createElementVNode("table", _hoisted_18, [
                          _createElementVNode("thead", null, [
                            _createElementVNode("tr", null, [
                              _createElementVNode("th", _hoisted_19, _toDisplayString(_ctx.$t("task.labels.value")), 1),
                              _createElementVNode("th", _hoisted_20, _toDisplayString(_ctx.$t("communication.comMessage.User")), 1),
                              _createElementVNode("th", _hoisted_21, _toDisplayString(_ctx.$t("scales.services.filter.validity")), 1),
                              _createElementVNode("th", _hoisted_22, _toDisplayString(_ctx.$t("main.labels.classificationDate")), 1)
                            ])
                          ]),
                          _createElementVNode("tbody", null, [
                            _createElementVNode("tr", null, [
                              _createElementVNode("td", null, _toDisplayString(_ctx.$t(`demand.party.partyClass.${item.id}`)), 1),
                              _createElementVNode("td", null, _toDisplayString(item.first_name ? item.first_name + ' ' + item.last_name : _ctx.assignee), 1),
                              _createElementVNode("td", null, _toDisplayString(`${item.validity_from ? _ctx.formatedDate(item.validity_from) : ''} - ${item.validity_until ? _ctx.formatedDate(item.validity_until) : ''}`), 1),
                              _createElementVNode("td", null, _toDisplayString(_ctx.formatedDate(item.classification_date)), 1)
                            ])
                          ])
                        ])
                      ]))
                    }), 128))
                  ])
                ]),
                _: 1
              }))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true),
    (_ctx.financialExposureTable && _ctx.financialExposureTable.length != 0)
      ? (_openBlock(), _createBlock(_component_q_expansion_item, {
          key: 1,
          "default-opened": "",
          "expand-icon-toggle": "",
          "header-class": "align-items-center justify-content-between mb-3 mt-3",
          class: "bg-secondary"
        }, {
          header: _withCtx(() => [
            _createElementVNode("div", null, _toDisplayString(_ctx.capitalize(
            _ctx.$t("middleOffice.party.organization.section.exposure").toLowerCase()
          )), 1)
          ]),
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.financialExposureTable, (item, index) => {
              return (_openBlock(), _createElementBlock("div", {
                key: index,
                class: "mb-3 pl-3 pr-3"
              }, [
                _createElementVNode("div", _hoisted_23, [
                  _createElementVNode("span", null, _toDisplayString(_ctx.$t('middleOffice.party.organization.exposure.Outstanding_entity')) + ": ", 1),
                  _createElementVNode("span", _hoisted_24, _toDisplayString(item.financialInstitution || ''), 1)
                ]),
                _createVNode(_component_q_table, {
                  rows: item.outstandingTypes,
                  columns: _ctx.columns,
                  "row-key": "financialInstitution",
                  "hide-pagination": "",
                  class: "shadow-0 table table-sm mb-0"
                }, {
                  header: _withCtx((props) => [
                    _createVNode(_component_q_tr, { props: props }, {
                      default: _withCtx(() => [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(props.cols, (col) => {
                          return (_openBlock(), _createBlock(_component_q_th, {
                            key: col.name,
                            props: props,
                            class: "text-dark font-weight-bold"
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.$t(col.label)), 1)
                            ]),
                            _: 2
                          }, 1032, ["props"]))
                        }), 128)),
                        _createVNode(_component_q_th, { "auto-width": "" })
                      ]),
                      _: 2
                    }, 1032, ["props"])
                  ]),
                  _: 2
                }, 1032, ["rows", "columns"])
              ]))
            }), 128))
          ]),
          _: 1
        }))
      : _createCommentVNode("", true)
  ], 64))
}