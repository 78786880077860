
import { Options, Vue } from "vue-class-component";
import { ref } from "vue";
import { ActionType, FileContent, SupportingDocumentStatus, TaskDefinition } from "@/types";
import { addedBySystem, evaluateUiControls, globalConfig } from "@/utils";
import store from "@/store";
import moment from "moment";
import i18n from "@/i18n";
import uploadDocumentModule from "@/store/modules/task/actions/uploadDocumentModule";
import UploadPanel from "@/components/task/tabs/upload/UploadPanel.vue";
import UploadConditionsDocuments from "@/components/task/tabs/upload/UploadConditionsDocuments.vue";
import {
  processDocuments,
  ProcessedDocument,
  TargetEntity,
  uploadDocument
} from "@/commons/gathering-process";
import { viewDocument } from "@/commons/index";
import { useRoute } from "vue-router";
import { changeNbrReceeivedDoc } from "@/store/services/task/taskService";
import router from "@/router";
import { truncateText } from "@/utils";

@Options({
  props: {
    expand: {
      type: Boolean,
      required: true
    },
    disable: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  components: {
    UploadPanel,
    UploadConditionsDocuments
  },
  computed: {
    allRowsExpanded() {
      return this.getGatheringProcess.every((row: { expanded: any; }) => row.expanded);
    }
  },
  watch: {
    "$store.state.taskModule.executedAction"(action: ActionType) {
      if (action === ActionType.VALIDATE) {
        if (!store.state.taskModule.selectedTask.proceed) {
          store.commit("setLockState", false);
          store.commit("setConfirmationDialogState", {
            show: false,
          });
          router.push("/task/list");
        }
      }
    },
  },
})

export default class DocumentManagment extends Vue {
  get allRowsExpanded() {
    return this.getGatheringProcess.every((row: { expanded: any; }) => row.expanded);
  }
  expand: boolean = true;
  filter: any = ref("");
  showDialog?: boolean = true;
  ListGatheringProcessAssociated: any;
  documentType: any = ref(null);
  index = 0;
  isLoading = true;
  deleteLoading?: boolean = false;
  removing = false;
  disable: any;
  rows: any[] = [];
  files: any[] = [];
  listRequiredDocument: any[] = [];
  fileLoadingStates: any = {};
  TaskDefinition=TaskDefinition;
  viewDocument = viewDocument;
  truncateText = truncateText;

  get columns() {
    return globalConfig.table_config.uploadDocumentCol;
  }

  get getTaskEntityId() {
    return store.state.taskModule.offer?.resourceUid;
  }

  get getKsiopDocuments() {
    return store.state.taskModule.listKsiopDocuments;
  }

  get getGatheringProcess() {
    const { offer, gatheringProcess } = store.state.taskModule;

    if (!gatheringProcess[0]?.entityTargets?.length) {
        return gatheringProcess;
    }

    const associatedCustomerId = offer?.associatedParties?.[0]?.associatedParty?.third?.resourceUid;
    const entityTargets = [...gatheringProcess[0].entityTargets];
    const firstCustomerIndex = entityTargets.findIndex(
        (entity: any) => entity.resourceUid === associatedCustomerId
    );

    if (firstCustomerIndex > 0) {
        const [customerEntity] = entityTargets.splice(firstCustomerIndex, 1);
        entityTargets.unshift(customerEntity);
    }

    return [
        {
            ...gatheringProcess[0],
            entityTargets,
        },
        ...gatheringProcess.slice(1),
    ];
}

  get getFile() {
    return uploadDocumentModule.state.files
  }

  get currentRoute() {
    return useRoute().name;
  }
  isRemoveFileLoading(targetRef: any, docRef: any) {
    return this.fileLoadingStates[`${targetRef}-${docRef}`] || false;
  }
  getStatus(status: string) {
    return i18n.global.t(`task.uploadDocument.headers.supportingDocumentStatus.${status}`)
  }

  get EmptyGatheringProcessListMessage() {
    return i18n.global.t("middleOffice.documents.empty");
  }

  get taskDefinition() {
    return store.getters["taskModule/taskDefinition"];
  }

  get isTaskModifyConditions() {
    return store.getters["taskModule/isTaskModifyConditions"];
  }


  setType(type: string) {
    if (type.includes('asset')) {
      return i18n.global.t('demand.tabs.asset')
    }
    else {
      return i18n.global.t('demand.documents.columns.entityType.third')
    }
  }

  setRoles(uid: any) {
    const associatedParties = this.currentRoute === 'TaskDetail' ? store.state.taskModule.offer?.associatedParties : store.state.middleOfficeModule.offer?.associatedParties;
    let party = associatedParties?.find(party => party.associatedParty?.third?.resourceUid === uid);
    let roleCode = party ? party.associatedParty?.role_code : null;
    return i18n.global.t(`demand.party.roles.${roleCode}`);
  }
  totalDocuments() {
      let count = 0;
      this.getGatheringProcess.forEach((gatheringProcess:any) => {
        gatheringProcess.entityTargets.forEach((entity:any) => {
          count += entity.documents.length;
        });
      });
      return count;
    }
    nbrReceivedDocuments() {
      let count = 0;
      const RECEIVED = i18n.global.t(`task.uploadDocument.headers.supportingDocumentStatus.RECEIVED`);
      this.getGatheringProcess.forEach((gatheringProcess:any) => {
        gatheringProcess.entityTargets.forEach((entity:any) => {
          entity.documents.forEach((document:any) => {
            if (document.status === RECEIVED || document.status.includes('RECEIVED')) {
              count++;
            }
          });
        });
      });
      return count;
    }
  setNbrValidDocuments(row?: any) {
    let countValid = 0;
    let countTotal = 0;

    if (!row || !row.entityTargets) return '0 / 0';

    row.entityTargets.forEach((entity: any) => {
      if (!entity.documents) return;

      entity.documents.forEach((doc: any) => {
        // Increment total for every document slot
        countTotal++;

        // Only count valid documents
        if (doc.status) {
          const valid = i18n.global.t(`task.uploadDocument.headers.supportingDocumentStatus.VALID`);
          if (doc.status === valid || doc.status.includes('VALID')) {
            countValid++;
          }
        }
      });
    });

    return `${countValid} / ${countTotal}`;
  }

  setNbrTobeTreatedDocuments(row?: any) {
    let countValid = 0;
    let countRejected = 0;
    let countTotal = 0;

    if (!row || !row.entityTargets) return '0 / 0';

    row.entityTargets.forEach((entity: any) => {
      if (!entity.documents) return;

      entity.documents.forEach((doc: any) => {
        const valid = i18n.global.t(`task.uploadDocument.headers.supportingDocumentStatus.VALID`);
        const rejected = i18n.global.t(`task.uploadDocument.headers.supportingDocumentStatus.REJECTED`);

        // Increment total for every document slot
        countTotal++;

        // Count valid documents
        if (doc.status.includes(valid) || doc.status.includes('VALID')) {
          countValid++;
        }
        // Count rejected documents
        if (doc.status.includes(rejected) || doc.status.includes('REJECTED')) {
          countRejected++;
        }
      });
    });

    // Calculate documents to be treated: Total - Valid - Rejected
    const TobeTreated = countTotal - countValid - countRejected;

    return `${TobeTreated} / ${countTotal}`;
  }

  downloadDocument(row: any) {
    store.dispatch("taskModule/uploadDocumentModule/downloadFile", {
      request: row.document,
      row: row,
      callback: this.downloadDocumentResponse,
    });
  }

  onUploadClick(inputId: string) {
    document.getElementById(inputId)?.click();
  }

  handleDocumentResponse(response: FileContent, index: number, childId: number) {
    const reg = this.getGatheringProcess[index][childId];
    if (reg) {
      reg.creationDate = moment(new Date()).format("DD/MM/YYYY");
      reg.document = response || {};
      reg.status = i18n.global.t(
        `task.uploadDocument.headers.supportingDocumentStatus.RECEIVED`
      );
      reg.comment = response.originalFileName;

      let nbrReceivedItems = Number(
        this.getGatheringProcess[index].nbrReceiveditems.split("/")[0]
      );
      nbrReceivedItems++;
      this.getGatheringProcess[index].nbrReceiveditems =
        nbrReceivedItems + "/" + this.getGatheringProcess[index].length;
    }
  }
  downloadDocumentResponse(response: any) {
    if (response) {
      const { content } = response;
      const linkSource = `data:${response.format};base64,${content}`;
      const downloadLink = document.createElement("a");
      downloadLink.href = linkSource;
      downloadLink.download = response.originalFileName;
      downloadLink.click();
    }
  }

  dialogTerm(index: number) {
    store.dispatch("taskModule/showDialog", {
      request: {
        index: index,
      },
    });

    this.showDialog = !this.showDialog;
  }

  async removeFile(payload: { processIndex: number, targetRef: string, docRef: string, documentReceived: any }) {
    const { processIndex, targetRef, docRef, documentReceived } = payload;
    const gatheringProcess = this.getGatheringProcess[processIndex];
    const processedDocument = gatheringProcess.entityTargets
      .find((entity: any) => entity.reference === targetRef).documents
      .find((document: ProcessedDocument) => document.reference === docRef);

    const fileKey = `${targetRef}-${docRef}`;
    this.fileLoadingStates[fileKey] = true;

    try {
      await store.dispatch('taskModule/validateDocumentsModule/deleteProcessFile', {
        request: documentReceived,
        callback: (req: any) => {
          changeNbrReceeivedDoc(req);
          // Clear the document but keep the slot
          processedDocument.document = {};
          // Clear the status when removing the file
          processedDocument.status = "";
          this.fileLoadingStates[fileKey] = false;

          // Force update the row count
          const updatedGatheringProcess = [...this.getGatheringProcess];
          store.commit('taskModule/SET_GATHERING_PROCESS', updatedGatheringProcess);
        }
      });
    } catch (error) {
      console.error('Error removing file:', error);
      this.fileLoadingStates[fileKey] = false;
    }
  }



  updateDocument(res: any) {
    this.ListGatheringProcessAssociated = res;
    for (const associatedGatheringProcess of this.ListGatheringProcessAssociated) {
      store.dispatch("taskModule/uploadDocumentModule/getRequiredDocument", {
        request: {
          resourceUid: associatedGatheringProcess.gatheringProcess.resourceUid,
        },
        callback: (res: any) => {
          this.listRequiredDocument.push(res);
          store.dispatch("taskModule/uploadDocumentModule/getProcess", {
            request: {
              resourceUid: associatedGatheringProcess.gatheringProcess.resourceUid,
            },
            callback: (res: any) => processDocuments({
              gatheringProcess: res,
              requiredDocuments: this.listRequiredDocument[0],
              supportingDocuments: res.supportingDocuments,
              receivedFiles: res.gatheringProcessItem[0].receivedFiles
            })
          });
        },
      });
    }
  }

  remove(i: any) {
    this.files.splice(i, 1);
  }

  dragover(event: any) {
    event.preventDefault();
    // Add some visual fluff to show the user can drop its files
    if (!event.currentTarget.classList.contains("bg-green-300")) {
      event.currentTarget.classList.remove("bg-gray-100");
      event.currentTarget.classList.add("bg-green-300");
    }
  }

  dragleave(event: Event) {
    // Clean up
    const input = event.currentTarget as HTMLInputElement;
    input.classList.add("bg-gray-100");
    input.classList.remove("bg-green-300");
  }

  onSelectFile(payload: { event: Event, processIndex: number, targetRef: string, docRef: string, documentReceived?: any }) {
    const { event, processIndex, targetRef, docRef, documentReceived } = payload;
    let documentReceivedFileID;
    if (documentReceived)
      documentReceivedFileID = documentReceived.receivedFileUid
    const files = (event.target as HTMLInputElement).files as FileList;
    this.onModify({
      files, processIndex, targetRef, docRef, documentReceivedFileID
    })
  }

  dropFile(payload: { event: any, processIndex: number, targetRef: string, docRef: string, documentReceived: any }) {
    const { event, processIndex, targetRef, docRef, documentReceived } = payload;
    const documentReceivedFileID = documentReceived.receivedFileUid
    event.preventDefault();
    let newTransfer = new DataTransfer();
    let files: any;
    const originalFileName = event.dataTransfer.getData("originalFileName");
    if (!originalFileName) {
      files = event.dataTransfer.files;
    }
    else {
      files = [this.getFile[event.dataTransfer.getData("fileIndex")]];
    }

    this.onModify({ processIndex, files, targetRef, docRef, documentReceivedFileID });
    event.currentTarget.classList.add("bg-gray-100");
    event.currentTarget.classList.remove("bg-green-300");
    newTransfer.items.clear();
  }

  onModify(payload: { processIndex: number, files: FileList, targetRef: string, docRef: string, documentReceivedFileID?: string }) {
    const { processIndex, files, targetRef, docRef, documentReceivedFileID } = payload;

    const gatheringProcess = this.getGatheringProcess[processIndex];

    const document = gatheringProcess.entityTargets
      .find((entity: TargetEntity) => entity.reference === targetRef).documents
      .find((document: ProcessedDocument) => document.reference === docRef);
    if (this.taskDefinition === TaskDefinition.ANALYSIS) {
      const status = SupportingDocumentStatus.REJECTED
      store.dispatch("taskModule/validateDocumentsModule/changeSupportingDocumentStatus", {
        status: status,
        gatheringProcessRow: document,
        processIndex,
        supportingDocumentIndex: gatheringProcess,
      });
      document.status = i18n.global.t(`task.uploadDocument.headers.supportingDocumentStatus.${document.status}`)
    }
    this.handleWithDocument({ document, files, gatheringProcess, documentReceivedFileID });
  }

  async handleWithDocument(attr: {
    document: ProcessedDocument,
    files?: FileList,
    gatheringProcess: any
    documentReceivedFileID?: string
  }) {

    const { document, files, gatheringProcess, documentReceivedFileID } = attr;

    const targetEntityAttr = {
      documentType: document.documentType,
      targetEntityObjectType: document.targetEntity.objectType as string,
      targetEntityUID: document.targetEntity.resourceUid as string
    }

    const offerReference = store.state.taskModule.offer?.reference as string;
    const connectedUser = store.getters["authModule/getUserConnected"];

    const metadataMap = new Map<string, string>();
    metadataMap.set("associatedOffer", offerReference);

    document.addedBy = `${connectedUser.firstName} ${connectedUser.lastName}`;
    metadataMap.set("addedBy", `${offerReference};${connectedUser.username}`);
    metadataMap.set("documentType", targetEntityAttr.documentType);
    metadataMap.set("targetEntityObjectType", targetEntityAttr.targetEntityObjectType);
    metadataMap.set("targetEntityUID", targetEntityAttr.targetEntityUID);
    if (files) {
      uploadDocument({ files, document, gatheringProcess, metadataMap, documentReceivedFileID })
    }
  }

  addedBy(metadata: any, defaultValue?: any) {
    const AssociatedOfferReference = metadata?.find((item: any) => {
      if (item.key === 'addedBy') {
        const offerRef = this.currentRoute === 'TaskDetail' ? store.state.taskModule.offer?.reference : store.state.middleOfficeModule.offer?.reference;
        return item.value.split(";")[0] === offerRef;
      }
      return false;
    });
    return AssociatedOfferReference ? AssociatedOfferReference.value.split(";")[1] : defaultValue;
  }

  getValidatedStatus(value: string) {
    let status = '';
    let RECEIVED = i18n.global.t(`task.uploadDocument.headers.supportingDocumentStatus.RECEIVED`);
    let valid = i18n.global.t(`task.uploadDocument.headers.supportingDocumentStatus.VALID`);
    if (value.toUpperCase() === SupportingDocumentStatus.RECEIVED || value === RECEIVED) {
      status = i18n.global.t(`task.uploadDocument.headers.supportingDocumentStatus.RECEIVED`)
    }
    else if (value.toUpperCase() === SupportingDocumentStatus.VALID || value === valid) {
      status = i18n.global.t(`task.uploadDocument.headers.supportingDocumentStatus.VALID`)
    }
    return status
  }

  supportingDocumentStatusOptions(docStatus?: string): any {
  if (this.taskDefinition === TaskDefinition.ANALYSIS) {
    let statusOptions = store.getters["picklistModule/SupportingDocumentStatusItems"]
      .filter((item: { value: string, label: string, config: any }) => item.value !== docStatus)
      .map((status: { value: string, label: string, config: any }) => {
        return {
          value: status.value,
          label: status.label,
          internalCode: status.config.internalCode
        }
      });
   statusOptions = statusOptions.filter((status: any) => status?.value);
    return statusOptions;
  } else {
    return Object.keys(SupportingDocumentStatus).map((status: string) => {
      return {
        label: i18n.global.t(
          `task.validateDocument.headers.supportingDocumentStatus.${status}`
        ),
        value: status,
      };
    });
  }
}


  getStatusClass(value: string) {
    if (value === 'VALID' || value === "Validé" || value === i18n.global.t(`task.uploadDocument.headers.supportingDocumentStatus.VALID`)) {
      return "positive";
    } else if (value === 'REJECTED' || value === "Refusé" || value === i18n.global.t(`task.uploadDocument.headers.supportingDocumentStatus.REJECTED`)) {
      return "negative";
    } else {
      return "black";
    }
  }


  deleteManualDocument(document: any) {
    this.removing = true
    store.dispatch('taskModule/validateDocumentsModule/deleteManualDocument', {
      request: document,
      callback: store.dispatch("taskModule/validateDocumentsModule/getGatheringProcessList")
    })
    setTimeout(() => this.removing = false, 1000);
  }

  async onSupportingDocumentStatusChange(payload: { event: any, document: any, processIndex: number, targetRef: string }) {
    let { event, processIndex, document, targetRef } = payload;
    const gatheringProcess = this.getGatheringProcess[processIndex];
    if (this.taskDefinition === TaskDefinition.VALIDATE_DOCUMENTS) {
      const docRef = document.reference
      document = gatheringProcess.entityTargets
        .find((entity: TargetEntity) => entity.reference === targetRef).documents
        .find((document: ProcessedDocument) => document.reference === docRef);
    }
    try {


      // Find the selected status option with its internalCode
      const selectedStatus = this.supportingDocumentStatusOptions(document.status).find(
        (option:any) => option.value === event
      );

      if (!selectedStatus) {
        throw new Error('Invalid status selected');
      }


      const result = await store.dispatch("taskModule/validateDocumentsModule/changeSupportingDocumentStatus", {
        status: selectedStatus,
        gatheringProcessRow: document,
        processIndex,
        supportingDocumentIndex: gatheringProcess,
      });
      const updatedStatus = result.data.status.resourceUid
      document.status = i18n.global.t(`task.uploadDocument.headers.supportingDocumentStatus.${updatedStatus}`)
      if (updatedStatus === 'VALID')
        changeNbrReceeivedDoc('DECREMENT')
    } catch (error) {
      console.error("Error in changeSupportingDocumentStatus:", error);
    }
    finally{ 
    console.log('updated and treated ')
    this.totalDocuments();
    this.nbrReceivedDocuments();
    }
  }


  updateDocument1(inputId: string) {
    document.getElementById(inputId)?.click()

  }

  t(value: any) {
    if (addedBySystem.includes(value)) {
      return i18n.global.t('task.supportingDocumentLabel.system')
    }
    else return value
  }

  created() {
    if (this.taskDefinition === TaskDefinition.UPLOAD_DOCUMENTS) {
      evaluateUiControls('upload-documents-task', 'insert', this.$router, true)
      store.dispatch("taskModule/uploadDocumentModule/getOfferDocument", {
        callback: this.updateDocument,
        request: {
          variable: this.getTaskEntityId,
        },
      });
    } else if (this.taskDefinition === TaskDefinition.ANALYSIS) {
      setTimeout(() => {
        this.isLoading = false
      }, 2000)
    } else if (this.taskDefinition === TaskDefinition.VALIDATE_DOCUMENTS) {
      // evaluateUiControls('validate-document-task', 'insert', this.$router, true);
      store.dispatch("taskModule/validateDocumentsModule/getGatheringProcessList");
    }

    if (this.getGatheringProcess.length > 0) {
      this.getGatheringProcess[0].entityTargets.length ? this.getGatheringProcess[0].entityTargets[0].expanded = true : null;
    }
  }


  updated() {
    if (this.getGatheringProcess.length > 0) {
      this.getGatheringProcess[0].entityTargets[0].expanded = true;
    }
    this.$emit('update')
  }

  toggleAllRows() {
    const allExpanded = this.getGatheringProcess.every((row: { expanded: any; }) => row.expanded);
    this.getGatheringProcess.forEach((row: { expanded: boolean; entityTargets: any[]; }) => {
      row.expanded = !allExpanded;
      row.entityTargets.forEach(targetEntity => {
        targetEntity.expanded = !allExpanded;
      });
    });
    this.expand = !allExpanded;
  }

}
