import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1ab984dd"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "row mb-5" }
const _hoisted_2 = {
  key: 0,
  class: "col-6 mb-2"
}
const _hoisted_3 = {
  key: 1,
  class: "col-6 mb-2"
}
const _hoisted_4 = {
  key: 2,
  class: "col-6 mb-2"
}
const _hoisted_5 = {
  key: 3,
  class: "col-6 mb-2"
}
const _hoisted_6 = {
  key: 4,
  class: "col-6 mb-2"
}
const _hoisted_7 = {
  key: 5,
  class: "col-6 mb-2"
}
const _hoisted_8 = {
  key: 6,
  class: "col-6 mb-2"
}
const _hoisted_9 = {
  key: 7,
  class: "col-6 mb-2"
}
const _hoisted_10 = {
  key: 8,
  class: "col-6 mb-2"
}
const _hoisted_11 = { class: "row" }
const _hoisted_12 = { class: "col-6 mb-2" }
const _hoisted_13 = { class: "col-6 mb-2" }
const _hoisted_14 = { class: "col-6 mb-2" }
const _hoisted_15 = { class: "col-6 mb-2" }
const _hoisted_16 = { class: "col-6 mb-2" }
const _hoisted_17 = { class: "col-6 mb-2" }
const _hoisted_18 = { class: "col-6 mb-2" }
const _hoisted_19 = { class: "col-6 mb-2" }
const _hoisted_20 = { class: "col-6 mb-2" }
const _hoisted_21 = { class: "col-6 mb-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LabelAndValue = _resolveComponent("LabelAndValue")!

  return (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.proposal.proposalItems, (items, index) => {
    return (_openBlock(), _createElementBlock("div", {
      class: "card border-0 pr-3 font-size-12 h-100",
      key: index
    }, [
      _createElementVNode("div", _hoisted_1, [
        (items.financedValue)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createElementVNode("div", null, [
                _createVNode(_component_LabelAndValue, {
                  i18nKey: 'middleOfficeFinancingForm.label.financedValue',
                  value: `${_ctx.formatAmoutswitchCurrency('EUR', items.financedValue)} `
                }, null, 8, ["i18nKey", "value"])
              ])
            ]))
          : _createCommentVNode("", true),
        (items.financingProductPack?.resourceUid)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              _createElementVNode("div", null, [
                _createVNode(_component_LabelAndValue, {
                  i18nKey: 'middleOfficeFinancingForm.label.financingProduct',
                  value: `${items.financingProduct.replace('PROFILE.', '')}`
                }, null, 8, ["i18nKey", "value"])
              ])
            ]))
          : _createCommentVNode("", true),
        (items.financingProduct?.replace('PROFILE.', '')==='RACC')
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              _createVNode(_component_LabelAndValue, {
                i18nKey: 'middleOfficeFinancingForm.label.downPayment',
                value: `${_ctx.formatAmoutswitchCurrency('EUR', items.downPayment)}`
              }, null, 8, ["i18nKey", "value"])
            ]))
          : (_openBlock(), _createElementBlock("div", _hoisted_5, [
              _createVNode(_component_LabelAndValue, {
                i18nKey: 'middleOfficeFinancingForm.label.firstPaymentWTax',
                value: `${_ctx.formatAmoutswitchCurrency('EUR', items.firstPaymentWTax)}`
              }, null, 8, ["i18nKey", "value"])
            ])),
        (items.downPayment)
          ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
              _createVNode(_component_LabelAndValue, {
                i18nKey: 'middleOfficeFinancingForm.label.downPayment',
                value: `${_ctx.formatAmoutswitchCurrency('EUR', items.downPayment!)}`
              }, null, 8, ["i18nKey", "value"])
            ]))
          : _createCommentVNode("", true),
        (items.mileage)
          ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
              _createElementVNode("div", null, [
                _createVNode(_component_LabelAndValue, {
                  i18nKey: 'middleOfficeFinancingForm.label.mileage',
                  value: `${items.mileage} km`
                }, null, 8, ["i18nKey", "value"])
              ])
            ]))
          : _createCommentVNode("", true),
        (items.numberOfPaymentTerm)
          ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
              _createElementVNode("div", null, [
                _createVNode(_component_LabelAndValue, {
                  i18nKey: 'middleOfficeFinancingForm.label.numberOfPaymentTerm',
                  value: `${items.numberOfPaymentTerm} ${_ctx.$t(
              `middleOfficeFinancingForm.month`
            )}`
                }, null, 8, ["i18nKey", "value"])
              ])
            ]))
          : _createCommentVNode("", true),
        (items.nominalRate)
          ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
              _createElementVNode("div", null, [
                _createVNode(_component_LabelAndValue, {
                  i18nKey: 'middleOfficeFinancingForm.label.nominalRate',
                  value: `${_ctx.formatAmoutswitchCurrency('EUR', items.nominalRate).replace('€','%')}`
                }, null, 8, ["i18nKey", "value"])
              ])
            ]))
          : _createCommentVNode("", true),
        (items.residualValueAmountWTax)
          ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
              _createElementVNode("div", null, [
                _createVNode(_component_LabelAndValue, {
                  i18nKey: 'middleOfficeFinancingForm.label.residualValueAmount',
                  value: `${_ctx.formatAmoutswitchCurrency('EUR', items.residualValueAmountWTax)}    (Soit ${_ctx.formatAmoutswitchCurrency('EUR', items.residualValuePercentage).replace('€','%')})`
                }, null, 8, ["i18nKey", "value"])
              ])
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_11, [
          (_ctx.isExternalApi && items?.businessData)
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                _createElementVNode("div", _hoisted_12, [
                  _createElementVNode("div", null, [
                    _createVNode(_component_LabelAndValue, {
                      i18nKey: `${this.$t('middleOfficeFinancingForm.label.financialPaymentWoTax')} (${this.$t('middleOfficeFinancingForm.label.horsPrestation')})`,
                      value: `${_ctx.formatAmoutswitchCurrency('EUR', _ctx.getInstallmentDetails(items).financialPaymentWoTax)}`
                    }, null, 8, ["i18nKey", "value"])
                  ])
                ]),
                _createElementVNode("div", _hoisted_13, [
                  _createElementVNode("div", null, [
                    _createVNode(_component_LabelAndValue, {
                      i18nKey: `${this.$t('middleOfficeFinancingForm.label.financialPaymentWTax')} (${this.$t('middleOfficeFinancingForm.label.horsPrestation')})`,
                      value: `${_ctx.formatAmoutswitchCurrency('EUR', _ctx.getInstallmentDetails(items).financialPaymentWTax)}`
                    }, null, 8, ["i18nKey", "value"])
                  ])
                ]),
                _createElementVNode("div", _hoisted_14, [
                  _createElementVNode("div", null, [
                    _createVNode(_component_LabelAndValue, {
                      i18nKey: `${this.$t('middleOfficeFinancingForm.label.financialPaymentWoTax')} (${this.$t('middleOfficeFinancingForm.label.avecPrestation')})`,
                      value: `${_ctx.formatAmoutswitchCurrency('EUR', _ctx.getInstallmentDetails(items).totalInstallmentWoTax)}`
                    }, null, 8, ["i18nKey", "value"])
                  ])
                ]),
                _createElementVNode("div", _hoisted_15, [
                  _createElementVNode("div", null, [
                    _createVNode(_component_LabelAndValue, {
                      i18nKey: `${this.$t('middleOfficeFinancingForm.label.financialPaymentWTax')} (${this.$t('middleOfficeFinancingForm.label.avecPrestation')})`,
                      value: `${_ctx.formatAmoutswitchCurrency('EUR', _ctx.getInstallmentDetails(items).totalInstallmentWTax)}`
                    }, null, 8, ["i18nKey", "value"])
                  ])
                ])
              ], 64))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_16, [
            _createElementVNode("div", null, [
              _createVNode(_component_LabelAndValue, {
                i18nKey: `${this.$t('middleOfficeFinancingForm.label.totalAmountWoInsuranceHT')} (${this.$t('middleOfficeFinancingForm.label.sansAquisition')})`,
                value: `${_ctx.formatAmoutswitchCurrency('EUR', _ctx.priceWithoutTax(items))}`
              }, null, 8, ["i18nKey", "value"])
            ])
          ]),
          _createElementVNode("div", _hoisted_17, [
            _createElementVNode("div", null, [
              _createVNode(_component_LabelAndValue, {
                i18nKey: `${this.$t('middleOfficeFinancingForm.label.totalAmountWInsuranceTTC')} (${this.$t('middleOfficeFinancingForm.label.sansAquisition')})`,
                value: `${_ctx.formatAmoutswitchCurrency('EUR', _ctx.priceWithTax(items))}`
              }, null, 8, ["i18nKey", "value"])
            ])
          ]),
          (_ctx.isExternalApi && items?.businessData)
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                _createElementVNode("div", _hoisted_18, [
                  _createElementVNode("div", null, [
                    _createVNode(_component_LabelAndValue, {
                      i18nKey: `${this.$t('middleOfficeFinancingForm.label.sumOfFinancialPaymentsWoTax')} (${this.$t('middleOfficeFinancingForm.label.sansAquisition')})`,
                      value: `${_ctx.formatAmoutswitchCurrency('EUR',_ctx.getInstallmentDetails(items).sumOfFinancialPaymentsWoTax)}`
                    }, null, 8, ["i18nKey", "value"])
                  ])
                ]),
                _createElementVNode("div", _hoisted_19, [
                  _createElementVNode("div", null, [
                    _createVNode(_component_LabelAndValue, {
                      i18nKey: `${this.$t('middleOfficeFinancingForm.label.sumOfFinancialPaymentsWTax')} (${this.$t('middleOfficeFinancingForm.label.sansAquisition')})`,
                      value: `${_ctx.formatAmoutswitchCurrency('EUR',_ctx.getInstallmentDetails(items).sumOfFinancialPaymentsWTax)}`
                    }, null, 8, ["i18nKey", "value"])
                  ])
                ])
              ], 64))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_20, [
            _createElementVNode("div", null, [
              _createVNode(_component_LabelAndValue, {
                i18nKey: `${this.$t('middleOfficeFinancingForm.label.sumOfTotalPaymentsWoTax')} (${this.$t('middleOfficeFinancingForm.label.avecAquisition')})`,
                value: `${_ctx.formatAmoutswitchCurrency('EUR', _ctx.priceWithoutTax(items) + items.residualValueAmountWoTax)}`
              }, null, 8, ["i18nKey", "value"])
            ])
          ]),
          _createElementVNode("div", _hoisted_21, [
            _createElementVNode("div", null, [
              _createVNode(_component_LabelAndValue, {
                i18nKey: `${this.$t('middleOfficeFinancingForm.label.sumOfTotalPaymentsWTax')} (${this.$t('middleOfficeFinancingForm.label.avecAquisition')})`,
                value: `${_ctx.formatAmoutswitchCurrency('EUR', _ctx.priceWithTax(items) + items.residualValueAmountWTax)}`
              }, null, 8, ["i18nKey", "value"])
            ])
          ])
        ])
      ])
    ]))
  }), 128))
}