import { globalConfig, parseTaskVariables } from "@/utils";
import {
  Config,
  DocumentUpload,
  Dossier,
  MoneyAmount,
  Picklist,
  Third,
  ThirdType,
  UniformOdmName,
  Validity,
  screenAuthorityKeys,
} from "@/types";
import i18n from "@/i18n";
import moment from "moment";
import {
  formatAmoutswitchCurrency,
  formatDateLocale,
  formatWithMask,
} from "@/utils/configuration/formatters-config";
import { ProposalGuaranteeClass } from "@/store/services/financing/guarantee/domain/ProposalGuaranteeClass";
import { AssociatedPartyClass } from "@/store/services/financing/guarantee/domain/AssociatedPartyClass";
import { Column } from "@/store/services/tabledata";

function sortRowsDate(
  a: any,
  b: any,
  rowA: any,
  rowB: any,
  format?: string,
  locale?: string
) {
  const dateA = moment(a, format || "DD/MM/YYYY HH:mm", locale || "fr");
  const dateB = moment(b, format || "DD/MM/YYYY HH:mm", locale || "fr");
  return dateA.isSame(dateB) ? 0 : dateA.isAfter(dateB) ? 1 : -1;
}
export function getName(value: string) {
  if (value && ["true", "false"].includes(value)) {
    return `${i18n.global.t(
      "task.historiesStep.headers.histories.".concat(value)
    )}`;
  } else if (value && value !== "") {
    return value.split("##")[0];
  }
}

export function partyCols(type: string) {
  if (type === ThirdType.PERSON || type === ThirdType.MANAGER) {
    return personCol;
  }
  return organizationCol;
}
export const colsRevenusMensuels = [
  {
    name: "type",
    label: "Type",
    align: "left",
    field: (row: { nature: Picklist }) => row.nature,
    sortable: false,
  },
  {
    name: "periodicity",
    label: "task.tabs.periodicity",
    align: "left",
    field: (row: { periodicity: Picklist }) => row.periodicity,
    sortable: false,
  },
  {
    name: "value",
    label: "demand.simulation.form.label",
    align: "left",
    classes: "tw-min-width-cell",
    field: (row: { value: number }) => row.value,
    sortable: false,
  },
  {
    name: "from",
    label: "main.labels.startDate",
    align: "left",
    field: (row: { validity: string }) => JSON.parse(row.validity).from,
    sortable: false,
  },
  {
    name: "until",
    label: "main.labels.endDate",
    align: "left",
    field: (row: { validity: string }) => JSON.parse(row.validity).until,
    sortable: false,
  },
  {
    name: "action",
    label: "",
    align: "right",
    sortable: false,
  },
];

export const serviceCols = [
  {
    name: "config",
    label: "Sel.",
    align: "left",
    type: "boolean",
    field: (row: { config: Config }) => row.config,
    sortable: true,
  },
  {
    name: "name",
    label: "demand.product.services.columns.name",
    align: "center",
    field: (row: { resourceUid: string }) =>
      i18n.global.t(
        `demand.product.services.names.${row.resourceUid.split(".").join("_")}`
      ),
    sortable: true,
  },
  {
    name: "basis",
    label: "demand.product.services.columns.basis",
    align: "center",
    field: (row: { basis: any }) => row.basis,
    sortable: true,
  },
  {
    name: "amountMin",
    label: "demand.product.services.columns.amountMin",
    align: "center",
    field: (row: { amountMin: MoneyAmount }) => row.amountMin?.amount || "",
    sortable: true,
  },
  {
    name: "amount",
    label: "demand.product.services.columns.amount",
    align: "center",
    field: (row: { amount: MoneyAmount }) => row.amount?.amount || "",
    sortable: true,
  },
  {
    name: "amountMax",
    label: "demand.product.services.columns.amountMax",
    align: "center",
    field: (row: { amountMax: MoneyAmount }) => row.amountMax?.amount || "",
    sortable: true,
  },
  {
    name: "qt",
    label: "demand.product.services.columns.qt",
    align: "center",
    field: (row: { qt: string }) => row.qt,
    sortable: true,
  },
  {
    name: "duration",
    label: "demand.product.services.columns.duration",
    align: "center",
    field: (row: { paymentDatesOption: string }) =>
      i18n.global.t(
        `demand.product.services.duration.${row.paymentDatesOption}`
      ),
    sortable: true,
  },

  {
    name: "annualRate",
    label: "demand.product.services.columns.annualRate",
    align: "center",
    field: (row: { annualRate: number }) => row.annualRate,
    sortable: true,
  },
  {
    name: "annualRateMin",
    label: "demand.product.services.columns.annualRateMin",
    align: "center",
    field: (row: { annualRateMin: any }) => row.annualRateMin,
    sortable: true,
  },
  {
    name: "annualRateMax",
    label: "demand.product.services.columns.annualRateMax",
    align: "center",
    field: (row: { annualRateMax: any }) => row.annualRateMax,
    sortable: true,
  },

  // {
  //     name: 'montantHt',
  //     label: 'Montant HT',
  //     align: 'center',
  //     field: (row: { montantHt: string; }) => row.montantHt,
  //     sortable: true
  // },
  // {
  //     name: 'montantTtc',
  //     label: ' Montant TTC',
  //     align: 'center',
  //     headerStyle:'max-width:10px',
  //     field: (row: { montantTtc: string; }) => row.montantTtc,
  //     sortable: true
  // }
];

export const personCol = [
  {
    name: "familyName",
    label: "middleOffice.party.organization.contact.familyName",
    align: "left",
    field: (row: { familyname: string }) => row.familyname,
    sortable: true,
  },
  {
    name: "firstName",
    label: "middleOffice.party.organization.contact.firstName",
    align: "left",
    field: (row: { firstname: string }) => row.firstname,
    sortable: true,
  },
  {
    name: "birthDate",
    label: "middleOffice.party.person.identity.birthDate",
    align: "left",
    field: (row: { birthdate: string }) =>
      globalConfig.formatters.formatDate(new Date(row.birthdate)),
    sortable: true,
  },
  {
    name: "phoneNumber",
    label: "middleOffice.party.person.identity.phoneNumber",
    align: "left",
    field: (row: { phonenumber: string }) => row.phonenumber,
    sortable: true,
  },
  {
    name: "city",
    label: "middleOffice.party.organization.address.city",
    align: "left",
    field: (row: { city: string }) => row.city,
    sortable: true,
  },
  {
    name: "postalCode",
    label: "middleOffice.party.organization.address.codePostal",
    align: "left",
    field: (row: { postalcode: string }) => row.postalcode,
    sortable: true,
  },
];

export const demandFilterCols = [
  {
    name: "reference",
    label: "demand.filtreMenu.demandReference", // We need just to put the translate I18n Key and the translation will be done on the .vue file with getlabel methode
    align: "left",
    field: (row: { reference: string }) => row.reference,
    sortVariable: {
      "table": "offer_offer",
      "name": "reference"
    },
  },
  {
    name: "externalReference",
    label: "demand.filtreMenu.externeReference",
    align: "left",
    field: (row: { external_reference: string }) =>
      row.external_reference ? row.external_reference : "-",
    sortVariable: {
      "table": "offer_offer",
      "name": "external_reference"
    },
  },
  {
    name: "familyname",
    label: "demand.filtreMenu.name",
    align: "left",
    field: (row: { familyname: string }) =>
      row.familyname ? row.familyname : "-",
    sortVariable: {
      "table": "party_party",
      "name": "family_name"
    },
  },
  {
    name: "firstname",
    label: "demand.filtreMenu.firstName",
    align: "left",
    field: (row: { firstname: string }) =>
      row.firstname ? row.firstname : "-",
    sortVariable: {
      "table": "party_party",
      "name": "first_name"
    },
  },
  {
    name: "commercialname",
    label: "demand.filtreMenu.companyName",
    align: "left",
    field: (row: { commercialname: string }) =>
      row.commercialname ? row.commercialname : "-",
    sortVariable: {
      "table": "party_party",
      "name": "commercial_name"
    },
  },
  {
    name: "brand",
    label: "demand.filtreMenu.brand",
    align: "left",
    field: (row: { brand: string }) =>
      row.brand ? row.brand.replace(/asset.good.vehicle.brand./g, "") : "",
    sortVariable: {
      "table": "offer_proposal_asset",
      "name": "brand"
    },
  },
  {
    name: "range",
    label: "demand.filtreMenu.range",
    align: "left",
    field: (row: { range: string }) => {
      const match = row.range ? row.range.match(/\.([^.]*)$/) : null;
      return match ? match[1] : "-";
    },
    sortVariable: {
      "table": "offer_proposal_asset",
      "name": "range"
    },
  },
  {
    name: "monthlyinstallement",
    label: "demand.filtreMenu.monthlyinstallement",
    align: "left",
    field: (row: { monthlyinstallement: number }) =>
      row.monthlyinstallement ? formatAmoutswitchCurrency(
        "EUR",
        row.monthlyinstallement 
      ): "-",
    sortVariable: {
      table: "offer_offer",
      name: "_when_created",
    },
  },
  
  
  {
    name: "demandCreator",
    label: "demand.filtreMenu.demandCreator",
    align: "left",
    field: (row: { businessdata: any }) => {
      if (!row.businessdata) return "-";
    
      const businessdata = JSON.parse(row.businessdata);
      const seller = businessdata.seller;
    
      return seller
        ? `${seller.firstName || ""} ${seller.lastName || ""}`.trim()
        : "-";
    },
    sortVariable: {
      table: "offer_offer",
      name: "_when_created",
    },
  },


  {
    name: "statuscode",
    label: "demand.filtreMenu.statusDemand",
    align: "left",
    field: (row: { statuscode: string }) =>
      row.statuscode
        ? i18n.global.t(`enum.offer.status.${row.statuscode}`)
        : "",
    sortVariable: {
      table: "offer_offer",
      name: "_when_created",
    },
  },
  {
    name: "whenModified",
    label: "demand.filtreMenu.whenModified",
    align: "left",
    field: (row: { whenmodified: Date }) =>
      row.whenmodified ? moment(row.whenmodified).format("DD/MM/YYYY HH:mm")  : "-",
    sortVariable: {
      table: "offer_offer",
      name: "_when_created",
    },
  },
];
export const partyFilterCols = [
  {
    name: "reference",
    label: "communication.comMessage.referenceLabel",
    align: "left",
    field: (row: { reference: string }) => (row.reference ? row.reference : ""),
    format: (val: string) => `${val}`,
    sortable: true,
  },
  {
    name: "discriminator",
    label: "demand.filtreMenu.filtresList.Type_client",
    align: "left",
    field: (row: { _discriminator: string }) =>
      i18n.global.t(`enum.party.type.${row._discriminator}`),
    format: (val: string) => `${val}`,
    sortable: true,
  },
  {
    name: "clientStatus",
    label: "task.validateDocument.headers.status",
    align: "left",
    field: (row: { current_status_code: string }) =>
        row.current_status_code
            ? i18n.global.t(`party.role.${row.current_status_code.toLowerCase()}`)
            : "",
    format: (val: string) => `${val}`,
    sortable: true,
  },
  {
    name: "role_code",
    label: "middleOffice.party.organization.role.role",
    align: "left",
    field: (row: { role_code: string }) =>
      row.role_code
        ? i18n.global
            .t("demand.party.roles.".concat(row.role_code))
            .toUpperCase()
        : "",
    format: (val: string) => `${val}`,
    sortable: true,
  },
  {
    name: "name",
    label: "middleOffice.party.organization.contact.familyName",
    align: "left",
    field: (row: { family_name: string }) =>
      row.family_name ? row.family_name : "",
    format: (val: string) => `${val}`,
    sortable: true,
  },
  {
    name: "first_name",
    label: "middleOffice.party.organization.contact.firstName",
    align: "left",
    field: (row: { first_name: string }) =>
      row.first_name ? row.first_name : "",
    format: (val: string) => `${val}`,
    sortable: true,
  },
  {
    name: "birth_date",
    label: "middleOffice.party.person.identity.birthDate",
    align: "left",
    field: (row: { birth_date: string }) =>
      row.birth_date
        ? globalConfig.formatters.formatDate(new Date(row.birth_date))
        : "",
    format: (val: string) => `${val}`,
    sortable: true,
  },
  {
    name: "commercial_name",
    label: "task.labels.organizationName",
    align: "left",
    field: (row: { commercial_name: string }) =>
      row.commercial_name ? row.commercial_name : "",
    format: (val: string) => `${val}`,
    sortable: true,
  },
  {
    name: "SIREN",
    label: "demand.filtreMenu.SIREN",
    align: "left",
    field: (row: { siren: string }) => (row.siren ? row.siren : ""),
    format: (val: string) => `${val}`,
    sortable: true,
  },
  {
    name: "SIRET",
    label: "middleOffice.party.organization.establishments.siret",
    align: "left",
    field: (row: { siret: string }) => (row.siret ? row.siret : ""),
    format: (val: string) => `${val}`,
    sortable: true,
  },
];

export const organizationCol = [
  {
    name: "name",
    label: "middleOffice.party.organization.contact.familyName",
    align: "left",
    field: (row: { name: string }) => row.name,
    sortable: true,
  },
  {
    name: "siren",
    label: "N SIREN/SIRET",
    align: "left",
    field: (row: { siren: string }) => row.siren,
    sortable: true,
  },
  {
    name: "city",
    label: "middleOffice.party.organization.address.city",
    align: "left",
    field: (row: { city: string }) => row.city,
    sortable: true,
  },
  {
    name: "postal_code",
    label: "middleOffice.party.organization.address.codePostal",
    align: "left",
    field: (row: { postal_code: string }) => row.postal_code,
    sortable: true,
  },
];

export const searchBankAgency = [
  { name: "id", align: "left", label: "Id", field: "id" },
  { name: "name", align: "left", label: "Name", field: "name" },
  { name: "code", align: "left", label: "Code", field: "code" },
  {
    name: "country_code",
    align: "left",
    label: "Country",
    field: "country_code",
  },
  { name: "end_date", align: "left", label: "End Date", field: "end_date" },
  { name: "address_id", align: "left", label: "Address", field: "address_id" },
  { name: "bank_id", align: "left", label: "Bank", field: "bank_id" },
  { name: "code_swift", align: "left", label: "Swift", field: "code_swift" },
];

export const expertMode = {
  stepCol: [
    { name: "config", align: "left", label: "Sel", field: "config" },
    {
      name: "id",
      align: "left",
      label: "demand.financialSituation.bearing",
      field: "id",
    },
    {
      name: "duration",
      align: "left",
      label: "demand.sliders.duration",
      field: "duration",
    },
    { name: "terme", align: "left", label: "Terme", field: "terme" },
    {
      name: "calculationMethod",
      align: "left",
      label: "Méthode de calcul",
      field: "calculationMethod",
    },
    {
      name: "paymentValue",
      align: "left",
      label: "Échéance (€)",
      field: "paymentValue",
    },
    {
      name: "paymentRate",
      align: "left",
      label: "Échéance (%)",
      field: "paymentRate",
    },
    {
      name: "periodicity",
      align: "left",
      label: "Fréquence",
      field: "periodicity",
    },
    {
      name: "periodicityCode",
      align: "left",
      label: "Code Fréquence",
      field: "periodicityCode",
    },
    { name: "rate", align: "left", label: "Taux", field: "rate" },
    {
      name: "residualValue",
      align: "left",
      label: "Montant Encours",
      field: "residualValue",
    },
    {
      name: "residualValueRate",
      align: "left",
      label: "% Encours",
      field: "residualValueRate",
    },
    { name: "actions", field: "actions" },
  ],
  servicesCol: [
    { name: "id", align: "left", label: "Service Nº", field: "id" },
    {
      name: "typeService",
      align: "left",
      label: "Type Service",
      field: "typeService",
    },
    { name: "name", align: "left", label: "Libellé", field: "name" },
    {
      name: "montantTtc",
      align: "left",
      label: "Montant (€)",
      field: "montantTtc",
    },
    { name: "valuePercent", align: "left", label: "%", field: "valuePercent" },
    {
      name: "baseCalcule",
      align: "left",
      label: "Basis",
      field: "baseCalcule",
    },
    {
      name: "duration",
      align: "left",
      label: "demand.sliders.duration",
      field: "duration",
    },
    { name: "term", align: "left", label: "Term", field: "term" },
    {
      name: "dtDebut",
      align: "left",
      label: "main.labels.startDate",
      field: "dtDebut",
    },
    {
      name: "paymentFrequence",
      align: "left",
      label: "Fréquence de paiement",
      field: "paymentFrequence",
    },
    { name: "multiple", align: "left", label: "Multiple", field: "multiple" },
    { name: "actions", field: "actions" },
  ],
};

export const asset = {
  accessoriesCol: [
    {
      name: "accessory",
      align: "left",
      label: "Acessorie",
      field: "accessory",
      style: "width: 24.5rem",
    },
    {
      name: "unitaryPrice",
      align: "left",
      label: "Prix Unitaire HT",
      field: "unitaryPriceAccessory",
      style: "width: 12.4rem",
    },
    {
      name: "discount",
      align: "left",
      label: "demand.asset.delivery",
      field: "discountAccessory",
      style: "width: 6.5rem",
    },
    {
      name: "priceWithDiscount",
      align: "left",
      label: "demand.asset.unit_price_ht_after_discount",
      field: "priceWithDiscountAccessory",
      style: "width: 12.3rem",
    },
    {
      name: "tax",
      align: "left",
      label: "Taxe",
      field: "taxAccessory",
      style: "width: 7rem",
    },
    {
      name: "priceWithTax",
      align: "left",
      label: "demand.asset.unit_price_ttc_after_discount",
      field: "priceWithTaxAccessory",
    },
    { name: "actions", field: "actions" },
  ],
  miscellaneousCostCol: [
    {
      name: "miscellaneousCost",
      align: "left",
      label: "demand.asset.OTHER_CAR",
      field: "miscellaneousCost",
      style: "width: 24.5rem",
    },
    {
      name: "unitaryPrice",
      align: "left",
      label: "Prix Unitaire HT",
      field: "unitaryPriceMiscellaneous",
      style: "width: 12.4rem",
    },
    {
      name: "discount",
      align: "left",
      label: "demand.asset.delivery",
      field: "discountMiscellaneous",
      style: "width: 6.5rem",
    },
    {
      name: "priceWithDiscount",
      align: "left",
      label: "demand.asset.unit_price_ht_after_discount",
      field: "priceWithDiscountMiscellaneous",
      style: "width: 12.3rem",
    },
    {
      name: "tax",
      align: "left",
      label: "Taxe",
      field: "taxMiscellaneous",
      style: "width: 7rem",
    },
    {
      name: "priceWithTax",
      align: "left",
      label: "demand.asset.unit_price_ttc_after_discount",
      field: "priceWithTaxMiscellaneous",
    },
    { name: "actions", field: "actions" },
  ],
  accessoriesColAutomatic: [
    {
      name: "id",
      label: "",
      align: "left",
      type: "boolean",
      field: (row: { selected: boolean }) => row.selected,
      sortable: true,
    },
    {
      name: "accessory",
      label: "Accessory",
      align: "left",
      style: "width: 19.5rem;",
      field: (row: { accessory: any }) => row.accessory,
      sortable: true,
    },
    {
      name: "unitaryPrice",
      label: "Prix Unitaire HT",
      align: "center",
      field: (row: { unitaryPrice: any }) => row.unitaryPrice,
      sortable: true,
    },
    {
      name: "discount",
      label: "demand.asset.delivery",
      align: "center",
      headerStyle: "width: 100px",
      field: (row: { discount: any }) => row.discount,
      sortable: true,
    },
    {
      name: "priceWithDiscount",
      label: "Prix Unitaire HT Remisé",
      align: "center",
      field: (row: { priceWithDiscount: string }) => row.priceWithDiscount,
      sortable: true,
    },
    {
      name: "tax",
      label: "Taxe",
      align: "center",
      field: (row: { tax: any }) => row.tax,
      sortable: true,
    },
    {
      name: "priceWithTax",
      label: "Prix Unitaire TTC Remisé",
      align: "center",
      field: (row: { priceWithTax: string }) => row.priceWithTax,
      sortable: true,
    },
    {
      name: "actions",
      label: "",
      align: "center",
      sortable: true,
    },
  ],
  optionsCol: [
    {
      name: "id",
      label: "",
      align: "left",
      type: "boolean",
      field: (row: { selected: boolean }) => row.selected,
      sortable: true,
    },
    {
      name: "option",
      label: "Option",
      align: "left",
      style: "width: 19.5rem",
      field: (row: { option: any }) => row.option,
      sortable: true,
    },
    {
      name: "unitaryPrice",
      label: "Prix Unitaire HT",
      align: "center",
      field: (row: { unitaryPrice: any }) => row.unitaryPrice,
      sortable: true,
    },
    {
      name: "discount",
      label: "demand.asset.delivery",
      align: "center",
      headerStyle: "width: 100px",
      field: (row: { discount: any }) => row.discount,
      sortable: true,
    },
    {
      name: "priceWithDiscount",
      label: "Prix Unitaire HT Remisé",
      align: "center",
      field: (row: { priceWithDiscount: string }) => row.priceWithDiscount,
      sortable: true,
    },
    {
      name: "tax",
      label: "Taxe",
      align: "center",
      field: (row: { tax: any }) => row.tax,
      sortable: true,
    },
    {
      name: "priceWithTax",
      label: "Prix Unitaire TTC Remisé",
      align: "center",
      field: (row: { priceWithTax: string }) => row.priceWithTax,
      sortable: true,
    },
    {
      name: "actions",
      label: "",
      align: "center",
      sortable: true,
    },
  ],
  packsCol: [
    {
      name: "id",
      label: "",
      align: "left",
      type: "boolean",
      field: (row: { selected: boolean }) => row.selected,
      sortable: true,
    },
    {
      name: "pack",
      label: "Pack",
      align: "left",
      style: "width: 19.5rem",
      field: (row: { pack: any }) => row.pack,
      sortable: true,
    },
    {
      name: "unitaryPrice",
      label: "Prix Unitaire HT",
      align: "center",
      field: (row: { unitaryPrice: any }) => row.unitaryPrice,
      sortable: true,
    },
    {
      name: "discount",
      label: "demand.asset.delivery",
      align: "center",
      headerStyle: "width: 100px",
      field: (row: { discount: any }) => row.discount,
      sortable: true,
    },
    {
      name: "priceWithDiscount",
      label: "Prix Unitaire HT Remisé",
      align: "center",
      field: (row: { priceWithDiscount: string }) => row.priceWithDiscount,
      sortable: true,
    },
    {
      name: "tax",
      label: "Taxe",
      align: "center",
      field: (row: { tax: any }) => row.tax,
      sortable: true,
    },
    {
      name: "priceWithTax",
      label: "Prix Unitaire TTC Remisé",
      align: "center",
      field: (row: { priceWithTax: string }) => row.priceWithTax,
      sortable: true,
    },
    {
      name: "actions",
      label: "",
      align: "center",
      sortable: true,
    },
  ],
  contentCol: [
    {
      name: "label",
      label: "Description",
      align: "left",
      style: "width: 26.9rem; padding-left: 6rem",
      field: (row: { label: any }) => row.label,
      sortable: true,
    },
    {
      name: "unitaryPrice",
      label: "Prix Unitaire HT",
      align: "left",
      style: "width: 6.8rem",
      field: (row: { unitaryPrice: any }) => row.unitaryPrice,
      sortable: true,
    },
    {
      name: "discount",
      label: "demand.asset.delivery",
      align: "left",
      style: "width: 11rem",
      field: (row: { discount: any }) => row.discount,
      sortable: true,
    },
    {
      name: "priceWithDiscount",
      label: "Prix Unitaire HT Remisé",
      align: "left",
      style: "width: 9.1rem",
      field: (row: { priceWithDiscount: string }) => row.priceWithDiscount,
      sortable: true,
    },
    {
      name: "tax",
      label: "Taxe",
      align: "left",
      style: "width: 9.2rem",
      field: (row: { tax: any }) => row.tax,
      sortable: true,
    },
    {
      name: "priceWithTax",
      label: "Prix Unitaire TTC Remisé",
      align: "left",
      field: (row: { priceWithTax: string }) => row.priceWithTax,
      sortable: true,
    },
  ],
};

function parseVariables(variables: any) {
  const { partyInfo, offerInfo, offerStatus, customerName, offerReference } =
    parseTaskVariables(variables);
  const quoteInfoStatus = offerStatus;
  const offerResourceUid = offerInfo?.reference;
  return {
    partyInfo,
    quoteInfoStatus,
    offerResourceUid,
    customerName,
    offerReference,
  };
}

const isOrderModule = (row: any) => {
  const { module } = row.variables;
  return module && module.value === "Order";
};

export const taskCol = [
  {
    name: "businessKey",
    label: "demand.filtreMenu.demandReference",
    align: "left",
    field: (row: any) => {
      const { offerReference, orderReference } = row.variables;
      return isOrderModule(row)
        ? orderReference?.value || orderReference
        : offerReference?.value || offerReference;
    },
    sortVariable: "processVariable.offerReference"
  },
  {
    name: "proposalReference",
    label: "demand.filtreMenu.externeReference",
    align: "left",
    field: (row: any) => {
      const { proposalReference } = row.variables;

      return proposalReference && proposalReference.value !=="null" ? proposalReference?.value || proposalReference :"-";
    },
    sortVariable: "processVariable.proposalReference"
  },
  {
    name: "firstName",
    label: "task.labels.clientName",
    align: "left",
    field: (row: any) => {
      const { customerName } = row.variables;
      return customerName.value || customerName || "-";
    },
    sortVariable: "processVariable.customerName"
  },
  {
    name: "customerType",
    label: "demand.filtreMenu.filtresList.Type_client",
    align: "left",
    field: (row: any) => {
      const { customerType } = row.variables;
  
      if (customerType?.value === ThirdType.PERSON) {
        return i18n.global.t("task.labels.particular"); 
      } else if (customerType?.value === ThirdType.ORGANIZATION) {
        return i18n.global.t("task.labels.professionnal"); 
      }
  
      return customerType?.value || customerType || "-";
    },
    sortVariable: "processVariable.customerType"
  },
  
  {
    name: "assignee",
    label: "task.labels.taskAssignee",
    align: "left",
    field: (row: any) =>
      row.assignee?.substring(0, row.assignee?.indexOf(" ## ")) || " - ",
    sortVariable: "processVariable.assignee"
  },

  {
    name: "actionDate",
    label: "task.labels.interventionDate",
    align: "left",
    field: (row: any) => moment(row.creationDate).format("DD/MM/YYYY HH:mm"),
    sortable: false,
  },
  {
    name: "status",
    label: "task.labels.offerStatus",
    align: "left",
    field: (row: any) => {
      const { offerStatus, orderStatus } = row.variables;
      return isOrderModule(row)
        ? orderStatus?.value
          ? i18n.global.t(`order.picklist.status.${orderStatus?.value}`)
          : "-" || orderStatus
          ? i18n.global.t(`order.picklist.status.${orderStatus}`)
          : "-"
        : offerStatus?.value
        ? i18n.global.t(`enum.offer.status.${offerStatus?.value}`)
        : "-" || offerStatus
        ? i18n.global.t(`enum.offer.status.${offerStatus}`)
        : "-";
    },
    sortVariable: "processVariable.offerStatus"
  },
  {
    name: "taskName",
    label: "task.labels.action",
    align: "left",
    field: (row: any) => i18n.global.t(`task.actions.${row.taskDefinition}`),
    sortVariable: "processVariable.taskName"
  },

];

export const uploadDocumentCol = [
  {
    name: "justifyType",
    label: "demand.documents.columns.justifyType",
    align: "left",
    field: (row: { typeJustify: string }) =>
      `${i18n.global.t(
        "task.supportingDocumentLabel.".concat(row.typeJustify)
      )}`,
    sortable: false,
  },
  {
    name: "documentType",
    label: "demand.documents.columns.documentType",
    align: "left",
    field: (row: { documentType: string }) =>
      `${i18n.global.t(
        "task.supportingDocumentLabel.".concat(row.documentType)
      )}` || " - ",
    sortable: false,
  },
  {
    name: "document",
    label: "demand.documents.columns.document",
    align: "left",
    field: (row: { document: DocumentUpload }) => row.document || " - ",
    sortable: false,
  },
  {
    name: "creationDate",
    label: "demand.documents.columns.creationDate",
    align: "left",
    field: (row: { creationDate: string }) => row.creationDate || " - ",
    sortable: false,
  },
  {
    name: "status",
    label: "demand.documents.columns.status",
    align: "left",
    field: (row: { status: string }) => row.status,
    sortable: false,
  },
  {
    name: "comment",
    label: "demand.documents.columns.comment",
    align: "left",
    field: (row: { comment: string }) => row.comment || " - ",
    sortable: false,
  },
  {
    name: "typeAction",
    label: "Action",
    align: "left",
    field: (row: { typeAction: string }) => row.typeAction || "  ",
    sortable: false,
  },
];

export const validateDocumentCol = [
  {
    name: "justifyType",
    label: "demand.documents.columns.justifyType",
    align: "left",
    field: (row: { typeJustify: string }) =>
      `${i18n.global.t(
        "task.supportingDocumentLabel.".concat(row.typeJustify)
      )}`,
    sortable: false,
  },
  {
    name: "documentType",
    label: "demand.documents.columns.documentType",
    align: "left",
    field: (row: { documentType: string }) =>
      `${i18n.global.t(
        "task.supportingDocumentLabel.".concat(row.documentType)
      )}`,
    sortable: false,
  },
  {
    name: "document",
    label: "demand.documents.columns.document",
    align: "left",
    field: (row: { document: DocumentUpload }) => row.document,
    sortable: false,
  },
  {
    name: "creationDate",
    label: "demand.documents.columns.creationDate",
    align: "left",
    field: (row: { creationDate: string }) => row.creationDate,
    sortable: false,
  },
  {
    name: "status",
    label: "demand.documents.columns.status",
    align: "left",
    field: (row: { status: string }) => row.status,
    sortable: false,
  },
  {
    name: "comment",
    label: "demand.documents.columns.comment",
    align: "left",
    field: (row: { comment: string }) => row.comment || " - ",
    sortable: false,
  },
  {
    name: "typeAction",
    label: "Action",
    align: "right",
    field: (row: { typeAction: string }) => row.typeAction || "  ",
    sortable: false,
  },
];

export const middleOfficeTable = {
  decisionsReserve: [
    {
      name: "reserve",
      label: "decision.label.RESERVE",
      align: "left",
      field: (row: { resourceUid: string }) => row.resourceUid,
      sortable: false,
    },
    {
      name: "comment",
      label: "decision.generalInfo.COMMENT",
      align: "left",
      field: (row: { comment: string }) => row.comment,
      sortable: false,
    },
  ],
  histories: [
    {
      name: "actionType",
      label: "task.historiesStep.headers.histories.actionType",
      align: "left",
      field: (row: { actionType: string }) =>
        `${i18n.global.t(
          "task.historiesStep.headers.histories.".concat(row.actionType)
        )}`,
      sortable: false,
    },
    {
      name: "userId",
      label: "task.historiesStep.headers.histories.userId",
      align: "left",
      field: (row: { userId: string }) => row.userId,
      sortable: false,
    },
    {
      name: "oldValue",
      label: "task.historiesStep.headers.histories.oldValue",
      align: "left",
      field: (row: { oldValue: string }) => getName(row.oldValue || ""),
      sortable: false,
    },
    {
      name: "newValue",
      label: "task.historiesStep.headers.histories.newValue",
      align: "left",
      field: (row: { newValue: string }) => getName(row.newValue || ""),
      sortable: false,
    },
    {
      name: "actionDate",
      label: "task.historiesStep.headers.histories.actionDate",
      align: "left",
      field: (row: { actionDate: string }) =>
        moment(row.actionDate).format("DD/MM/YYYY HH:mm"),
      sortable: false,
    },
  ],
  preConditions: [
    {
      name: "status",
      label: "middleOffice.precondition.checkpoint.Status",
      align: "left",
      field: (row: { status: string }) => (row.status ? row.status : ""),
      sortable: false,
    },
    {
      name: "type",
      label: "middleOffice.precondition.checkpoint.Type",
      align: "left",
      field: (row: { type: string }) => (row.type ? row.type : ""),
      sortable: false,
    },
    {
      name: "label",
      label: "middleOffice.precondition.checkpoint.Label",
      align: "left",
      field: (row: { label: string }) => (row.label ? row.label : ""),
      sortable: false,
    },
    {
      name: "actionDate",
      label: "middleOffice.precondition.checkpoint.CreationDate",
      align: "left",
      field: (row: { actionDate: string }) =>
        row.actionDate ? moment(row.actionDate).format("DD/MM/YYYY HH:mm") : "",
      sortable: false,
    },
    {
      name: "realisationDate",
      label: "middleOffice.precondition.checkpoint.CompletionDate",
      align: "left",
      field: (row: { realisationDate: string }) =>
        row.realisationDate
          ? moment(row.realisationDate, "DD/MM/YYYY").format("DD/MM/YYYY HH:mm")
          : "",
      sortable: false,
    },
    {
      name: "user",
      label: "middleOffice.precondition.checkpoint.EditingUser",
      align: "left",
      field: (row: { user: string }) => (row.user ? row.user : ""),
      sortable: false,
    },
    {
      name: "action",
      label: "",
      align: "center",
      sortable: false,
    },
  ],
  documents: [
    {
      name: "justifyType",
      label: "demand.documents.columns.justifyType",
      align: "left",
      field: (row: { typeJustify: string }) =>
        `${i18n.global.t(
          "task.supportingDocumentLabel.".concat(row.typeJustify)
        )}`,
      sortable: false,
    },
    {
      name: "documentType",
      label: "demand.documents.columns.documentType",
      align: "left",
      field: (row: { documentType: string }) =>
        `${i18n.global.t(
          "task.supportingDocumentLabel.".concat(row.documentType)
        )}` || " - ",
      sortable: false,
    },
    {
      name: "document",
      label: "demand.documents.columns.document",
      align: "left",
      field: (row: { document: DocumentUpload }) => row.document || " - ",
      sortable: false,
    },
    {
      name: "creationDate",
      label: "demand.documents.columns.creationDate",
      align: "left",
      field: (row: { creationDate: string }) =>
        row.creationDate
          ? moment(row.creationDate).format("DD/MM/YYYY HH:mm")
          : " - ",
      sortable: false,
    },
    {
      name: "status",
      label: "demand.documents.columns.status",
      align: "left",
      field: (row: { status: string }) => row.status,
      sortable: false,
    },
    {
      name: "comment",
      label: "demand.documents.columns.comment",
      align: "left",
      field: (row: { comment: string }) => row.comment || " - ",
      sortable: false,
    },
    {
      name: "typeAction",
      label: "Action",
      align: "right",
      field: (row: { typeAction: string }) => row.typeAction || "  ",
      sortable: false,
    },
  ],
};

export const financingRequestCol = [
  {
    name: "actionName",
    style: "width: 34%",
    align: "left",
    field: (row: { name: string }) =>
      `${i18n.global.t("task.actions." + row.name)}`,
    label: "sendFinancingRequest.columns.label.actionName",
    sortable: false,
  },
  {
    name: "actionUser",
    style: "width: 33%",
    align: "left",
    field: (row: { user: string }) => row.user,
    label: "sendFinancingRequest.columns.label.actionUser",
  },
  {
    name: "actionDate",
    style: "width: 33%",
    align: "left",
    field: (row: { date: string }) => row.date,
    //format: (val: any) => formatDate(val),
    label: "sendFinancingRequest.columns.label.actionDate",
  },
];
export const middelOfficeServiceCols = [
  {
    name: "rate",
    label: "middleOfficeService.columns.label.rate",
    align: "left",
    type: "boolean",
    field: (row: { rate: string }) => row.rate,
    sortable: true,
  },
  {
    name: "basis",
    label: "middleOfficeService.columns.label.basis",
    align: "left",
    field: (row: { basis: any }) => row.basis,
    sortable: true,
  },
  {
    name: "basisValue",
    label: "middleOfficeService.columns.label.basisValue",
    align: "left",
    field: (row: { basisValue: MoneyAmount }) => row.basisValue || "",
    sortable: true,
  },
  {
    name: "proposalAccessoryCalculationMethodOption",
    label:
      "middleOfficeService.columns.label.proposalAccessoryCalculationMethodOption",
    align: "left",
    field: (row: { proposalAccessoryCalculationMethodOption: string }) =>
      row.proposalAccessoryCalculationMethodOption || "",
    sortable: true,
  },
  {
    name: "proposalAccessoryPaymentDatesOption",
    label:
      "middleOfficeService.columns.label.proposalAccessoryPaymentDatesOption",
    align: "left",
    field: (row: { proposalAccessoryPaymentDatesOption: string }) =>
      row.proposalAccessoryPaymentDatesOption || "",
    sortable: true,
  },
  {
    name: "taxValue",
    label: "middleOfficeService.columns.label.taxValue",
    align: "left",
    field: (row: { taxValue: string }) => row.taxValue,
    sortable: true,
  },
];
export const partyInsuranceCol = [
  {
    name: "partyName",
    label: "demand.financialSituation.partyName",
    align: "left",
    isAmount: false,
    field: (row: { partyName: any }) => row.partyName,
    sortable: true,
  },
  {
    name: "partyRole",
    label: "demand.financialSituation.partyRole",
    align: "left",
    isAmount: false,
    field: (row: { partyRole: any }) => {
      return `${i18n.global.t("demand.party.roles.".concat(row.partyRole))}`;
    },
    sortable: true,
  },
  {
    name: "coverage",
    label: "demand.financialSituation.coverage",
    align: "right",
    isAmount: false,
    field: (row: { coverage: any }) => row.coverage,
    sortable: true,
    editable: true,
  },
  {
    name: "amountValueWoTax",
    label: "demand.financialSituation.amountValueWoTax",
    align: "right",
    isAmount: true,
    field: (row: { amountWoTax: number; paymentDatesOption: string }) => {
      if (!row.amountWoTax) {
        return "-";
      }
      return `${formatAmoutswitchCurrency("EUR", row.amountWoTax)} ${
        row.paymentDatesOption === "INSTALLMENT" ? "/Mois" : ""
      }`;
    },
    sortable: true,
  },
  {
    name: "amountValueWTax",
    label: "demand.financialSituation.amountValueWTax",
    align: "right",
    isAmount: true,
    field: (row: {
      amountWTax: number;
      paymentDatesOption: string;
      rate: string;
    }) => {
      if (!row.amountWTax) {
        return "-";
      }
      return `${formatAmoutswitchCurrency("EUR", row.amountWTax)} ${
        row.paymentDatesOption === "INSTALLMENT" ? "/Mois" : ""
      }`;
    },
    sortable: true,
  },
];

export const scalesFilterCols = [
  {
    name: "reference",
    label: "scales.filter.reference",
    align: "left",
    field: (row: { reference: string }) => row.reference,
    sortable: true,
  },
  {
    name: "name",
    label: "scales.filter.name",
    align: "left",
    field: (row: { name: string }) => row.name,
    sortable: true,
  },
  {
    name: "validity_from",
    label: "scales.filter.validityFrom",
    align: "center",
    field: (row: { validity_from: string }) =>
      row.validity_from ? row.validity_from : "",
    sortable: true,
  },
  {
    name: "validity_until",
    label: "scales.filter.validityTo",
    align: "center",
    field: (row: { validity_until: string }) =>
      row.validity_until ? row.validity_until : "",
    sortable: true,
  },
  {
    name: "financial_profile",
    label: "scales.filter.financialProfile",
    align: "left",
    field: (row: { financial_profile: string }) =>
      row.financial_profile?.substring(
        row.financial_profile?.lastIndexOf(".") + 1
      )
        ? row.financial_profile?.substring(
            row.financial_profile?.lastIndexOf(".") + 1
          )
        : "",
    sortable: true,
  },
  {
    name: "status_code",
    label: "scales.filter.status",
    align: "left",
    field: (row: { status_code: string }) =>
      row.status_code ? row.status_code : "",
    sortable: true,
  },
];
export const serviceFilterCols = [
  {
    name: "reference",
    label: "scales.filter.reference",
    align: "left",
    field: (row: { reference: string }) => row.reference,
    sortable: true,
  },
  {
    name: "name",
    label: "scales.services.filter.name",
    align: "left",
    field: (row: { name: string }) => row.name,
    sortable: true,
  },
  {
    name: "validity",
    label: "scales.services.filter.validity",
    align: "center",
    field: (row: { validity: Validity }) =>
      row.validity.from
        ? `From: ${row.validity.from} To: ${row.validity.until}`
        : "",
    sortable: true,
  },
  {
    name: "type",
    label: "scales.services.filter.type",
    align: "left",
    field: (row: { serviceType: string }) =>
      i18n.global.t(`scales.picklist.serviceTypes.${row.serviceType}`),
    sortable: true,
  },
  {
    name: "TAEG",
    label: "scales.services.filter.TAEG",
    align: "left",
    field: (row: { apcr: string }) => (row.apcr ? row.apcr : "false"),
    sortable: true,
  },
  {
    name: "paymentType",
    label: "scales.services.filter.paymentType",
    align: "left",
    field: (row: { paymentType: string }) =>
      row.paymentType ? row.paymentType : "",
    sortable: true,
  },
  {
    name: "status_code",
    label: "scales.services.filter.status",
    align: "left",
    field: (row: { status: string }) => (row.status ? row.status : ""),
    sortable: true,
  },
];
export const assetFilterCols = [
  {
    name: "reference",
    label: "good.goodFilter.goodReference",
    align: "left",
    field: (row: { reference: string }) => (row.reference ? row.reference : ""),
    format: (val: string) => `${val}`,
    sortable: true,
  },
  {
    name: "name",
    label: "good.goodFilter.goodName",
    align: "left",
    field: (row: { name: string }) => (row.name ? row.name : ""),
    format: (val: string) => `${val}`,
    sortable: true,
  },
  {
    name: "type",
    label: "asset.filtreMenu.assetType",
    align: "left",
    field: (row: { type: string }) => (row.type ? row.type : ""),
    format: (val: string) => `${val}`,
    sortable: true,
  },
  {
    name: "marque",
    label: "good.goodFilter.goodBrand",
    align: "left",
    field: (row: { marque: string }) => (row.marque ? row.marque : ""),
    format: (val: string) => `${val}`,
    sortable: true,
  },
  {
    name: "model",
    label: "good.goodFilter.goodRange",
    align: "left",
    field: (row: { model: string }) => (row.model ? row.model : ""),
    format: (val: string) => `${val}`,
    sortable: true,
  },
  {
    name: "famille",
    label: "good.goodFilter.goodFamily",
    align: "left",
    field: (row: { famille: string }) => (row.famille ? row.famille : ""),
    format: (val: string) => `${val}`,
    sortable: true,
  },
  {
    name: "status",
    label: "good.goodFilter.goodStatus",
    align: "left",
    field: (row: { status: string }) => (row.status ? row.status : ""),
    format: (val: string) => `${val}`,
    sortable: true,
  },
];

export const ScrenConfigurationFilterCols = [
  {
    name: "item",
    label: "Item",
    align: "left",
    field: (row: { item: string }) => (row.item ? row.item : ""),
    format: (val: string) => `${val}`,
    sortable: true,
  },
  {
    name: "entityLabel",
    label: "entityLabel",
    align: "left",
    field: (row: { entityLabel: string }) =>
      row.entityLabel ? row.entityLabel : "",
    format: (val: string) => `${val}`,
    sortable: true,
  },
  {
    name: "odm",
    label: "odm",
    align: "left",
    field: (row: { odm: string }) => (row.odm ? row.odm : ""),
    format: (val: string) => `${val}`,
    sortable: true,
  },
  {
    name: "entityName",
    label: "entityName",
    align: "left",
    field: (row: { entityName: string }) =>
      row.entityName ? row.entityName : "",
    format: (val: string) => `${val}`,
    sortable: true,
  },
  {
    name: "internalCode",
    label: "internalCode",
    align: "left",
    field: (row: { internalCode: string }) =>
      row.internalCode ? row.internalCode : "",
    format: (val: string) => `${val}`,
    sortable: true,
  },
];

export const catalogCols = [
  {
    name: "reference",
    label: "good.goodDetails.goodCatalogs.catalogReference",
    align: "left",
    field: (row: { reference: string }) => (row.reference ? row.reference : ""),
    format: (val: string) => `${val}`,
    sortable: true,
  },
  {
    name: "name",
    label: "good.goodDetails.goodCatalogs.catalogName",
    align: "left",
    field: (row: { name: string }) => (row.name ? row.name : ""),
    format: (val: string) => `${val}`,
    sortable: true,
  },
  {
    name: "validity.from",
    label: "Date de Création",
    align: "left",
    field: (row: { validity: { from?: Date } }) =>
      row.validity && row.validity.from ? row.validity.from : "",
    format: (val: Date) => `${val}`,
    sortable: true,
  },
  {
    name: "validity.until",
    label: "good.goodDetails.goodCatalogs.catalogUntil",
    align: "left",
    field: (row: { validity?: { until?: Date } }) =>
      row.validity && row.validity.until ? row.validity.until : "",
    format: (val: Date) => `${val}`,
    sortable: true,
  },
  {
    name: "currency",
    label: "good.goodDetails.goodCatalogs.catalogCurrency",
    align: "left",
    field: (row: { currency: string }) => (row.currency ? row.currency : ""),
    format: (val: string) => `${val}`,
    sortable: true,
  },
  {
    name: "price",
    label: "good.goodDetails.goodCatalogs.catalogPrice",
    align: "left",
    field: (row: { price: number }) => (row.price ? row.price : ""),
    format: (val: number) => `${val}`,
    sortable: true,
  },
  {
    name: "catalogPrice",
    label: "good.goodDetails.goodCatalogs.catalogCatalogPrice",
    align: "left",
    field: (row: { catalogPrice: number }) =>
      row.catalogPrice ? row.catalogPrice : "",
    format: (val: number) => `${val}`,
    sortable: true,
  },
];

export const searchCatalogCols = [
  {
    name: "reference",
    label: "Référence",
    align: "left",
    field: (row: { reference: string }) => (row.reference ? row.reference : ""),
    format: (val: string) => `${val}`,
    sortable: true,
  },
  {
    name: "name",
    label: "Libellé",
    align: "left",
    field: (row: { name: string }) => (row.name ? row.name : ""),
    format: (val: string) => `${val}`,
    sortable: true,
  },
  {
    name: "validity.from",
    label: "Date de Création",
    align: "left",
    field: (row: { validity?: { from?: Date } }) =>
      row.validity && row.validity.from ? row.validity.from : "",
    format: (val: Date) => `${val}`,
    sortable: true,
  },
  {
    name: "validity.until",
    label: "Date d'expiration",
    align: "left",
    field: (row: { validity?: { until?: Date } }) =>
      row.validity && row.validity.until ? row.validity.until : "",
    format: (val: Date) => `${val}`,
    sortable: true,
  },
];

export const garantyFilterCols = [
  {
    name: "reference",
    label: "communication.comMessage.referenceLabel",
    align: "left",
    field: (row: { reference: string }) => (row.reference ? row.reference : ""),
    format: (val: string) => `${val}`,
    sortable: true,
  },
  {
    name: "guaranty_name",
    label: "guarantees.labels.label",
    align: "left",
    field: (row: { guaranty_name: string }) =>
      row.guaranty_name ? row.guaranty_name : "",
    format: (val: string) => `${val}`,
    sortable: true,
  },
  {
    name: "_discriminator",
    label: "guarantees.labels.guaranteeType",
    align: "left",
    field: (row: { _discriminator: string }) =>
      row._discriminator ? row._discriminator : "",
    format: (val: string) => `${val}`,
    sortable: true,
  },
  {
    name: "status_code",
    label: "scales.services.filter.status",
    align: "left",
    field: (row: { status_code: string }) =>
      row.status_code ? row.status_code : "",
    format: (val: string) => `${val}`,
    sortable: true,
  },
];

export const guaranteeCols = [
  {
    name: "guaranteeType",
    label: "guarantees.labels.guaranteeType",
    align: "left",
    field: (row: ProposalGuaranteeClass) => row.productGuarantee?.guaranteeType,
    sortable: true,
  },
  {
    name: "label",
    label: "guarantees.labels.label",
    align: "left",
    field: (row: ProposalGuaranteeClass) => row.productGuarantee?.name,
    sortable: true,
  },
  {
    name: "guarantor",
    label: "guarantees.labels.guarantor",
    align: "left",
    field: (row: ProposalGuaranteeClass) => {
      if (row.associatedParty.third) {
        return AssociatedPartyClass.guarantorName(
          row.associatedParty.third as Third
        );
      } else {
        if (row.associatedParty.party && row.associatedParty.party.label) {
          return row.associatedParty.party.label;
        }
      }
    },
    sortable: true,
  },
  {
    name: "evaluationMethod",
    label: "guarantees.labels.evaluationMethod",
    align: "left",
    field: (row: ProposalGuaranteeClass) =>
      (row.productGuarantee?.evaluationMethod as UniformOdmName)?.resourceUid,
    sortable: true,
  },
  {
    name: "value",
    label: "guarantees.labels.value",
    align: "left",
    field: (row: ProposalGuaranteeClass) => {
      return `${formatWithMask(row.amount.amount, 2)} €`;
    },
    sortable: true,
  },
];

export const availabilitiesFilterCols = [
  {
    name: "name",
    label: "availabilities.filter.name",
    align: "left",
    field: (row: { application_name: string }) => row.application_name,
    sortable: true,
  },
  {
    name: "context",
    label: "availabilities.filter.context",
    align: "left",
    field: (row: { context: string }) =>
      row.context?.substring(row.context?.lastIndexOf(".") + 1)
        ? row.context?.substring(row.context?.lastIndexOf(".") + 1)
        : "",
    sortable: true,
  },
  {
    name: "validity_from",
    label: "availabilities.filter.validityFrom",
    align: "center",
    field: (row: { validity_from: string }) =>
      row.validity_from ? row.validity_from : "",
    sortable: true,
  },
  {
    name: "validity_until",
    label: "availabilities.filter.validityTo",
    align: "center",
    field: (row: { validity_until: string }) =>
      row.validity_until ? row.validity_until : "",
    sortable: true,
  },
];
export const scalesFinancial = [
  {
    name: "reference",
    label: "scales.filter.reference",
    align: "left",
    field: (row: { reference: string }) => row.reference,
    sortable: true,
  },
  {
    name: "name",
    label: "scales.filter.name",
    align: "left",
    field: (row: { name: string }) => row.name,
    sortable: true,
  },
  {
    name: "creationDate",
    label: "scales.filter.creationDate",
    align: "left",
    field: (row: any) => moment(row._when_created).format("DD/MM/YYYY HH:mm"),
    sortable: true,
  },
  {
    name: "validity_from",
    label: "scales.filter.validityFrom",
    align: "center",
    field: (row: { validity_from: string }) =>
      row.validity_from ? row.validity_from : "",
    sortable: true,
  },
  {
    name: "validity_until",
    label: "scales.filter.validityTo",
    align: "center",
    field: (row: { validity_until: string }) =>
      row.validity_until ? row.validity_until : "",
    sortable: true,
  },
  {
    name: "status_code",
    label: "scales.filter.status",
    align: "left",
    field: (row: { status_code: string }) =>
      row.status_code ? row.status_code : "",
    sortable: true,
  },
];
export const scalesServicesSearch = [
  {
    name: "discriminator",
    label: "scales.filter.discriminator",
    align: "left",
    field: (row: { _discriminator: string }) =>
      i18n.global.t(
        `scales.picklist.serviceTypes.${row._discriminator.replace(
          "product-",
          ""
        )}`
      ),
    sortable: true,
  },
  {
    name: "reference",
    label: "scales.filter.reference",
    align: "left",
    field: (row: { reference: string }) => row.reference,
    sortable: true,
  },
  {
    name: "name",
    label: "scales.filter.name",
    align: "left",
    field: (row: { name: string }) => row.name,
    sortable: true,
  },
  {
    name: "creationDate",
    label: "task.labels.interventionDate",
    align: "left",
    field: (row: any) => moment(row._when_created).format("DD/MM/YYYY HH:mm"),
    sortable: true,
  },
  {
    name: "validity_from",
    label: "scales.filter.validityFrom",
    align: "center",
    field: (row: { validity_from: string }) =>
      row.validity_from ? row.validity_from : "",
    sortable: true,
  },
  {
    name: "validity_until",
    label: "scales.filter.validityTo",
    align: "center",
    field: (row: { validity_until: string }) =>
      row.validity_until ? row.validity_until : "",
    sortable: true,
  },
  {
    name: "status_code",
    label: "scales.filter.status",
    align: "left",
    field: (row: { status_code: string }) =>
      row.status_code ? row.status_code : "",
    sortable: true,
  },
];
export const searchGuaranteeFilterCols = [
  {
    name: "reference",
    label: "communication.comMessage.referenceLabel",
    align: "left",
    field: (row: { productReference: string }) =>
      row.productReference ? row.productReference : "",
    format: (val: string) => `${val}`,
    sortable: true,
  },
  {
    name: "guaranty_name",
    label: "guarantees.labels.label",
    align: "left",
    field: (row: { productName: string }) =>
      row.productName ? row.productName : "",
    format: (val: string) => `${val}`,
    sortable: true,
  },
  {
    name: "categoryClass",
    label: "guarantees.labels.guaranteeType",
    align: "left",
    field: (row: { classifications: any; categoryClass: string }) =>
      row.classifications[0].categoryClass
        ? row.classifications[0].categoryClass
        : "",
    format: (val: string) => `${val}`,
    sortable: true,
  },
  {
    name: "status_code",
    label: "scales.services.filter.status",
    align: "left",
    field: (row: { productStatus: string }) =>
      row.productStatus ? row.productStatus : "",
    format: (val: string) => `${val}`,
    sortable: true,
  },
];
export const historyClassificationsCols = [
  {
    name: "name",
    label: "middleOffice.party.classifications.col.value",
    align: "left",
    field: (row: { partyClass: string }) =>
      row.partyClass
        ? i18n.global.t(`demand.party.partyClass.${row.partyClass}`)
        : "",
    format: (val: string) => `${val}`,
    sortable: true,
  },
  {
    name: "user",
    label: "middleOffice.party.classifications.col.user",
    align: "left",
    field: (row: { createdBy: string }) => (row.createdBy ? row.createdBy : ""),
    format: (val: string) => `${val}`,
    sortable: true,
  },
  {
    name: "validity",
    label: "middleOffice.party.classifications.col.validity",
    align: "left",
    field: (row: { validity_from: string; validity_until: string }) => {
      if (row.validity_from) {
        if (row.validity_until) {
          return (
            globalConfig.formatters.formatDate(new Date(row.validity_from)) +
            " - " +
            globalConfig.formatters.formatDate(new Date(row.validity_until))
          );
        } else {
          return (
            globalConfig.formatters.formatDate(new Date(row.validity_from)) +
            " - "
          );
        }
      } else {
        return "";
      }
    },
    format: (val: string) => `${val}`,
    sortable: true,
  },
  {
    name: "classificationDate",
    label: "middleOffice.party.classifications.col.classificationDate",
    align: "left",
    field: (row: { classification_date: string }) =>
      row.classification_date
        ? globalConfig.formatters.formatDate(new Date(row.classification_date))
        : "",
    format: (val: string) => `${val}`,
    sortable: true,
  },
];

export const assetCol = [
  {
    name: "vehicule",
    label: "main.dossier.asset.command.vehicle",
    align: "left",
    field: (row: { vehicule: string }) => (row.vehicule ? row.vehicule : ""),
    sortable: true,
  },
  {
    name: "price",
    label: "main.dossier.asset.command.price",
    align: "left",
    field: (row: { price: number }) => (row.price ? row.price : ""),
    sortable: true,
  },
  {
    name: "discount",
    label: "main.dossier.asset.command.discount",
    align: "left",
    field: (row: { discount: number }) => (row.discount ? row.discount : ""),
    sortable: true,
  },
  {
    name: "priceHT",
    label: "main.dossier.asset.command.priceHT",
    align: "left",
    field: (row: { priceHT: number }) => (row.priceHT ? row.priceHT : ""),
    sortable: true,
  },
  {
    name: "priceTTC",
    label: "main.dossier.asset.command.priceTTC",
    align: "left",
    field: (row: { priceTTC: number }) => (row.priceTTC ? row.priceTTC : ""),
    sortable: true,
  },
  {
    name: "refComm",
    label: "main.dossier.asset.command.reference",
    align: "left",
    field: (row: { refComm: number }) => (row.refComm ? row.refComm : ""),
    sortable: true,
  },
];
export const optionCol = [
  {
    name: "option",
    label: "main.dossier.asset.command.option",
    align: "left",
    field: (row: { option: string }) => (row.option ? row.option : ""),
    sortable: true,
    type: "string",
  },
  {
    name: "price",
    label: "main.dossier.asset.command.price",
    align: "left",
    field: (row: { price: number }) => (row.price ? row.price : ""),
    sortable: true,
    type: "number",
  },
  {
    name: "discount",
    label: "main.dossier.asset.command.discount",
    align: "left",
    field: (row: { discount: number }) => (row.discount ? row.discount : ""),
    sortable: true,
    type: "number",
  },
  {
    name: "priceHT",
    label: "main.dossier.asset.command.priceHT",
    align: "left",
    field: (row: { priceHT: number }) => (row.priceHT ? row.priceHT : ""),
    sortable: true,
    type: "number",
  },
  {
    name: "priceTTC",
    label: "main.dossier.asset.command.priceTTC",
    align: "left",
    field: (row: { priceTTC: number }) => (row.priceTTC ? row.priceTTC : ""),
    sortable: true,
    type: "number",
  },
  {
    name: "refComm",
    label: "main.dossier.asset.command.reference",
    align: "left",
    field: (row: { refComm: string }) => (row.refComm ? row.refComm : ""),
    sortable: true,
    type: "string",
  },
];
export const accessoryCol = [
  {
    name: "accessoire",
    label: "Accessoire",
    align: "left",
    field: (row: { accessoire: string }) =>
      row.accessoire ? row.accessoire : "",
    sortable: true,
  },
  {
    name: "price",
    label: "main.dossier.asset.command.price",
    align: "left",
    field: (row: { price: number }) => (row.price ? row.price : ""),
    sortable: true,
  },
  {
    name: "discount",
    label: "main.dossier.asset.command.discount",
    align: "left",
    field: (row: { discount: number }) => (row.discount ? row.discount : ""),
    sortable: true,
  },
  {
    name: "priceHT",
    label: "main.dossier.asset.command.priceHT",
    align: "left",
    field: (row: { priceHT: number }) => (row.priceHT ? row.priceHT : ""),
    sortable: true,
  },
  {
    name: "priceTTC",
    label: "main.dossier.asset.command.priceTTC",
    align: "left",
    field: (row: { priceTTC: number }) => (row.priceTTC ? row.priceTTC : ""),
    sortable: true,
  },
  {
    name: "refComm",
    label: "main.dossier.asset.command.reference",
    align: "left",
    field: (row: { refComm: number }) => (row.refComm ? row.refComm : ""),
    sortable: true,
  },
];
export const feesCol = [
  {
    name: "fees",
    label: "Frais divers",
    align: "left",
    field: (row: { fees: string }) => (row.fees ? row.fees : ""),
    sortable: true,
  },
  {
    name: "price",
    label: "main.dossier.asset.command.price",
    align: "left",
    field: (row: { price: number }) => (row.price ? row.price : ""),
    sortable: true,
  },
  {
    name: "discount",
    label: "main.dossier.asset.command.discount",
    align: "left",
    field: (row: { discount: number }) => (row.discount ? row.discount : ""),
    sortable: true,
  },
  {
    name: "priceHT",
    label: "main.dossier.asset.command.priceHT",
    align: "left",
    field: (row: { priceHT: number }) => (row.priceHT ? row.priceHT : ""),
    sortable: true,
  },
  {
    name: "priceTTC",
    label: "main.dossier.asset.command.priceTTC",
    align: "left",
    field: (row: { priceTTC: number }) => (row.priceTTC ? row.priceTTC : ""),
    sortable: true,
  },
  {
    name: "refComm",
    label: "main.dossier.asset.command.reference",
    align: "left",
    field: (row: { refComm: number }) => (row.refComm ? row.refComm : ""),
    sortable: true,
  },
];
export const articleCol = [
  {
    name: "article",
    label: "Article",
    align: "left",
    field: (row: { article: string }) => (row.article ? row.article : ""),
    sortable: true,
  },
  {
    name: "price",
    label: "main.dossier.asset.command.price",
    align: "left",
    field: (row: { price: number }) => (row.price ? row.price : ""),
    sortable: true,
  },
  {
    name: "discount",
    label: "main.dossier.asset.command.discount",
    align: "left",
    field: (row: { discount: number }) => (row.discount ? row.discount : ""),
    sortable: true,
  },
  {
    name: "priceHT",
    label: "main.dossier.asset.command.priceHT",
    align: "left",
    field: (row: { priceHT: number }) => (row.priceHT ? row.priceHT : ""),
    sortable: true,
  },
  {
    name: "priceTTC",
    label: "main.dossier.asset.command.priceTTC",
    align: "left",
    field: (row: { priceTTC: number }) => (row.priceTTC ? row.priceTTC : ""),
    sortable: true,
  },
  {
    name: "refComm",
    label: "main.dossier.asset.command.reference",
    align: "left",
    field: (row: { refComm: number }) => (row.refComm ? row.refComm : ""),
    sortable: true,
  },
];
export const ordersCols: Column[] = [
  {
    name: "activity",
    align: "left",
    label: "main.dossier.DossierCols.activity",
    field: (row: { activity: string }) =>
      row.activity === "true"
        ? i18n.global.t("asset.vehicleType.vn")
        : i18n.global.t("asset.vehicleType.vo"),
    sortable: true,
    type: "text",
  },
  {
    name: "referenceOrder",
    align: "left",
    label: "main.dossier.DossierCols.referenceOrder",
    field: (row: { referenceorder: string }) => row.referenceorder,
    sortable: true,
    type: "text",
  },
  {
    name: "clientreference",
    align: "left",
    label: "main.dossier.DossierCols.clientreference",
    field: (row: { clientreference: string }) => row.clientreference,
    sortable: true,
    type: "text",
  },
  {
    name: "associatedpartyname",
    align: "left",
    label: "main.dossier.DossierCols.associatedpartyname",
    field: (row: {
      associatedpartyname: string;
      associatedpartyfamilyname: string;
      associatedpartyfirstname: string;
    }) => {
      let associatedparty = "";
      if (row.associatedpartyfirstname) {
        associatedparty = associatedparty + row.associatedpartyfirstname + " ";
      }
      if (row.associatedpartyfamilyname) {
        associatedparty = associatedparty + row.associatedpartyfamilyname;
      }
      if (row.associatedpartyname) {
        associatedparty = row.associatedpartyname;
      }
      return associatedparty;
    },

    sortable: true,
    type: "text",
  },
  {
    name: "ordercreateddate",
    align: "left",
    label: "main.dossier.DossierCols.ordercreateddate",
    field: (row: any) =>
      moment(row.ordercreateddate).format("DD/MM/YYYY HH:mm"),
    sortable: true,
  },
  {
    name: "initialdeliverydate",
    align: "left",
    label: "main.dossier.DossierCols.initialdeliverydate",
    field: (row: any) =>
      row.initialdeliverydate
        ? moment(row.initialdeliverydate).format("DD/MM/YYYY")
        : "",
    //field: (row: { initialdeliverydate: string }) => row.initialdeliverydate,
    sortable: true,
  },
  {
    name: "brand",
    align: "left",
    label: "main.dossier.DossierCols.brand",
    field: (row: { brand: string }) =>
      row.brand ? row.brand.replace(/asset.good.vehicle.brand./g, "") : "",
    sortable: true,
    type: "text",
  },
  {
    name: "chassis_reference",
    align: "left",
    label: "main.dossier.DossierCols.chassis_reference",
    field: (row: { chassis_reference: string }) => row.chassis_reference,
    sortable: true,
    type: "number",
  },
  {
    name: "registrationnumber",
    align: "left",
    label: "main.dossier.DossierCols.registrationnumber",
    field: (row: { registrationnumber: string }) => row.registrationnumber,
    sortable: true,
    type: "text",
  },
  {
    name: "main.dossier.DossierCols.status_code",
    align: "left",
    label: "main.dossier.DossierCols.status_code",
    field: (row: { status_code: string }) =>
      row.status_code
        ? i18n.global.t(`main.dossier.orderStatus.${row.status_code}`)
        : "",
    sortable: true,
    type: "text",
  },
];

export const orderHistoryTableCols = {
  histories: [
    {
      name: "actionType",
      label: "task.historiesStep.headers.histories.actionType",
      align: "left",
      field: (row: { actionType: string }) =>
        `${i18n.global.t(
          "task.historiesStep.headers.histories.".concat(row.actionType)
        )}`,
      sortable: false,
    },
    {
      name: "userId",
      label: "task.historiesStep.headers.histories.userId",
      align: "left",
      field: (row: { userId: string }) => row.userId,
      sortable: false,
    },
    {
      name: "oldValue",
      label: "task.historiesStep.headers.histories.oldValue",
      align: "left",
      field: (row: { oldValue: string }) => getName(row.oldValue || ""),
      sortable: false,
    },
    {
      name: "newValue",
      label: "task.historiesStep.headers.histories.newValue",
      align: "left",
      field: (row: { newValue: string }) => getName(row.newValue || ""),
      sortable: false,
    },
    {
      name: "actionDate",
      label: "task.historiesStep.headers.histories.actionDate",
      align: "left",
      field: (row: { actionDate: string }) =>
        moment(row.actionDate).format("DD/MM/YYYY HH:mm"),
      sortable: false,
    },
  ],
};

export const DossierFilterCols: Column[] = [
  {
    name: "reference",
    align: "center",
    label: "Réf",
    field: (row: Dossier) => row.reference,
    sortable: true,
    type: "text",
  },
  {
    name: "cdtLoueur",
    label: "Cdt louer ",
    field: (row: Dossier) => row.cdtLoueur,
    sortable: true,
    type: "text",
  },
  {
    name: "client",
    label: "Client",
    field: (row: Dossier) =>
      row.client?.firstname
        ? row.client?.firstname + " " + row.client?.familyname
        : row.client?.name,
    sortable: true,
    type: "select",
    options: ["France", "DARCHE", "DARCHE"],
  },
  {
    name: "vendeur",
    label: "Vendeur",
    field: (row: Dossier) => row.vendeur?.name,
    sortable: true,
    type: "select",
    options: ["Supplier A", "Adolfo", "Supplier A"],
  },
  {
    name: "dateCommande",
    label: "Date Commande ",
    field: (row: Dossier) => row.dateCommande,
    sortable: true,
    type: "text",
  },
  {
    name: "dateLivraison",
    label: "Date De Livraison",
    field: (row: Dossier) => row.dateLivraison,
    sortable: true,
    type: "text",
  },
  {
    name: "dateRDVClient",
    label: "Date RDV client",
    field: (row: Dossier) => row.dateRDVClient,
    sortable: true,
    type: "text",
  },
  {
    name: "status",
    label: "Status",
    field: (row: Dossier) => row.status?.value,
    sortable: true,
    type: "select",
    options: [
      { label: "Regrouper Docs command", value: "Regrouper Docs command" },
      { label: "option1", value: "option1" },
      { label: "option2", value: "option2" },
    ],
  },
  {
    name: "adresseLivraison",
    align: "center",
    label: "deliveryAddress",
    field: (row: Dossier) => row.adresseLivraison,
    sortable: true,
    type: "text",
  },
  {
    name: "price",
    align: "center",
    label: "Price",
    field: (row: Dossier) => row.prix,
    sortable: true,
    type: "number",
  },
  {
    name: "discount",
    align: "center",
    label: "discount",
    field: (row: Dossier) => row.remise,
    sortable: true,
    type: "number",
  },
  {
    name: "priceHT",
    align: "center",
    label: "priceHT",
    field: (row: Dossier) => row.prixHT,
    sortable: true,
    type: "number",
  },
  {
    name: "priceTTC",
    align: "center",
    label: "priceTTC",
    field: (row: Dossier) => row.prixTTC,
    sortable: true,
    type: "number",
  },
];
export const searchSupplierAddress = [
  {
    name: "street_num",
    label: "middleOffice.party.organization.address.streetNum",
    align: "left",
    field: (row: { street_num: string }) =>
      row.street_num ? row.street_num : "",
    sortable: true,
  },
  {
    name: "street",
    label: "middleOffice.party.person.address.street",
    align: "left",
    field: (row: { street: number }) => (row.street ? row.street : ""),
    sortable: true,
  },
  {
    name: "postal_code",
    label: "middleOffice.party.person.address.postalCode",
    align: "left",
    field: (row: { postal_code: number }) =>
      row.postal_code ? row.postal_code : "",
    sortable: true,
  },
  {
    name: "city",
    label: "middleOffice.party.person.address.city",
    align: "left",
    field: (row: { city: number }) => (row.city ? row.city : ""),
    sortable: true,
  },
  {
    name: "country_code",
    label: "middleOffice.party.person.address.country",
    align: "left",
    field: (row: { country_code: number }) =>
      row.country_code ? row.country_code : "",
    sortable: true,
  },
];

export const orderItemCols = [
  {
    name: "orderNumber",
    label: `order.item.cols.orderNumber`,
    align: "left",
    field: (row: any) => row.reference,
    format: (val: string) => `${val}`,
    sortable: true,
  },
  {
    name: "type",
    align: "left",
    label: `order.item.cols.type`,
    field: (row: any) =>
      row.type.resourceUid
        ? i18n.global.t("order.item.cols.".concat(row.type.resourceUid))
        : "",
    format: (val: string) => `${val}`,
    sortable: true,
  },
  {
    name: "asset",
    align: "left",
    label: `order.item.cols.asset`,
    field: (row: any) => {
      let result = "";
      if (
        row.orderAssets &&
        row.orderAssets.length &&
        row.orderAssets[0].description
      ) {
        result = row.orderAssets[0].description;
      }
      return result;
    },
    format: (val: string) => `${val}`,
    sortable: true,
  },
  {
    name: "orderCreationDate",
    align: "left",
    label: `order.item.cols.orderCreationDate`,
    field: (row: any) => row.effectiveDeliveryDate || "",
    format: (val: string) => `${val}`,
    sortable: true,
  },
  {
    name: "provisionalDeliveryDate",
    align: "left",
    label: `order.item.cols.provisionalDeliveryDate`,
    field: (row: any) => row.previsionDeliveryDate || "",
    format: (val: string) => `${val}`,
    sortable: true,
  },
  {
    name: "supplier",
    align: "left",
    label: `order.item.cols.supplier`,
    field: (row: any) => {
      let result = "";
      if (
        row.orderAssets &&
        row.orderAssets.length &&
        row.orderAssets[0].config?.supplierPartyName
      ) {
        result = row.orderAssets[0].config.supplierPartyName;
      }
      return result;
    },
    format: (val: string) => `${val}`,
    sortable: true,
  },
  {
    name: "deliveryAddress",
    align: "left",
    label: `order.item.cols.deliveryAddress`,
    field: (row: any) => {
      let result = "";
      if (
        row.orderAssets &&
        row.orderAssets.length &&
        row.orderAssets[0].assetDelivery?.description
      ) {
        result = row.orderAssets[0].assetDelivery.description;
      }
      return result;
    },
    format: (val: string) => `${val}`,
    sortable: true,
  },
  {
    name: "totalAmount",
    align: "left",
    label: `order.item.cols.totalAmount`,
    field: (row: any) => {
      let result = "";
      if (
        row.orderAssets &&
        row.orderAssets.length &&
        row.orderAssets[0].totalAmountWTax?.amount
      ) {
        result = formatAmoutswitchCurrency(
          "EUR",
          row.orderAssets[0].totalAmountWTax.amount
        );
      }
      return result;
    },
    format: (val: string) => `${val}`,
    sortable: true,
  },
  {
    name: "status",
    label: `order.item.cols.status`,
    align: "left",
    field: (row: any) =>
      row.status?.resourceUid
        ? i18n.global.t(`order.picklist.item.status.${row.status.resourceUid}`)
        : "",
    format: (val: string) => `${val}`,
    sortable: true,
  },
  {
    name: "action",
    label: "",
    align: "center",
    sortable: false,
  },
];
export const orderPreconditionTabCols = [
  {
    name: "status",
    label: "order.item.cols.status",
    align: "left",
    field: (row: any) => {
      let result;
      if (row.objectType.includes("documentvalue")) {
        result = row.document
          ? i18n.global.t(
              "task.uploadDocument.headers.supportingDocumentStatus.".concat(
                row.document.status
              )
            )
          : "";
      } else {
        result =
          row.status && row.status.resourceUid
            ? i18n.global.t(
                "order.preconditions.fieldvalue.status.".concat(
                  row.status.resourceUid
                )
              )
            : "-";
      }
      return result;
    },
    format: (val: string) => `${val}`,
    sortable: true,
  },
  {
    name: "type",
    align: "left",
    label: "order.item.cols.type",
    field: (row: any) =>
      row.objectType
        ? row.objectType.substring(row.objectType.lastIndexOf(".") + 1)
        : "",
    format: (val: string) => `${val}`,
    sortable: true,
  },
  {
    name: "label",
    align: "left",
    label: "order.item.cols.label",
    field: (row: any) => {
      let result;
      if (row.objectType.includes("documentvalue")) {
        result = row.document
          ? i18n.global.t(
              "task.supportingDocumentLabel.".concat(row.document.documentType)
            )
          : "";
      } else {
        result = row.i18nLabel
          ? i18n.global.t(
              "order.preconditions.fieldvalue.label." + row.i18nLabel
            )
          : "";
      }
      return result;
    },
    format: (val: string) => `${val}`,
    sortable: true,
  },
  {
    name: "creationDate",
    align: "left",
    label: "order.item.cols.creationDate",
    field: (row: any) => {
      let result = "-";
      if (row.objectType.includes("documentvalue")) {
        if (row.document.creationDate) {
          result = moment(row.document.creationDate).format("DD/MM/YYYY HH:mm");
        }
      } else {
        if (row.creationDate) {
          result = moment(row.creationDate).format("DD/MM/YYYY HH:mm");
        }
      }
      return result;
    },
    format: (val: string) => `${val}`,
    sortable: true,
  },
  {
    name: "completionDate",
    align: "left",
    label: "order.item.cols.completionDate",
    field: (row: any) =>
      row.status.resourceUid === "COMPLETED"
        ? row.objectType.includes("documentvalue")
          ? moment(row.document.creationDate).format("DD/MM/YYYY HH:mm")
          : moment(row.completionDate, "DD/MM/YYYY").format("DD/MM/YYYY HH:mm")
        : "-",
    format: (val: string) => `${val}`,
    sortable: true,
  },
  {
    name: "modificationUser",
    align: "left",
    label: "order.item.cols.modificationUser",
    field: (row: any) => {
      let result = "-";
      if (row.whoModified) {
        result = row.whoModified;
      }
      return result;
    },
    format: (val: string) => `${val}`,
    sortable: true,
  },
  {
    name: "action",
    label: "",
    align: "center",
    sortable: false,
  },
];

export const statusHistoryCols = [
  {
    name: "label",
    label: "demand.product.services.columns.name",
    align: "left",
    field: (row: any) => row.label,
    format: (val: string) => `${val}`,
    sortable: true,
  },
  {
    name: "value",
    align: "left",
    label: "middleOffice.party.classifications.col.value",
    field: (row: any) => row.value,
    format: (val: string) => `${val}`,
    sortable: true,
  },
];

export const preConditionDetailcols = [
  {
    name: "value",
    label: "order.preconditions.label.value",
    align: "left",
    field: (row: any) => row.fieldValue,
    format: (val: string) => `${val}`,
    sortable: true,
  },
  {
    name: "checkComment",
    align: "left",
    label: "order.preconditions.documents.columns.commentary",
    field: (row: any) => row.checkComment,
    format: (val: string) => `${val}`,
    sortable: true,
  },
];

export const FinancialexposureCols = [
  {
    name: "outstandingtypecode",
    label: "middleOffice.party.organization.exposure.Outstanding_type",
    align: "left",
    field: "outstandingType",
  },
  {
    name: "netoutstandingamount",
    label: "middleOffice.party.organization.exposure.total_net_outstanding",
    align: "right",
    field: "netOutstanding",
  },
  {
    name: "grossoutstandingamount",
    label: "middleOffice.party.organization.exposure.total_gross_outstanding",
    align: "right",
    field: "grossOutstanding",
  },
  {
    name: "residualvalueamount",
    label: "middleOffice.party.organization.exposure.residual_value",
    align: "right",
    field: "residualValue",
  },
];

export const variablesWorkflowOrder =
  "module;orderId;orderReference;orderCreationDate;orderStatus;";

export const usersCols = [
  {
    name: "username",
    label: "users.filter.username",
    align: "left",
    field: (row: { username: string }) => (row.username ? row.username : ""),
    sortable: true,
  },
  {
    name: "firstName",
    label: "users.filter.firstName",
    align: "left",
    field: (row: { first_name: string }) =>
      row.first_name ? row.first_name : "",
    sortable: true,
  },
  {
    name: "lastName",
    label: "users.filter.lastName",
    align: "left",
    field: (row: { last_name: string }) => (row.last_name ? row.last_name : ""),
    sortable: true,
  },
  {
    name: "email",
    label: "users.filter.email",
    align: "left",
    field: (row: { email: string }) => (row.email ? row.email : ""),
    sortable: true,
  },
  {
    name: "status",
    label: "users.filter.status",
    align: "left",
    field: (row: { enabled: string }) => (row.enabled ? row.enabled : false),
    sortable: true,
  },
  {
    name: "notExpired",
    label: "users.filter.notExpired",
    align: "left",
    field: (row: { account_not_expired: string }) =>
      row.account_not_expired ? row.account_not_expired : false,
    sortable: true,
  },
  {
    name: "credintialsNotExpired",
    label: "users.filter.credintialsNotExpired",
    align: "left",
    field: (row: { credentials_not_expired: string }) =>
      row.credentials_not_expired ? row.credentials_not_expired : false,
    sortable: true,
  },
  {
    name: "notLocked",
    label: "users.filter.notLocked",
    align: "center",
    field: (row: { account_not_locked: string }) =>
      row.account_not_locked ? row.account_not_locked : "",
    sortable: true,
  },
];

export const ratingHistoryCols = [
  {
    name: "calculatedValue",
    label: "task.study.calculatedValue",
    align: "left",
    field: (row: { calculatedValue: string }) =>
      row.calculatedValue ? row.calculatedValue : "",
  },
  {
    name: "updatedValue",
    label: "task.study.updatedValue",
    align: "left",
    field: (row: { updatedValue: number }) =>
      row.updatedValue ? row.updatedValue : "",
  },
  {
    name: "ValidityFrom",
    label: "availabilities.filter.validityFrom",
    align: "left",
    field: (row: { validityFrom: number }) =>
      row.validityFrom ? row.validityFrom : "",
  },
  {
    name: "ValidityUntil",
    label: "availabilities.filter.validityTo",
    align: "left",
    field: (row: { validityFrom: number }) =>
      row.validityFrom ? row.validityFrom : "",
  },
  {
    name: "whoModified",
    label: "task.study.whoModified",
    align: "left",
    field: (row: { whoModified: number }) =>
      row.whoModified ? row.whoModified : "",
  },
  {
    name: "whenModified",
    label: "task.study.whenModified",
    align: "left",
    field: (row: { whenCreated: number }) =>
      row.whenCreated ? row.whenCreated : "",
  },
];
export const userLogsCols = [
  {
    name: "actionDate",
    label: "users.globalesLabels.actionDate",
    align: "left",
    field: (row: { date: string }) => (row.date ? row.date : ""),
    sortable: true,
  },
  {
    name: "actionType",
    label: "users.globalesLabels.actionType",
    align: "left",
    field: (row: { action: string }) => (row.action ? row.action : ""),
    sortable: true,
  },
];

// TODO EF-262 - finish
export const orderCommissionCols = [
  {
    name: "beneficiary",
    label: "order.commission.orderCommissionCols.beneficiary",
    align: "left",
    field: (row: { role: any }) =>
      row.role ? i18n.global.t(`demand.party.roles.${row.role.id}`) : "",
    sortable: true,
  },
  {
    name: "number",
    label: "order.commission.orderCommissionCols.number",
    align: "left",
    field: (row: { number: string }) => (row.number ? row.number : ""),
    sortable: true,
  },
  {
    name: "type",
    label: "order.commission.orderCommissionCols.type",
    align: "left",
    field: (row: any) =>
      row.addCommissionMock
        ? "Prime"
        : row.commissionType
        ? i18n.global.t(`order.commision.commissionType.${row.commissionType}`)
        : "",
    sortable: true,
  },
  {
    name: "description",
    label: "order.commission.orderCommissionCols.description",
    align: "left",
    field: (row: any) =>
      row.addCommissionMock
        ? row.accessoryProduct.resourceUid
        : row.accessoryProduct && row.accessoryProduct.resourceUid
        ? i18n.global.t(
            `order.commision.description.${row.accessoryProduct.resourceUid}`
          )
        : "",
    sortable: true,
  },
  {
    name: "calculationType",
    label: "order.commission.orderCommissionCols.calculationType",
    align: "left",
    field: (row: { orderAccessoryCalculationMethodOption: any }) =>
      row.orderAccessoryCalculationMethodOption &&
      row.orderAccessoryCalculationMethodOption.resourceUid
        ? i18n.global.t(
            `order.commision.calculeType.${row.orderAccessoryCalculationMethodOption.resourceUid}`
          )
        : "",
    sortable: true,
  },
  {
    name: "rateType",
    label: "order.commission.orderCommissionCols.rateType",
    align: "left",
    field: (row: { basis: string }) =>
      row.basis ? i18n.global.t(`order.commision.basis.${row.basis}`) : "",
    sortable: true,
  },
  {
    name: "amount",
    label: "order.commission.orderCommissionCols.amount",
    align: "left",
    field: (row: any) =>
      row.addCommissionMock ? 0 + " %" : row.rate ? row.rate + " %" : "",
    sortable: true,
  },
  {
    name: "result",
    label: "order.commission.orderCommissionCols.result",
    align: "left",
    field: (row: { calculatedAmountWTax: any }) =>
      row.calculatedAmountWTax && row.calculatedAmountWTax.amount
        ? formatAmoutswitchCurrency("EUR", row.calculatedAmountWTax.amount)
        : "",
    sortable: true,
  },
  {
    name: "revision",
    label: "order.commission.orderCommissionCols.revision",
    align: "left",
    field: (row: any) =>
      row.addCommissionMock
        ? ""
        : row.adjustAmount && row.adjustAmount.amount
        ? formatAmoutswitchCurrency("EUR", row.adjustAmount.amount)
        : formatAmoutswitchCurrency("EUR", 0),
    sortable: true,
  },
  {
    name: "commission",
    label: "order.commission.orderCommissionCols.commission",
    align: "left",
    field: (row: any) =>
      row.adjustAmount
        ? formatAmoutswitchCurrency(
            "EUR",
            Number(
              typeof row.adjustAmount === "string"
                ? row.adjustAmount
                : row.adjustAmount.amount
            ) + Number(row.calculatedAmountWTax.amount)
          )
        : formatAmoutswitchCurrency("EUR", row.calculatedAmountWTax.amount),
    sortable: true,
  },
];

export const orderCommissionSubventionCols = [
  {
    name: "subvention",
    label: "order.commission.subvention",
    align: "left",
    field: (row: { subvention: string }) =>
      row.subvention ? row.subvention : "",
    sortable: true,
  },
  {
    name: "type",
    label: "order.commission.orderCommissionCols.type",
    align: "left",
    field: (row: { subventionType: string }) =>
      row.subventionType ? row.subventionType : "",
    sortable: true,
  },
  {
    name: "rate",
    align: "left",
    label: "demand.sliders.annualRate",
    field: (row: { rate: string }) => (row.rate ? row.rate + " %" : ""),
    sortable: true,
  },
  {
    name: "montantHt",
    label: "middleOfficeFinancingService.label.withOutTax",
    align: "center",
    field: (row: { montantHt: string }) => row.montantHt + " €",
    sortable: true,
  },
  {
    name: "montantTtc",
    label: "middleOfficeFinancingService.label.withTax",
    align: "center",
    headerStyle: "max-width:10px",
    field: (row: { montantTtc: string }) => row.montantTtc + " €",
    sortable: true,
  },
];

export const orderMargeCols = [
  {
    name: "margeType",
    label: "",
    align: "left",
    field: (row: { margeType: string }) =>
      row.margeType
        ? i18n.global.t(`order.commission.margeType.${row.margeType}`)
        : "",
    sortable: true,
  },
  {
    name: "taux",
    label: "Taux",
    align: "left",
    field: (row: { taux: any }) => (row.taux ? row.taux + " %" : ""),
    sortable: true,
  },
  {
    name: "montant",
    label: "Montant",
    align: "left",
    field: (row: { montant: string }) => row.montant + " €",
    sortable: true,
  },
  {
    name: "signe",
    label: "Signe",
    align: "left",
    field: (row: { signe: string }) => (row.signe ? row.signe : ""),

    sortable: true,
  },
];

export const orderSubventionCols = [
  {
    name: "type",
    label: "",
    align: "left",
    field: (row: { type: string }) => (row.type ? row.type : ""),
    sortable: true,
  },
  {
    name: "taux",
    label: "",
    align: "left",
    field: (row: { taux: any }) => (row.taux ? row.taux + " %" : ""),
    sortable: true,
  },
  {
    name: "montantHt",
    label: "HT affaire",
    align: "left",
    field: (row: { montantHt: string }) => row.montantHt + " €",
    sortable: true,
  },
  {
    name: "montantTtc",
    label: "TTC Client",
    align: "left",
    field: (row: { montantTtc: string }) => row.montantTtc + " €",
    sortable: true,
  },
  {
    name: "signe",
    label: "Signe",
    align: "left",
    field: (row: { signe: string }) => (row.signe ? row.signe : ""),

    sortable: true,
  },
];

export const orderAssetServiceCols = [
  {
    name: "serviceType",
    align: "left",
    label: "order.asset.service.cols.serviceType",
    field: (row: any) => {
      return row.type && row.type.resourceUid ? row.type.resourceUid : "";
    },
    format: (val: string) => `${val}`,
    sortable: true,
  },
  {
    name: "label",
    label: "order.asset.service.cols.label",
    align: "left",
    field: (row: any) => (row.label ? row.label : ""),
    format: (val: string) => `${val}`,
    sortable: true,
  },
  {
    name: "scaleCode",
    label: "order.asset.service.cols.scaleCode",
    align: "left",
    field: (row: any) => "",
    format: (val: string) => `${val}`,
    sortable: true,
  },
  {
    name: "scaleLabel",
    label: "order.asset.service.cols.scaleLabel",
    align: "left",
    field: (row: any) => "",
    format: (val: string) => `${val}`,
    sortable: true,
  },
  {
    name: "duration",
    label: "order.asset.service.cols.duration",
    align: "left",
    field: (row: any) => "",
    format: (val: string) => `${val}`,
    sortable: true,
  },
  {
    name: "amountIncl",
    label: "order.asset.service.cols.amountIncl",
    align: "left",
    field: (row: any) => "",
    format: (val: string) => `${val}`,
    sortable: true,
  },
  {
    name: "amountExcl",
    label: "order.asset.service.cols.amountExcl",
    align: "left",
    field: (row: any) => "",
    format: (val: string) => `${val}`,
    sortable: true,
  },
  {
    name: "purchase",
    label: "order.asset.service.cols.purchase",
    align: "left",
    field: (row: any) => "",
    format: (val: string) => `${val}`,
    sortable: true,
  },
  {
    name: "mileage",
    label: "order.asset.service.cols.mileage",
    align: "left",
    field: (row: any) => "",
    format: (val: string) => `${val}`,
    sortable: true,
  },
  {
    name: "activatedContract",
    label: "order.asset.service.cols.activatedContract",
    align: "left",
    field: (row: any) => "",
    format: (val: string) => `${val}`,
    sortable: true,
  },
  {
    name: "amountCommission",
    label: "order.asset.service.cols.amountCommission",
    align: "left",
    field: (row: any) => "",
    format: (val: string) => `${val}`,
    sortable: true,
  },
];
export const searchAssetOrderCols = [
  {
    name: "brand",
    label: "order.asset.inventoryCols.brand",
    align: "left",
    field: (row: { brand: string }) =>
      row.brand ? row.brand.replace(/asset.good.vehicle.brand./g, "") : "",
    sortable: true,
  },
  {
    name: "model",
    label: "order.asset.inventoryCols.model",
    align: "left",
    field: (row: { model: string }) =>
      row.model
        ? row.model.substring(
            row.model.lastIndexOf("range") + 6,
            row.model.length + 1
          )
        : "",
    sortable: true,
  },
  {
    name: "product_name",
    label: "order.asset.inventoryCols.description",
    align: "center",
    field: (row: { product_name: string }) =>
      row.product_name ? row.product_name : "",
    sortable: true,
  },
  {
    name: "price_amount",
    label: "order.asset.inventoryCols.price",
    align: "center",
    field: (row: { price_amount: string }) =>
      row.price_amount ? row.price_amount : "",
    sortable: true,
  },
  {
    name: "current_mileage",
    label: "order.asset.inventoryCols.currentMileage",
    align: "center",
    field: (row: { current_mileage: string }) =>
      row.current_mileage ? row.current_mileage : "",
    sortable: true,
  },
  {
    name: "color",
    label: "order.asset.inventoryCols.color",
    align: "center",
    field: (row: { color: string }) => (row.color ? row.color : ""),
    sortable: true,
  },
  {
    name: "status_code",
    label: "order.asset.inventoryCols.status",
    align: "center",
    field: (row: { status_code: string }) =>
      row.status_code
        ? i18n.global.t(
            `order.asset.inventoryCols.statusField.${row.status_code}`
          )
        : "",
    sortable: true,
  },
  {
    name: "type_code",
    label: "order.asset.inventoryCols.activity",
    align: "center",
    field: (row: { type_code: string }) =>
      row.type_code
        ? i18n.global.t(
            `order.asset.inventoryCols.activityField.${row.type_code}`
          )
        : "",
    sortable: true,
  },
];
export const orderAssetDeliveryCols = [
  {
    name: "process",
    align: "left",
    label: "order.delivery.cols.process",
    field: (row: any) =>
      row.process && row.process.resourceUid ? row.process.resourceUid : "",
    format: (val: string) => `${val}`,
    sortable: true,
  },
  {
    name: "initialDate",
    label: "order.delivery.cols.initialDate",
    align: "left",
    field: (row: any) =>
      row.initialDeliveryDate ? row.initialDeliveryDate : "",
    format: (val: string) => `${val}`,
    sortable: true,
  },
  {
    name: "previsionDate",
    label: "order.delivery.cols.previsionDate",
    align: "left",
    field: (row: any) =>
      row.previsionDeliveryDate ? row.previsionDeliveryDate : "",
    format: (val: string) => `${val}`,
    sortable: true,
  },
  {
    name: "finalDate",
    label: "order.delivery.cols.finalDate",
    align: "left",
    field: (row: any) =>
      row.effectiveDeliveryDate ? row.effectiveDeliveryDate : "",
    format: (val: string) => `${val}`,
    sortable: true,
  },
  {
    name: "deliveryAddress",
    label: "order.delivery.cols.deliveryAddress",
    align: "left",
    field: (row: any) => (row.description ? row.description : ""),
    format: (val: string) => `${val}`,
    sortable: true,
  },
  {
    name: "deliveryMethod",
    label: "order.delivery.cols.deliveryMethod",
    align: "left",
    field: (row: any) => "",
    format: (val: string) => `${val}`,
    sortable: true,
  },
  {
    name: "deliveryContact",
    label: "order.delivery.cols.deliveryContact",
    align: "left",
    field: (row: any) => (row.phoneContact ? row.phoneContact : ""),
    format: (val: string) => `${val}`,
    sortable: true,
  },
];
export const orderAlertCols = [
  {
    name: "createdBy",
    label: "user.createdBy",
    align: "left",
    field: (row: { whoCreated: string }) =>
      row.whoCreated ? row.whoCreated : "",
    format: (val: string) => `${val}`,
    sortable: true,
  },
  {
    name: "creationDate",
    label: "user.creationDate",
    align: "left",
    field: (row: { whenCreated: string }) =>
      row.whenCreated ? row.whenCreated : "",
    format: (val: string) => `${val}`,
    sortable: true,
  },
  {
    name: "comment",
    label: "Analysis.Comment",
    align: "left",
    field: (row: { comment: string }) => (row.comment ? row.comment : ""),
    format: (val: string) => `${val}`,
    sortable: true,
  },
  {
    name: "reasons",
    label: "reasons",
    align: "left",
    field: (row: { reasons: string }) => (row.reasons ? row.reasons : ""),
    format: (val: string) => `${val}`,
    sortable: true,
  },
];

export const listOfColors = [
  "red",
  "red-3",
  "red-4",
  "red-5",
  "red-6",
  "red-7",
  "red-8",
  "red-9",
  "red-10",
  "red-11",
  "red-12",
  "red-13",
  "red-14",
  "pink",
  "pink-3",
  "pink-4",
  "pink-5",
  "pink-6",
  "pink-7",
  "pink-8",
  "pink-9",
  "pink-10",
  "pink-11",
  "pink-12",
  "pink-13",
  "pink-14",
  "purple",
  "purple-3",
  "purple-4",
  "purple-5",
  "purple-6",
  "purple-7",
  "purple-8",
  "purple-9",
  "purple-10",
  "purple-11",
  "purple-12",
  "purple-13",
  "purple-14",
  "deep-purple",
  "deep-purple-3",
  "deep-purple-4",
  "deep-purple-5",
  "deep-purple-6",
  "deep-purple-7",
  "deep-purple-8",
  "deep-purple-9",
  "deep-purple-10",
  "deep-purple-11",
  "deep-purple-12",
  "deep-purple-13",
  "deep-purple-14",
  "indigo",
  "indigo-3",
  "indigo-4",
  "indigo-5",
  "indigo-6",
  "indigo-7",
  "indigo-8",
  "indigo-9",
  "indigo-10",
  "indigo-11",
  "indigo-12",
  "indigo-13",
  "indigo-14",
  "blue",
  "blue-3",
  "blue-4",
  "blue-5",
  "blue-6",
  "blue-7",
  "blue-8",
  "blue-9",
  "blue-10",
  "blue-11",
  "blue-12",
  "blue-13",
  "blue-14",
  "light-blue",
  "light-blue-3",
  "light-blue-4",
  "light-blue-5",
  "light-blue-6",
  "light-blue-7",
  "light-blue-8",
  "light-blue-9",
  "light-blue-10",
  "light-blue-11",
  "light-blue-12",
  "light-blue-13",
  "light-blue-14",
  "cyan",
  "cyan-6",
  "cyan-7",
  "cyan-8",
  "cyan-9",
  "cyan-10",
  "cyan-11",
  "cyan-12",
  "cyan-13",
  "cyan-14",
  "teal",
  "teal-3",
  "teal-4",
  "teal-5",
  "teal-6",
  "teal-7",
  "teal-8",
  "teal-9",
  "teal-10",
  "teal-11",
  "teal-12",
  "teal-13",
  "teal-14",
  "green",
  "green-3",
  "green-4",
  "green-5",
  "green-6",
  "green-7",
  "green-8",
  "green-9",
  "green-10",
  "green-11",
  "green-12",
  "green-13",
  "green-14",
  "light-green",
  "light-green-7",
  "light-green-8",
  "light-green-9",
  "light-green-10",
  "light-green-11",
  "light-green-12",
  "light-green-13",
  "light-green-14",
  "lime",
  "lime-8",
  "lime-9",
  "lime-10",
  "lime-11",
  "lime-12",
  "lime-13",
  "lime-14",
  "yellow",
  "yellow-6",
  "yellow-7",
  "yellow-8",
  "yellow-9",
  "yellow-10",
  "yellow-11",
  "yellow-12",
  "yellow-13",
  "yellow-14",
  "amber",
  "amber-6",
  "amber-7",
  "amber-8",
  "amber-9",
  "amber-10",
  "amber-11",
  "amber-12",
  "amber-13",
  "amber-14",
  "orange",
  "orange-3",
  "orange-4",
  "orange-5",
  "orange-6",
  "orange-7",
  "orange-8",
  "orange-9",
  "orange-10",
  "orange-11",
  "orange-12",
  "orange-13",
  "orange-14",
  "deep-orange",
  "deep-orange-3",
  "deep-orange-4",
  "deep-orange-5",
  "deep-orange-6",
  "deep-orange-7",
  "deep-orange-8",
  "deep-orange-9",
  "deep-orange-10",
  "deep-orange-11",
  "deep-orange-12",
  "deep-orange-13",
  "deep-orange-14",
  "brown",
  "brown-3",
  "brown-4",
  "brown-5",
  "brown-6",
  "brown-7",
  "brown-8",
  "brown-9",
  "brown-10",
  "brown-11",
  "brown-12",
  "brown-13",
  "brown-14",
  "grey",
  "grey-3",
  "grey-4",
  "grey-5",
  "grey-6",
  "grey-7",
  "grey-8",
  "grey-9",
  "grey-10",
  "grey-11",
  "grey-12",
  "grey-13",
  "grey-14",
  "blue-grey",
  "blue-grey-3",
  "blue-grey-4",
  "blue-grey-5",
  "blue-grey-6",
  "blue-grey-7",
  "blue-grey-8",
  "blue-grey-9",
  "blue-grey-10",
  "blue-grey-11",
  "blue-grey-12",
  "blue-grey-13",
  "blue-grey-14",
];

export const roleMenuCols = [
  {
    name: "id",
    label: "Name",
    align: "left",
    field: (row: { id: string }) =>
      i18n.global.t(`main.menu.${row.id}`) ?? row.id,
    sortable: true,
  },
  {
    name: "creationDate",
    label: "Creation Date",
    align: "left",
    field: (row: { creationdate: string }) =>
      row.creationdate
        ? formatDateLocale(row.creationdate, "YYYY-MM-DD", "fr")
        : "",
    sortable: true,
  },
  {
    name: "createdby",
    label: "Created By",
    align: "left",
    field: (row: { createdby: string }) => row.createdby ?? "",
    sortable: true,
  },
  {
    name: "modificationDate",
    label: "Modification Date",
    align: "left",
    field: (row: { modificationDate: string }) =>
      row.modificationDate
        ? formatDateLocale(row.modificationDate, "YYYY-MM-DD", "fr")
        : "",
    sortable: true,
  },
  {
    name: "modifiedBy",
    label: "Modified By",
    align: "left",
    field: (row: { modifiedBy: string }) => row.modifiedBy ?? "",
    sortable: true,
  },
];

export const dashboardMenuCols = [
  {
    name: "id",
    label: "ID dashboard",
    align: "left",
    field: (row: { externaldashboard: string }) =>
      row.externaldashboard ? row.externaldashboard : "",
    sortable: true,
  },
  {
    name: "name",
    label: "Name",
    align: "left",
    field: (row: { name: string }) => (row.name ? row.name : ""),
    sortable: true,
  },
  {
    name: "creationDate",
    label: "Creation Date",
    align: "left",
    field: (row: { creationdate: string }) =>
      row.creationdate
        ? formatDateLocale(row.creationdate, "YYYY-MM-DD", "fr")
        : "",
    sortable: true,
  },
  {
    name: "createdBy",
    label: "Created By",
    align: "left",
    field: (row: { createdby: string }) => row.createdby ?? "",
    sortable: true,
  },
  {
    name: "modificationDate",
    label: "Modification Date",
    align: "left",
    field: (row: { modificationdate: string }) =>
      row.modificationdate
        ? formatDateLocale(row.modificationdate, "YYYY-MM-DD", "fr")
        : "",
    sortable: true,
  },
  {
    name: "modifiedBy",
    label: "Modified By",
    align: "left",
    field: (row: { modifiedby: string }) => row.modifiedby ?? "",
    sortable: true,
  },
];
