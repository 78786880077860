
import { Options, Vue } from "vue-class-component";
import store from "@/store";
import { removeExpandedItem, sortItemsByLabel } from "@/utils";
import { ComboBoxItem, PartyClassification } from "@/types";
import { formatDate, formatDateLocale, formatDateServiceAddDays, formatDateToMoment } from "@/utils/configuration/formatters-config";

@Options({
  computed: {
    isClassificationBtnDisabled: function () {
      return !this.classificationBtn;
    },
  },
  watch: {
    selectedClassifications: {
      handler(newVal: PartyClassification[]) {
        if (newVal && newVal.length > 0) {
          newVal.forEach((classification, index) => {
            if (!classification.validity) {
              classification.validity = { from: '', until: '' };
            }
            if (!classification.partyClass?.type?.value) {
              if (!classification.partyClass) {
                classification.partyClass = { type: { value: '' } };
              } else if (!classification.partyClass.type) {
                classification.partyClass.type = { value: '' };
              } else {
                classification.partyClass.type.value = '';
              }
            }

            if (classification.classType) {
              this.loadOptionsForClassType(classification.classType, index);
            }
          });
        }
      },
      deep: true,
      immediate: true
    },
  },
  props: ["selectedClassifications"],
})
export default class TabContact extends Vue {
  classificationBtn = false;
  dateMask = "DD/MM/YYYY";
  removeExpandedItem = removeExpandedItem;
  startDate!: string;
  partyClassificationItems: ComboBoxItem[][] = [];
  selectedClassifications?: PartyClassification[];

  // Initialize the party classification items array with empty arrays
  beforeMount() {
    if (this.selectedClassifications && this.selectedClassifications.length > 0) {
      // Initialize the array with empty arrays up to the length of selectedClassifications
      this.partyClassificationItems = Array(this.selectedClassifications.length).fill([]);

      this.selectedClassifications.forEach((classification: any, index: number) => {
        if (!classification.validity) {
          classification.validity = {
            from: '',
            until: ''
          };
        }

        // Load options for each class type
        if (classification.classType) {
          this.loadOptionsForClassType(classification.classType, index);
        }
      });
    }
  }

  mounted() {
    if (this.selectedClassifications && this.selectedClassifications.length > 0) {
      this.selectedClassifications.forEach((classification: any, index: number) => {
        if (classification.classType) {
          this.loadOptionsForClassType(classification.classType, index);
        }
      });
    }
  }

  // Extract the loading of options into a reusable method
  loadOptionsForClassType(classType: string, index: number) {
    store.dispatch("thirdModule/getPartyClass", {
      callback: (res: any) => this.updateModel(res, index),
      filter: {
        class_type_code: classType
      },
    });
  }

  get partyClassificationTypeItems() {
    return store.getters["picklistModule/partyClassificationTypeItems"];
  }

  get partyClassificationInit() {
    return store.getters["thirdModule/getPartyClassificationInit"];
  }

  edit(value: { isDisabled: boolean }) {
    value.isDisabled = !value.isDisabled;
  }

  addExpandClassification(items: PartyClassification[] = []) {
    if (items && items.length === 0) {
      const element = store.getters["thirdModule/getPartyClassificationInit"];
      this.selectedClassifications?.push(element);
    }
  }

  add<T>(items: T[]) {
    const getter = store.getters["thirdModule/getPartyClassificationInit"];
    items.push(getter);

    // When adding a new item, we need to add an empty array to partyClassificationItems
    if (Array.isArray(this.partyClassificationItems)) {
      this.partyClassificationItems.push([]);
    }
  }

  remove<T>(index: number, items: T[]) {
    if (items && items.length > 0) {
      items.splice(index, 1);
    }

    // Shift all items in partyClassificationItems
    if (Array.isArray(this.partyClassificationItems)) {
      this.partyClassificationItems.splice(index, 1);
    }
  }

  formatDateUntil(value: any) {
    if (value.validity?.until) {
      value.validity!.until = value.validity?.until.includes("/")
        ? formatDateLocale(value.validity?.until, "DD/MM/YYYY", "fr", "DD/MM/YYYY")
        : formatDateLocale(value.validity?.until, "DDMMYYYY", "fr", "DD/MM/YYYY");
    }
  }

  formatDateFrom(value: any) {
    if (value.validity?.from) {
      value.validity!.from = value.validity?.from.includes("/")
        ? formatDateLocale(value.validity?.from, "DD/MM/YYYY", "fr", "DD/MM/YYYY")
        : formatDateLocale(value.validity?.from, "DDMMYYYY", "fr", "DD/MM/YYYY");
    }
  }

  formatDate(date: string) {
    return formatDateLocale(date, this.dateMask, "fr", "YYYY/MM/DD");
  }

  onChangeType(classType: string, index: number) {
    if (!classType) return;

    // Load options for the selected class type
    this.loadOptionsForClassType(classType, index);

    // Find the last classification with the same type
    let startDate = '';
    if (this.selectedClassifications) {
      for (const classification of this.selectedClassifications) {
        if (classification.classType === classType &&
          classification.partyClass?.type?.value &&
          classification.validity?.until) {
          startDate = classification.validity.until;
        }
      }
    }

    // Get a new classification template
    const getter = store.getters["thirdModule/getPartyClassificationInit"];

    // Set the start date based on previous classification's end date
    if (startDate) {
      getter.validity.from = formatDate(formatDateServiceAddDays(1, formatDateToMoment(startDate)));
    }

    // Set the class type
    getter.classType = classType;

    // Update the classification at the current index
    if (this.selectedClassifications && index < this.selectedClassifications.length) {
      this.selectedClassifications[index] = getter;
    }
  }

  updateModel(res: any, index: number) {
    if (!res || !res.items) {
      console.error('Invalid response for party classification items', res);
      return;
    }

    const sortedItems = sortItemsByLabel(res.items);

    // Ensure we have an array to store the items
    if (!Array.isArray(this.partyClassificationItems)) {
      this.partyClassificationItems = [];
    }

    // Update the options for the specific index
    this.partyClassificationItems[index] = sortedItems;
  }

  getDisable(value: any) {
    const getResourceUid = store.getters["thirdModule/getResourceUid"];
    if (value.validity?.from &&
      value.validity.from !== '' &&
      value.validity?.until &&
      value.validity.until !== '' &&
      getResourceUid) {

      // Find classifications with the same type
      const list = this.selectedClassifications?.filter((el: any) => {
        return el.classType === value.classType;
      });

      // If there are multiple classifications of the same type
      // and the current one has different from/until dates, disable it
      if (list && list.length > 1 && value.validity.from !== value.validity.until) {
        return true;
      }

      return false;
    }

    return false;
  }

  testStartDate(date: any, value: any) {
    let startDate = '';

    // Find the latest end date for classifications with the same type
    if (this.selectedClassifications) {
      for (const classification of this.selectedClassifications) {
        if (classification.classType === value.classType &&
          classification.partyClass?.type?.value) {
          startDate = classification.validity?.until || classification.validity?.from || '';
        }
      }
    }

    if (startDate !== '') {
      return (date > this.formatDate(startDate) + 1);
    }

    return true;
  }

  testEndDate(date: any, value: any) {
    let startDate = '';

    // Find start dates for classifications with the same type
    if (this.selectedClassifications) {
      for (const classification of this.selectedClassifications) {
        if (classification.classType === value.classType) {
          startDate = classification.validity?.from || '';
        }
      }
    }

    if (startDate !== '' && value.validity?.from !== startDate) {
      return (date < this.formatDate(startDate) + 1);
    }

    return true;
  }

  onChangeStartDate(value: any) {
    if (!this.selectedClassifications || !value.validity?.from) return;

    for (const classification of this.selectedClassifications) {
      if (classification &&  classification.classType === value.classType &&
        (!classification.validity?.until || classification.validity.until === '') &&
        classification.validity?.from !== value.validity.from) {
        classification.validity!.until = formatDate(formatDateServiceAddDays(0, formatDateToMoment(value.validity.from)));
      }
    }
  }

  onChangeEndDate(value: any) {
    if (!this.selectedClassifications || !value.validity?.until) return;

    for (const classification of this.selectedClassifications) {
      if (classification.classType === value.classType &&
        (!classification.validity?.from || classification.validity.from === '')) {
        classification.validity!.from = formatDate(formatDateServiceAddDays(1, formatDateToMoment(value.validity.until)));
      }
    }
  }
}
