
import {Options, Vue} from "vue-class-component";
import QuestionsByCategory from "@/components/general/QuestionsByCategory.vue";
import Algorithmes from "@/components/Analysis/Algorithmes.vue";
import Reserves from "@/components/Analysis/Reserves.vue";

import store from "@/store";

@Options({
    components: {
        QuestionsByCategory,
        Algorithmes,
        Reserves
    },
    props: ["reCalculation"],

})
export default class Study extends Vue {
    reCalculation: any;

    get score() {
        return store.state.analysisModule?.analysis?.score
    }
    get questionnaires() {
        return store.state.analysisModule?.questionnaire
    }

    get gatheringQuestions() {
        return store.state.analysisModule?.gatheringQuestions
    }
    get isLoading() {
        return store.state.analysisModule?.isLoading
    }
    get errors() {
        return store.state.analysisModule?.errors.length > 0 ? store.state.analysisModule?.errors.algo : false
    }
    
}
