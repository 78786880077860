
import { Options, Vue } from "vue-class-component";
import Score from "@/components/Analysis/Score.vue";
import Ratings from "@/components/Analysis/Ratings.vue";
import Ratios from "@/components/Analysis/Ratios.vue";
import store from "@/store";
import { Question } from "@/types";
import Notify from 'quasar/src/plugins/Notify.js';;
import i18n from "@/i18n";

@Options({
  components: {
    Score,
    Ratings,
    Ratios,
  },
  props: ["data", "reCalculation"],
  watch: {
    "$store.state.financingModule.simulationChanged"(simulationChanged) {
      if (simulationChanged === true) {
        store.state.analysisModule!.AlgorithmeChanged = true;
      }
    },
    "$store.state.financingModule.serviceModule.calculationPendent"(calculationPendent) {
      if (calculationPendent === true) {
        store.state.analysisModule!.AlgorithmeChanged = true;
      }
    },
    "$store.state.demandModule.offer": {
      handler(newvalue) {
        store.state.analysisModule!.AlgorithmeChanged = true;
      },
      deep: true, // Watch nested properties
    },

    "$store.state.taskModule.analysisModule.selectedAnswersOptions": {
      handler(newvalue) {
        store.state.analysisModule!.AlgorithmeChanged = true;
      },
      deep: true, // Watch nested properties
    },
  },
})
export default class Algorithmes extends Vue {
  data: any;
  reCalculation: any;
  simulationChanged?: boolean = false;
  calculationPendent?: boolean = false;
  get loading() {
    return store.getters["analysisModule/getAlgorithmeLoading"];
  }
  get currentRoute() {
    return this.$router.currentRoute;
  }
  get algorithmeChanged() {
    return store.state.analysisModule?.AlgorithmeChanged;
  }

  get analysisIsDone() {
    return store.state.analysisModule!.isLoading
  }
  updateAnalysis() {
    if (this.algorithmeChanged) {
        store.commit("analysisModule/setAlgorithmeLoading", true);
        const gatheringQuestionsState = store.state.analysisModule?.gatheringQuestions;
        const answersState = store.state.analysisModule?.selectedAnswersOptions;
        const updatedAnswers: Question[] = gatheringQuestionsState!.map((item) => {
          const questionId = item.question.resourceUid;
          const answerData: any = Object.values(answersState as any).find(
            (data: any) => data.question_id === questionId
          );

          if (answerData) {
            item.answer.resourceUid = answerData.answer_option_id;
          }
          return item;
        });

        const TaskVariablesState: any = store.state.taskModule.selectedTask?.variables;

        store.dispatch("taskModule/saveOffer").then(() => {
          if (TaskVariablesState && TaskVariablesState.offerId) {
            store.commit("analysisModule/setAlgorithmeLoading", false);
            store.state.analysisModule!.AlgorithmeChanged = false;
          }
        }).catch((error) => {
          console.error('Error in saving offer:', error);
        });
      }

  }
}
