
import { Vue, Options } from "vue-class-component";

import { AssociatedParty, BalanceSheet, Organization, Person, Third } from "@/types";
import store from "@/store";
import i18n from "@/i18n";
import { useRoute } from "vue-router";
import { formatDateLocale } from "@/utils/configuration/formatters-config";

@Options({
  props: ["party", "tabPosition"],
  watch: {
    "$store.state.middleOfficeModule.section"(newValue) {
      this.showFinancialSituationExpense = newValue === `section-financialSituation${this.tabPosition}`;
    },
  },
  created() {

    if (this.party?.balanceSheets && this.party?.balanceSheets[0]?.supportingDocument) {
      store.dispatch("searchThirdModule/downloadFile", {
        request: this.party?.balanceSheets[0].supportingDocument.resourceUid,
        callback: (result: any) => {
          this.supportingDocument = result.originalFileName
        },
      })

    }
    this.balanceSheets = this.balanceSheetsList;
    for (const balanceSheet of this.balanceSheets) {
      if (balanceSheet.supportingDocument) {
        store.dispatch("searchThirdModule/downloadFile", {
          request: balanceSheet.supportingDocument.resourceUid,
          callback: (result: any) => {
            balanceSheet.supportingDocument = result
          }
        })
      }
    }
  },
  computed: {
    // balanceSheetsList: function () {
    //   this.balanceSheets =
    //     this.third.organization && this.third.organization.balanceSheets;
    //   if (this.balanceSheets.length > 0 && !this.balanceSheets[0].status.label) { this.balanceSheets[0].status.label = i18n.global.t(`party.role.new`) }
    //   return this.balanceSheets;
    // },
  }
})
export default class PartyFinancialSituation extends Vue {
  party?: Third;
  tabPosition?: number;
  showFinancialSituationExpense = false;
  supportingDocument: any = null;
  statusLabel: any = null;
  balanceSheets: BalanceSheet[] = [];
  dateMask = "DD/MM/YYYY";

  get balanceSheetsList() {
    const list = this.party?.organization?.balanceSheets && this.party.organization?.balanceSheets.length > 0 ? this.list : [store.getters['thirdModule/getBalanceSheetEmpty']];
    this.party!.organization!.balanceSheets = list
    return this.party!.organization?.balanceSheets?.map(balanceSheet => {
      if (!balanceSheet.detail || balanceSheet.detail.length === 0) {
        balanceSheet.detail = [store.getters['thirdModule/getBalanceSheetDetailEmpty']];
      }
      return balanceSheet;
    });
  }

  get list() {
    const balanceSheets = this.party?.organization?.balanceSheets
    if (balanceSheets && balanceSheets.length > 0 && !balanceSheets[0].status.label) { balanceSheets[0].status.label = i18n.global.t(`party.role.new`) }
    return balanceSheets
  }

  get currentRoute() {
    return useRoute().name;
  }

  get isdisable() {
    if (this.currentRoute !== 'TaskDetail') {
      return true
    } else
      return false
  }
  formatDateFrom(value: any) {
    value.period.from = value.period.from.includes("/")
        ? formatDateLocale(value.period.from, "DD/MM/YYYY", "fr", "DD/MM/YYYY")
        : formatDateLocale(value.period.from, "DDMMYYYY", "fr", "DD/MM/YYYY");
  }
  formatDate(date: string) {
    return formatDateLocale(date, this.dateMask, "fr", "YYYY/MM/DD");
  }
  formatDateUntil(value: any) {
    value.period.until = value.period.until.includes("/")
        ? formatDateLocale(value.period.until, "DD/MM/YYYY", "fr", "DD/MM/YYYY")
        : formatDateLocale(value.period.until, "DDMMYYYY", "fr", "DD/MM/YYYY");
  }

  get countriesOptions() {
    return store.getters["picklistModule/countriesItems"];
  }
  get statusOptions() {
    return store.getters["picklistModule/roleStatusItems"].map((item: any) => {
      return {
        ...item,
        label: item.label?.toString() !== "" ? i18n.global.t("party.role." + item.value.toLowerCase()) : ''
      };
    }).sort((a: any, b: any) => a.label.localeCompare(b.label))
  }
  get natureOptions() {
    return store.getters["picklistModule/natureBalanceSheetItems"];
  }
}
