
import { Options, Vue } from 'vue-class-component';
import store from '@/store';
import { DownloadRequest, FileResponse, Template } from '@/types';
import { PDFDocument } from 'pdf-lib';
import { useRoute } from 'vue-router';
@Options({
    name: 'TemplateListComponent',
    props: {
        modal: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
})
export default class TemplateListComponent extends Vue {
    isLoadingDownload = false;
    isLoadingView = false;
    modal!: boolean;
    get allTemplatesSelected() {
        const allTemplates = Object.values(this.groupedTemplates).flat();
        return allTemplates.length > 0 && allTemplates.every((template: any) =>
            this.isTemplateSelected(template.processId)
        );
    }

    get totalTemplatesCount() {
        return Object.values(this.groupedTemplates).flat().length;
    }

    handleMainCheckboxChange() {
        if (this.allTemplatesSelected) {
            this.clearSelection();
        } else {
            this.selectAll();
        }
    }

    get signatureMethode() {
        if (this.currentRoute === 'TaskDetail') {
             return store.state.taskModule.selectedTask.variables.SignatureMethod || "ELECTRONIC" 
            } else store.state.demandModule.signatureMethod;
    }
    get groupedTemplates() {
        let groupedTemplates;
            groupedTemplates = store.getters['templateModule/getGroupedTemplates'](this.signatureMethode)
        return groupedTemplates;
    }

    get isLoading() {
        return store.state.templateModule.isLoading;
    }

    get offer() {
        return store.state.taskModule.offer || store.state.middleOfficeModule.offer;
    }


    get error() {
        return store.state.templateModule.error;
    }

    get hasSelectedTemplates() {
        return store.getters['templateModule/getSelectedTemplates'].length > 0;
    }
    get financialAgreement() {
        return store.getters['templateModule/getFinancingAgreement'];
    }
    get currentRoute() {
    return useRoute().name;
  }

    get selectedTemplatebySignatureMethod() {
        const fileList = store.getters['templateModule/getSelectedTemplates']
            .filter((template: string) => {
                const templates = store.getters['templateModule/getTemplates'];
                return templates.some((t: Template) => t.processId === template && t.signatureType === this.signatureMethode);
            });
        return fileList
    }
    /**
        * Highlights the row if the template is the preview template
        * @param template Template object
        * @returns Object with highlight class
        */
    isPreview(template: Template) {
        return store.state.templateModule.previewTemplate[0]?.fileId === template.fileId;
    }

    isTemplateSelected(processId: string) {
        return store.getters['templateModule/isTemplateSelected'](processId);
    }

    // New method to check if all templates in a package are selected
    isPackageFullySelected(packageName: string) {
        const packageTemplates = this.groupedTemplates[packageName];
        return packageTemplates.every((template: Template) =>
            this.isTemplateSelected(template.processId)
        );
    }

    // New method to toggle selection for an entire package
    togglePackageSelection(packageName: string) {
        const packageTemplates = this.groupedTemplates[packageName];
        const isCurrentlySelected = this.isPackageFullySelected(packageName);

        packageTemplates.forEach((template: Template) => {
            // Only toggle if the current package selection state is different
            if (isCurrentlySelected) {
                // If package is fully selected, clear its selection
                store.dispatch('templateModule/deselectTemplate', template.processId);
            } else {
                // If package is not fully selected, select its templates
                store.dispatch('templateModule/selectTemplate', template.processId);
            }
        });
    }

    toggleSelection(processId: string) {
        store.dispatch('templateModule/toggleSelection', processId);
    }

    clearSelection() {
        store.dispatch('templateModule/clearSelection');
    }

    selectAll() {
        store.dispatch('templateModule/selectAll');
    }

    retryTemplate(template: Template) {
        store.dispatch('templateModule/retryFailedTemplate', template);
    }

    viewDocumentResponse() {

        this.isLoadingView = false;
        this.isLoadingDownload = false;
    }

    onDownloadAllEvent() {
        const fileList = store.getters['templateModule/getTemplates'].filter((template: Template) => this.selectedTemplatebySignatureMethod.includes(template.processId)).sort((a: any, b: any) => {
            const templateA = store.getters['templateModule/getTemplates'].find((t: any) => t.processId === a);
            const templateB = store.getters['templateModule/getTemplates'].find((t: any) => t.processId === b);
            return (templateA?.documentOrder || 0) - (templateB?.documentOrder || 0);
        });
        this.isLoadingDownload = true;
        this.downloadAndCombineFiles(fileList.map((file: any) => file = file.fileId))
    }

    onDownloadEvent(fileId: string, template: any, packageName: any) {
        this.onViewEvent(template.fileId, template);
        this.isLoadingDownload = true;
        this.downloadAndCombineFiles([fileId], template, packageName)
    }




    async downloadAndCombineFiles(fileIds: string[], template?: any, packageName?: any): Promise<void> {
        try {
            // Download all files in parallel
            const downloadPromises = fileIds.map(fileId =>
                this.downloadSingleFile({ resourceUid: fileId })
            );

            const responses = await Promise.all(downloadPromises);

            // Create a new PDF document
            const mergedPdf = await PDFDocument.create();

            // Process each file
            for (const response of responses) {
                if (!response || !response.content) {
                    console.error('Invalid file response');
                    continue;
                }

                try {
                    const pdfBytes = this.base64ToUint8Array(response.content);
                    const pdf = await PDFDocument.load(pdfBytes);
                    const pages = await mergedPdf.copyPages(pdf, pdf.getPageIndices());
                    pages.forEach((page: any) => mergedPdf.addPage(page));
                } catch (error) {
                    console.error(`Error processing PDF: ${error}`);
                }
            }

            // Save and download the merged PDF
            const mergedPdfBytes = await mergedPdf.save();
            this.downloadMergedPdf(mergedPdfBytes, template ? this.offer?.reference + '_' + template.fileName + '_' + packageName + '.pdf' : this.offer?.reference + '_DOC.pdf');

        } catch (error) {
            console.error('Error in downloadAndCombineFiles:', error);
            throw new Error('Failed to download and combine files');
        }
    }

    /**
     * Downloads a single file
     * @param request Download request parameters
     * @returns Promise<FileResponse>
     */
    async downloadSingleFile(request: DownloadRequest): Promise<FileResponse> {
        return new Promise((resolve, reject) => {
            store.dispatch("printDocumentModule/downloadFileGeneratedFile", {
                request,
                callback: (response: FileResponse) => {
                    if (response) {
                        resolve(response);
                    } else {
                        reject(new Error('Download failed'));
                    }
                },
            });
        });
    }

    /**
     * Converts base64 string to Uint8Array
     * @param base64 Base64 string to convert
     * @returns Uint8Array
     */
    base64ToUint8Array(base64: string): Uint8Array {
        const binaryString = atob(base64);
        const bytes = new Uint8Array(binaryString.length);
        for (let i = 0; i < binaryString.length; i++) {
            bytes[i] = binaryString.charCodeAt(i);
        }
        return bytes;
    }

    /**
     * Downloads the merged PDF file
     * @param pdfBytes PDF file as Uint8Array
     * @param fileName Name for the downloaded file
     */
    downloadMergedPdf(pdfBytes: Uint8Array, fileName: string): void {
        const blob = new Blob([pdfBytes], { type: 'application/pdf' });
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = fileName;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(url);
    }
    onViewEvent(fileId: string, template: Template) {
        this.isLoadingView = true;
        this.setPreviewGeneratedFiles(fileId)
        store.dispatch('templateModule/setPreviewTemplates', template);
    }
    setPreviewGeneratedFiles(fileId: string) {
        store.dispatch("printDocumentModule/setPreviewGeneratedFiles", {
            fileId: fileId,
            callback: this.viewDocumentResponse,
        });
    }
    refetchTemplates() {
        const entityId = this.offer?.resourceUid;
        store.dispatch('templateModule/fetchTemplates', { entityId: entityId });
    }

    created() {
        const entityId = this.offer?.resourceUid;
        store.dispatch('templateModule/fetchTemplates', { entityId: entityId });
        this.refetchTemplates();
    }
}
