import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withModifiers as _withModifiers, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, withCtx as _withCtx, resolveDirective as _resolveDirective, createBlock as _createBlock, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "card-title flex-grow-1 justify-content-between align-items-center mb-0" }
const _hoisted_2 = { class: "d-flex align-items-center" }
const _hoisted_3 = { class: "font-semi-bold font-size-12" }
const _hoisted_4 = { class: "card-body" }
const _hoisted_5 = { class: "d-flex" }
const _hoisted_6 = { class: "flex-grow-1" }
const _hoisted_7 = { class: "row" }
const _hoisted_8 = {
  class: "col-lg-3 col-md-6 col-sm-12 form-control no-border",
  id: "third_edit_ralation_relation"
}
const _hoisted_9 = { class: "d-block mb-0 small text-muted" }
const _hoisted_10 = {
  class: "col-lg-3 col-md-6 col-sm-12 form-control no-border",
  id: "third_edit_ralation_relationType"
}
const _hoisted_11 = { class: "d-block mb-0 small text-muted" }
const _hoisted_12 = {
  class: "col-lg-3 col-md-6 col-sm-12 form-control no-border",
  id: "third_edit_ralation_party"
}
const _hoisted_13 = { class: "d-block mb-0 small text-muted" }
const _hoisted_14 = { class: "col-lg-3 col-md-6 col-sm-12 form-control no-border" }
const _hoisted_15 = { class: "d-block mb-0 small text-muted" }
const _hoisted_16 = { class: "row items-center justify-end" }
const _hoisted_17 = { class: "col-lg-3 col-md-6 col-sm-12 form-control no-border" }
const _hoisted_18 = { class: "d-block mb-0 small text-muted" }
const _hoisted_19 = { class: "row items-center justify-end" }
const _hoisted_20 = {
  key: 0,
  class: "col-lg-3 col-md-6 col-sm-12 form-control no-border"
}
const _hoisted_21 = { class: "d-block mb-0 small text-muted" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_select = _resolveComponent("q-select")!
  const _component_q_item_label = _resolveComponent("q-item-label")!
  const _component_q_item_section = _resolveComponent("q-item-section")!
  const _component_q_item = _resolveComponent("q-item")!
  const _component_q_list = _resolveComponent("q-list")!
  const _component_q_btn_dropdown = _resolveComponent("q-btn-dropdown")!
  const _component_q_input = _resolveComponent("q-input")!
  const _component_q_date = _resolveComponent("q-date")!
  const _component_q_popup_proxy = _resolveComponent("q-popup-proxy")!
  const _component_q_icon = _resolveComponent("q-icon")!
  const _component_q_expansion_item = _resolveComponent("q-expansion-item")!
  const _component_q_form = _resolveComponent("q-form")!
  const _component_third_search_dialog = _resolveComponent("third-search-dialog")!
  const _directive_close_popup = _resolveDirective("close-popup")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_q_form, null, {
      default: _withCtx(() => [
        _createVNode(_component_q_list, null, {
          default: _withCtx(() => [
            _createVNode(_component_q_expansion_item, {
              "switch-toggle-side": "",
              "header-class": "card-header bg-transparent no-border p-4",
              class: "card tw-items-dashed-group tw-items-group-with-bg tw-level-two-group",
              onShow: _cache[1] || (_cache[1] = ($event: any) => {
          _ctx.relationBtn = !_ctx.relationBtn;
          _ctx.addExpandRelation(_ctx.relations);
        }),
              onHide: _cache[2] || (_cache[2] = ($event: any) => {_ctx.relationBtn = !_ctx.relationBtn;}),
              modelValue: _ctx.openedRelations,
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.openedRelations) = $event))
            }, {
              header: _withCtx(() => [
                _createElementVNode("div", _hoisted_1, [
                  _createElementVNode("div", _hoisted_2, [
                    _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.$t("middleOffice.party.organization.section.relation")), 1),
                    _createVNode(_component_q_btn, {
                      class: "btn btn-primary btn-sm ml-3",
                      icon: "add",
                      onClickCapture: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.add(_ctx.relations)), ["stop"]))
                    })
                  ])
                ])
              ]),
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_4, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.relations, (value, index) => {
                    return (_openBlock(), _createElementBlock("div", {
                      class: "p-2 bg-white default-border-radius mb-3",
                      key: index
                    }, [
                      _createElementVNode("div", _hoisted_5, [
                        _createElementVNode("div", _hoisted_6, [
                          _createElementVNode("div", _hoisted_7, [
                            _createElementVNode("div", _hoisted_8, [
                              _createElementVNode("label", _hoisted_9, _toDisplayString(_ctx.$t("middleOffice.party.organization.relation.relation")), 1),
                              _createVNode(_component_q_select, {
                                "data-testid": "third_edit_ralation_relation",
                                dense: "",
                                outlined: "",
                                "emit-value": "",
                                "map-options": "",
                                options: _ctx.relationsPicklist,
                                modelValue: value.relation.type.value,
                                "onUpdate:modelValue": [($event: any) => ((value.relation.type.value) = $event), ($event: any) => (_ctx.changeRelation(value))],
                                disable: value.isDisabled
                              }, null, 8, ["options", "modelValue", "onUpdate:modelValue", "disable"])
                            ]),
                            _createElementVNode("div", _hoisted_10, [
                              _createElementVNode("label", _hoisted_11, _toDisplayString(_ctx.$t("middleOffice.party.organization.relation.relationType")), 1),
                              _createVNode(_component_q_select, {
                                "data-testid": "third_edit_ralation_relationType",
                                dense: "",
                                outlined: "",
                                "emit-value": "",
                                "map-options": "",
                                options: _ctx.getRelationType(value),
                                modelValue: value.relationType.type.value,
                                "onUpdate:modelValue": ($event: any) => ((value.relationType.type.value) = $event),
                                disable: value.isDisabled
                              }, null, 8, ["options", "modelValue", "onUpdate:modelValue", "disable"])
                            ]),
                            _createElementVNode("div", _hoisted_12, [
                              _createElementVNode("label", _hoisted_13, _toDisplayString(_ctx.$t("demand.tabs.tiers")), 1),
                              _createVNode(_component_q_input, {
                                "data-testid": "third_edit_ralation_party",
                                outlined: "",
                                readonly: "",
                                dense: "",
                                modelValue: value.name,
                                "onUpdate:modelValue": ($event: any) => ((value.name) = $event),
                                disable: value.isDisabled
                              }, {
                                append: _withCtx(() => [
                                  _createVNode(_component_q_btn_dropdown, {
                                    round: "",
                                    dense: "",
                                    flat: "",
                                    icon: "search"
                                  }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_q_list, null, {
                                        default: _withCtx(() => [
                                          _withDirectives((_openBlock(), _createBlock(_component_q_item, {
                                            clickable: "",
                                            onClick: ($event: any) => (_ctx.searchThird(index, _ctx.organizationType))
                                          }, {
                                            default: _withCtx(() => [
                                              _createVNode(_component_q_item_section, null, {
                                                default: _withCtx(() => [
                                                  _createVNode(_component_q_item_label, null, {
                                                    default: _withCtx(() => [
                                                      _createTextVNode(_toDisplayString(_ctx.$t("enum.party.type.party-FrenchOrganization")), 1)
                                                    ]),
                                                    _: 1
                                                  })
                                                ]),
                                                _: 1
                                              })
                                            ]),
                                            _: 2
                                          }, 1032, ["onClick"])), [
                                            [_directive_close_popup]
                                          ]),
                                          _withDirectives((_openBlock(), _createBlock(_component_q_item, {
                                            clickable: "",
                                            onClick: ($event: any) => (_ctx.searchThird(index, _ctx.personType))
                                          }, {
                                            default: _withCtx(() => [
                                              _createVNode(_component_q_item_section, null, {
                                                default: _withCtx(() => [
                                                  _createVNode(_component_q_item_label, null, {
                                                    default: _withCtx(() => [
                                                      _createTextVNode(_toDisplayString(_ctx.$t("enum.party.type.party-FrenchPerson")), 1)
                                                    ]),
                                                    _: 1
                                                  })
                                                ]),
                                                _: 1
                                              })
                                            ]),
                                            _: 2
                                          }, 1032, ["onClick"])), [
                                            [_directive_close_popup]
                                          ])
                                        ]),
                                        _: 2
                                      }, 1024)
                                    ]),
                                    _: 2
                                  }, 1024)
                                ]),
                                _: 2
                              }, 1032, ["modelValue", "onUpdate:modelValue", "disable"])
                            ]),
                            _createElementVNode("div", _hoisted_14, [
                              _createElementVNode("label", _hoisted_15, _toDisplayString(_ctx.$t("main.labels.startDate")), 1),
                              _createVNode(_component_q_input, {
                                readonly: "",
                                onChange: ($event: any) => (_ctx.formatDateFrom(value)),
                                dense: "",
                                outlined: "",
                                modelValue: value.validity.from,
                                "onUpdate:modelValue": ($event: any) => ((value.validity.from) = $event),
                                disable: value.isDisabled
                              }, {
                                append: _withCtx(() => [
                                  _createVNode(_component_q_icon, {
                                    name: "event",
                                    class: "cursor-pointer"
                                  }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_q_popup_proxy, {
                                        ref_for: true,
                                        ref: "qDateProxy",
                                        cover: "",
                                        "transition-show": "scale",
                                        "transition-hide": "scale"
                                      }, {
                                        default: _withCtx(() => [
                                          _createVNode(_component_q_date, {
                                            modelValue: value.validity.from,
                                            "onUpdate:modelValue": ($event: any) => ((value.validity.from) = $event),
                                            mask: _ctx.dateMask,
                                            options: 
                                (date) =>
                                  value.validity.until
                                    ? date < _ctx.formatDate(value.validity.until)
                                    : true
                              
                                          }, {
                                            default: _withCtx(() => [
                                              _createElementVNode("div", _hoisted_16, [
                                                _withDirectives(_createVNode(_component_q_btn, {
                                                  label: _ctx.$t('task.completeDocument.party.person.financialSituation.close'),
                                                  color: "primary",
                                                  flat: ""
                                                }, null, 8, ["label"]), [
                                                  [_directive_close_popup]
                                                ])
                                              ])
                                            ]),
                                            _: 2
                                          }, 1032, ["modelValue", "onUpdate:modelValue", "mask", "options"])
                                        ]),
                                        _: 2
                                      }, 1536)
                                    ]),
                                    _: 2
                                  }, 1024)
                                ]),
                                _: 2
                              }, 1032, ["onChange", "modelValue", "onUpdate:modelValue", "disable"])
                            ]),
                            _createElementVNode("div", _hoisted_17, [
                              _createElementVNode("label", _hoisted_18, _toDisplayString(_ctx.$t("main.labels.endDate")), 1),
                              _createVNode(_component_q_input, {
                                readonly: "",
                                dense: "",
                                outlined: "",
                                modelValue: value.validity.until,
                                "onUpdate:modelValue": ($event: any) => ((value.validity.until) = $event),
                                onChange: ($event: any) => (_ctx.formatDateUntil(value)),
                                disable: value.isDisabled
                              }, {
                                append: _withCtx(() => [
                                  _createVNode(_component_q_icon, {
                                    name: "event",
                                    class: "cursor-pointer"
                                  }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_q_popup_proxy, {
                                        ref_for: true,
                                        ref: "qDateProxy",
                                        cover: "",
                                        "transition-show": "scale",
                                        "transition-hide": "scale"
                                      }, {
                                        default: _withCtx(() => [
                                          _createVNode(_component_q_date, {
                                            modelValue: value.validity.until,
                                            "onUpdate:modelValue": ($event: any) => ((value.validity.until) = $event),
                                            mask: _ctx.dateMask,
                                            options: 
                                (date) =>
                                  value.validity.from
                                    ? date > _ctx.formatDate(value.validity.from)
                                    : true
                              
                                          }, {
                                            default: _withCtx(() => [
                                              _createElementVNode("div", _hoisted_19, [
                                                _withDirectives(_createVNode(_component_q_btn, {
                                                  label: _ctx.$t('task.completeDocument.party.person.financialSituation.close'),
                                                  color: "primary",
                                                  flat: ""
                                                }, null, 8, ["label"]), [
                                                  [_directive_close_popup]
                                                ])
                                              ])
                                            ]),
                                            _: 2
                                          }, 1032, ["modelValue", "onUpdate:modelValue", "mask", "options"])
                                        ]),
                                        _: 2
                                      }, 1536)
                                    ]),
                                    _: 2
                                  }, 1024)
                                ]),
                                _: 2
                              }, 1032, ["modelValue", "onUpdate:modelValue", "onChange", "disable"])
                            ]),
                            (_ctx.isPersonMorale)
                              ? (_openBlock(), _createElementBlock("div", _hoisted_20, [
                                  _createElementVNode("label", _hoisted_21, _toDisplayString(_ctx.$t("main.labels.sharingRate")), 1),
                                  _createVNode(_component_q_input, {
                                    dense: "",
                                    outlined: "",
                                    modelValue: value.sharingRate,
                                    "onUpdate:modelValue": ($event: any) => ((value.sharingRate) = $event),
                                    min: "0",
                                    type: "number",
                                    suffix: "%"
                                  }, null, 8, ["modelValue", "onUpdate:modelValue"])
                                ]))
                              : _createCommentVNode("", true)
                          ])
                        ]),
                        _createElementVNode("div", null, [
                          _createVNode(_component_q_btn, {
                            icon: "delete",
                            class: "btn btn-outline-danger mt-3",
                            onClick: ($event: any) => {_ctx.remove(index, _ctx.relations); _ctx.setDeletedRelation(value)}
                          }, null, 8, ["onClick"])
                        ])
                      ])
                    ]))
                  }), 128))
                ])
              ]),
              _: 1
            }, 8, ["modelValue"])
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    (_ctx.showDialog)
      ? (_openBlock(), _createBlock(_component_third_search_dialog, {
          key: 0,
          openDialog: _ctx.openDialog,
          onOnSelectedItem: _ctx.onSelectedItem,
          onOnClose: _ctx.onClose
        }, null, 8, ["openDialog", "onOnSelectedItem", "onOnClose"]))
      : _createCommentVNode("", true)
  ], 64))
}