import {getOfferDocument, getProcess, getRequiredDocument} from "@/store/services/task/actions/uploadDocumentService";
import i18n from "@/i18n";
import {
    GatheringProcess,
    Offer, QcHeader,
    supportingDocuments,
    SupportingDocumentStatus,
    Task,
    TaskExecuteAction,
    TaskState
} from "@/types";
import {settings} from "@/settings";
import {api} from "@/auth/api";
import {executeTask} from "@/store/services/task/workflow";
import store from "@/store";
import {processDocuments} from "@/commons/gathering-process";
import {headers} from "@/utils";
import Notify from 'quasar/src/plugins/Notify.js';;

const odm_supportingdocument = 'odm-supportingdocument'
const baseUrl = `${settings.api_url}/${odm_supportingdocument}/api/1/${odm_supportingdocument}`

export async function executeValidateDocumentAction(taskState: TaskState, userId: string, offer: Offer, task: Task) {
    const qualified = i18n.global.t(`task.uploadDocument.headers.supportingDocumentStatus.RECEIVED`);
    const valid = i18n.global.t(`task.uploadDocument.headers.supportingDocumentStatus.VALID`);

    const gatheringProcess = store.state.taskModule.gatheringProcess;
    const allDocsValid = !gatheringProcess
        .flatMap((gp: any) => gp.entityTargets)
        .flatMap((targetEntity: any) => targetEntity.documents)
        .some((doc: any) => doc.status !== qualified && doc.status !== 'RECEIVED' && doc.status !== valid && doc.status !== 'VALID');

    if (allDocsValid) {
        const taskExecuteAction: TaskExecuteAction = {
            taskId: task.taskId || '',
            variables: {}
        }
        try {
            const sentToValidProcess: string[] = []
            for (const gp of gatheringProcess) {
                for (const targetEntity of gp.entityTargets) {
                    for ( const doc of targetEntity.documents ) {
                        if (sentToValidProcess.indexOf(doc.processResourceUid) < 0) {
                            await validProcess(doc.processResourceUid)
                            sentToValidProcess.push(doc.processResourceUid)
                            Notify.create({
timeout: 5000,
            actions: [{ icon: 'close', color: 'white' }],
                                message: i18n.global.t("main.dialog.validation.success"),
                                color: 'positive'
                            });
                        }
                    }
                }
            }
            return executeTask(taskExecuteAction)
        } catch (e) {
            console.error(e)
            return Promise.reject(e)
        }
    } else {
        return Promise.reject('There are some invalid documents')
    }

}

export async function getGatheringProcessList(offerUid: string) {
    try {
        const processList = await getOfferDocument(offerUid)

        for (const process of processList) {
            const gatheringProcessUid = process.gatheringProcess.resourceUid
            const gatheringProcess = await getProcess(gatheringProcessUid)
            const requiredDocument = await getRequiredDocument(gatheringProcessUid)

            gatheringProcess.supportingDocuments.forEach(item => item.config = {
                statusModel: {
                    label: i18n.global.t(`task.validateDocument.headers.supportingDocumentStatus.${item.status.resourceUid}`),
                    value: item.status.resourceUid
                }
            })
            gatheringProcess.config = {requiredDocument: requiredDocument}

            if (requiredDocument.length) {
                await processDocuments({
                    gatheringProcess: gatheringProcess,
                    requiredDocuments: requiredDocument,
                    supportingDocuments: gatheringProcess.supportingDocuments,
                    receivedFiles: gatheringProcess.gatheringProcessItem[0].receivedFiles
                })
             }
         }
        return Promise.resolve();
    } catch (e) {
        console.error(e)
        return Promise.reject(e)
    }
}

export async function updateSupportingDocumentStatus(gatheringProcessRow: any , status: any) {
    let receivedResult;
    try {
        let receivedFileId = gatheringProcessRow.receivedFileUid || gatheringProcessRow.document.receivedFileUid
        const label = gatheringProcessRow.document.originalFileName
        let processResourceUid = gatheringProcessRow.processResourceUid
        let supportingDocumentResourceUid = gatheringProcessRow.supportingDocumentResourceUid

        if(!gatheringProcessRow.supportingDocumentResourceUid || !receivedFileId) {
            const fileMetadata = await getIdsToValid(gatheringProcessRow.document.resourceUid,gatheringProcessRow.typeJustify, gatheringProcessRow.targetEntity.resourceUid)
            if (fileMetadata.length) {
                processResourceUid = fileMetadata[0].gatheringProcessId
                receivedFileId = fileMetadata[0].receivedFileId
                supportingDocumentResourceUid = fileMetadata[0].supportingDocumentId
            }
        }

        // Handle different status cases based on internalCode
        switch (status.internalCode) {
            case SupportingDocumentStatus.VALID:
                receivedResult = await validFile(
                    processResourceUid,
                    receivedFileId,
                    supportingDocumentResourceUid,
                    { label: label }
                );
                await validDocument(processResourceUid, supportingDocumentResourceUid);
                break;

            case SupportingDocumentStatus.CANCELLED:
                receivedResult = await cancelDocument(
                    processResourceUid,
                    supportingDocumentResourceUid
                );
                break;

            case SupportingDocumentStatus.OVERWRITE:
                receivedResult = await overwriteDocument(
                    processResourceUid,
                    supportingDocumentResourceUid
                );
                break;

            case SupportingDocumentStatus.REJECTED:
                receivedResult = await rejectFile(
                    processResourceUid,
                    receivedFileId,
                    supportingDocumentResourceUid,
                    {
                        label: label,
                        status: {
                            objectType: "odm.supportingdocument.processstatus",
                            systemUid: "odm-supportingdocument",
                            resourceUid: status.value
                        }
                    }
                );
                break;

            default:
                // Handle unknown or unexpected status
                console.warn(`Unknown status: ${status.value}`);
        }


    } catch (e) {
        console.error(e)
        return Promise.reject(e)
    }
    return receivedResult
}

async function changeDocumentStatus(processId: string, fileId: string, supportingDocumentId: string, payload: any,status?:string) {
    return await api().put(`${baseUrl}/process/${processId}/document/${supportingDocumentId}/file/${fileId}/${status?.toLowerCase()}/`, payload)
}



async function validProcess(processId: string) {
    return api().put(`${baseUrl}/process/${processId}/valid/`, {})
}

async function validDocument(processId: string, supportingDocumentId: string) {
    return api().put(`${baseUrl}/process/${processId}/supporting/${supportingDocumentId}/validate/`, {})
}

export async function rejectFile(processId: string, fileId: string, supportingDocumentId: string, payload: any) {
    return await api().put(`${baseUrl}/process/${processId}/document/${supportingDocumentId}/file/${fileId}/rejected/`, payload)
}

async function validFile(processId: string, fileId: string, supportingDocumentId: string, payload: any) {
    return await api().put(`${baseUrl}/process/${processId}/document/${supportingDocumentId}/file/${fileId}/valid/`, payload)
}

async function overwriteDocument(processId: string, supportingDocumentId: string) {
    return api().put(`${baseUrl}/process/${processId}/supporting/${supportingDocumentId}/overwrite/`, {})
}

async function cancelDocument(processId: string, supportingDocumentId: string) {
    return api().put(`${baseUrl}/process/${processId}/supporting/${supportingDocumentId}/canceled/`, {})
}

export const deleteManualDocument = async (processId: any, supportingDocumentId: any) => {
    const baseUrl = `${settings.api_url}/${odm_supportingdocument}/api/1/${odm_supportingdocument}/`
    return new Promise<any>(async (resolve, reject) => {
        try {
            const result = await api().delete(`${baseUrl}/process/` + processId + `/supporting/` + supportingDocumentId + '/')
            resolve(result.data)
        } catch (e) {
            reject(e)
        }
    });
}

export const deleteProcessFile = async (processId: any, fileId: any) => {
    const baseUrl = `${settings.api_url}/${odm_supportingdocument}/api/1/${odm_supportingdocument}/`
    return new Promise<any>(async (resolve, reject) => {
        try {
            const result = await api().delete(`${baseUrl}/process/` + processId + `/file/` + fileId + '/')
            resolve(result.data)
        } catch (e) {
            reject(e)
        }
    });
}

export const getIdsToValid = async (file_reference_id: string, document_family_code: string, target_entity_id: string) => {
    const qc_header: QcHeader = {
        qc: {
            queryId: "supportingdocument-received-file-gathering-process-item",
            offset: 0,
            limit: 100,
            parameters: {
                file_reference_id, document_family_code, target_entity_id
            }
        }
    };

    const {data} = (await api().get(settings.api_query_url, {
        headers: headers({qc: JSON.stringify(qc_header.qc)})
    }));

    return data.map((el: { received_file_id: string, gathering_process_item_id: string, gathering_process_id: string, supporting_document_id: string }) => ({
        receivedFileId: el.received_file_id,
        gatheringProcessItemId: el.gathering_process_item_id,
        gatheringProcessId: el.gathering_process_id,
        supportingDocumentId: el.supporting_document_id
    }));
}
