
import { Options, Vue } from "vue-class-component";
import twCurrencyInput from "@/components/general/twCurrencyInput.vue";
import twPercent from "@/components/general/twPercent.vue";
@Options({
  props: ["data"],
  watch: {},
  components: {
    twCurrencyInput,
    twPercent
  }
})
export default class Ratios extends Vue {
  data: any;
  get ratiosByCategories() {
    return this.sortCategories(this.$store.state.analysisModule?.ratiosByCategories);
  }
  get ratios() {
    return this.$store.state.analysisModule?.analysis?.ratios;
  }
 sortCategories(data:any) {
  if (!data) return {};
  const categories = Object.keys(data);
  const sortedCategories = categories.sort((a, b) =>
    a === "ENCOURS" ? -1 : b === "ENCOURS" ? 1 : a.localeCompare(b)
  );

  const sortedData: any = {};

  sortedCategories.forEach((category) => {
    if (category === "ENCOURS" && typeof data[category] === "object") {
      const customOrder = [
        "Encours_avant_financement",
        "Encours_BrutWoTax",
        "First_paymentWoTax",
        "Encours_net",
        "RAT_ENCOURS",
      ];

      const availableKeys = Object.keys(data[category]);

      const orderedKeys = availableKeys.sort((a, b) => {
        const indexA = customOrder.indexOf(a);
        const indexB = customOrder.indexOf(b);

        if (indexA !== -1 && indexB !== -1) return indexA - indexB;
        if (indexA !== -1) return -1;
        if (indexB !== -1) return 1;

        return availableKeys.indexOf(a) - availableKeys.indexOf(b);
      });

      sortedData[category] = orderedKeys.map((key) => ({
        ratio_id: data[category][key].ratio_id || key,
        category_code: data[category][key].category_code || category,
        ratio_name: data[category][key].ratio_name || key,
        value: data[category][key]?._daaq || "/",
      }));
    } else {
      sortedData[category] = data[category] || [];
    }
  });

  return sortedData;
}
  getRatioValue(ratio: any) {
    let currentModel = null;
    if (this.ratios) {
      currentModel = this.ratios.find(
        (item) => item.ratioParam?.resourceUid === ratio.ratio_id
      );
    }
    return currentModel;
  }
}
