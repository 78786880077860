import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "card-footer bg-white no-border flex justify-content-end pt-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_Ratings = _resolveComponent("Ratings")!
  const _component_Ratios = _resolveComponent("Ratios")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      (_ctx.reCalculation !== false)
        ? (_openBlock(), _createBlock(_component_q_btn, {
            key: 0,
            class: _normalizeClass(["btn", _ctx.algorithmeChanged ? 'btn-danger' : 'btn-primary']),
            "data-testid": "task_detail_study_algorithms_refresh-button",
            label: _ctx.$t('task.study.refresh'),
            icon: "restart_alt",
            loading: _ctx.loading,
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.updateAnalysis()))
          }, null, 8, ["class", "label", "loading"]))
        : _createCommentVNode("", true)
    ]),
    (!_ctx.analysisIsDone)
      ? (_openBlock(), _createBlock(_component_Ratings, {
          key: 0,
          reCalculation: _ctx.reCalculation
        }, null, 8, ["reCalculation"]))
      : _createCommentVNode("", true),
    (!_ctx.analysisIsDone)
      ? (_openBlock(), _createBlock(_component_Ratios, {
          key: 1,
          reCalculation: _ctx.reCalculation
        }, null, 8, ["reCalculation"]))
      : _createCommentVNode("", true)
  ], 64))
}